import { LinkA, LinkALink, TextH4, TextP } from "comps/basic/Typography4";
import { ToggleControlled } from "comps/simple/Toggle";
import img1 from "img/header/anna-tallarico-studio-commercialista-milano-00.jpg";
import img2 from "img/header/anna-tallarico-studio-commercialista-milano-01.jpg";
import img3 from "img/header/anna-tallarico-studio-commercialista-milano-02.jpg";
import { useState } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components';
import SectionTitle0 from "comps/simple/SectionTitle0";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";

const SNBox00 = styled.div`
  position: relative;
  width: 100%;
  padding: 20px 0;
  background: #3E56A9 linear-gradient(to right, #3E56A990, #1B264B90);
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #ffffffff, #ffffffff 50%, #ffffff00 50%, #ffffff00 100%);
  }
  .togglebox {z-index: 2;}
`;
const SectionNews = () => {
  const [type, setType] = useState(true); /*true = news, false = articles*/
  const [selected, setSelected] = useState(1);
  const toggleType = () => (setType(s => !s));
  return (
    <NewsWrap>
      <SectionHashTarget id="news-articoli" />
      <SectionTitle0 curved img={1} flip title="Risorse" subtitle="e approfondimenti" />
      <SNBox00>
        <ToggleControlled selected={type} onToggle={toggleType} t1="News" t2="Articoli" /></SNBox00>
      <SNBox0>
        <ScrollContainer vertical={false} horizontal className="scroll-container">
          <SNBox>
            {
              type === true ?
                items.map((i, index) =>
                  <SNItem key={index + "snitn"} onClick={() => setSelected(index)} selected={selected === index} bg={i.img}>
                    <div className="snoverlay" />
                    <TextH4 strong className="sntitle">{i.title}</TextH4>
                    <TextP className="snsubtitle">{i.subtitle}</TextP>
                    <LinkALink fontHoverColor="#30ffa2" to="#" className="snlink">{"Leggi"}</LinkALink>
                  </SNItem>
                ) :
                items2.map((i, index) =>
                  <SNItem key={index + "snita"} onClick={() => setSelected(index)} selected={selected === index} bg={i.img}>
                    <div className="snoverlay" />
                    <TextH4 strong className="sntitle">{i.title}</TextH4>
                    <TextP className="snsubtitle">{i.subtitle}</TextP>
                    <LinkALink fontHoverColor="#30ffa2" to="#" className="snlink">{"Leggi"}</LinkALink>
                  </SNItem>
                )
            }
          </SNBox>
        </ScrollContainer>
      </SNBox0>
    </NewsWrap>
  );
}

const items = [
  {
    title: "Novità fiscali",
    subtitle: "Novità fiscali di Luglio. Alleghiamo informativa di luglio riguardante news di carattere fiscale.",
    fulltext: "What are you a fucking twitcher is that a term",
    img: img2
  },
  {
    title: "Detrazione IRPEF",
    subtitle: "Detrazione IRPEF delle spese di frequenza delle Università non statali. Individuazione dei limiti di importo per il periodo d'imposta 2018",
    fulltext: "What is your best advice sros some one who's doing their own videosg",
    img: img1
  },
  {
    title: "Novità fiscali di Luglio",
    subtitle: "Alleghiamo informativa di luglio riguardante news di carattere fiscale.",
    fulltext: "What are you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch er is that a term",
    img: img3
  },
  {
    title: "Detrazione IRPEF delle spese di frequenza delle Università non statali",
    subtitle: "Individuazione dei limiti di importo per il periodo d'imposta 2018",
    fulltext: "What are you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch er is that a term",
    img: img3
  },
  {
    title: "Novità fiscali di Luglio",
    subtitle: "Alleghiamo informativa di luglio riguardante news di carattere fiscale.",
    fulltext: "Whaererfrefert is your ferf best advice sros some o your ferf best advice sros some o your ferf best advice sros some one who's doing their ofer ferferf wn videosg",
    img: img1
  },
  {
    title: "Detrazione IRPEF delle spese di frequenza delle Università non statali",
    subtitle: "Individuazione dei limiti di importo per il periodo d'imposta 2018",
    fulltext: "What are you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch er is that a term",
    img: img2
  },
  {
    title: "Novità fiscali di Luglio",
    subtitle: "Alleghiamo informativa di luglio riguardante news di carattere fiscale.",
    fulltext: "What is your best advice sros some one who's doing their own videosg",
    img: img1
  },
  {
    title: "Detrazione IRPEF delle spese di frequenza delle Università non statali",
    subtitle: "Individuazione dei limiti di importo per il periodo d'imposta 2018",
    fulltext: "What are you a fucking twitcher is that a term",
    img: img2
  },
  {
    title: "Novità fiscali di Luglio",
    subtitle: "Alleghiamo informativa di luglio riguardante news di carattere fiscale.",
    fulltext: "The question is rf refref what is uour best advice is pretts rf refref what is uour best advice is pretty broad. Consistency is key to keep going",
    img: img3
  }
];


const items2 = [
  {
    title: "ARTICOLO 1",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What is your best advice sros some one who's doing their own videosg",
    img: img3
  },
  {
    title: "ARTICOLO 5",
    subtitle: "Some sottotitolo troppo lungo?",
    fulltext: "What are you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch er is that a term",
    img: img2
  },
  {
    title: "ARTICOLO 22",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What are you a fucking twitcher is that a term",
    img: img1
  },
  {
    title: "ARTICOLO 5",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What are you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch you  er frfref ref era fucking twitch er is that a term",
    img: img1
  },
  {
    title: "ARTICOLO 1",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What is your best advice sros some one who's doing their own videosg",
    img: img1
  },
  {
    title: "ARTICOLO 22",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What are you a fucking twitcher is that a term",
    img: img2
  },
  {
    title: "ARTICOLO 6",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "The question is rf refref what is uour best advice is pretts rf refref what is uour best advice is pretty broad. Consistency is key to keep going",
    img: img3
  },
  {
    title: "ARTICOLO 1",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "What is your best advice sros some one who's doing their own videosg",
    img: img1
  },
  {
    title: "ARTICOLO 333",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "The question is what is uour best advice is pretty broad. Consistency is key to keep going",
    img: img3
  },
  {
    title: "ARTICOLO 4444",
    subtitle: "Sottotitololololololoooloo",
    fulltext: "Whaererfrefert is your ferf best advice sros some o your ferf best advice sros some o your ferf best advice sros some one who's doing their ofer ferferf wn videosg",
    img: img1
  }
];

const NewsWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;



/* const SNSelector = styled.div`
  position: absolute;
  top: 11px;
  transition: all 0.4s;
  left: ${props => "calc( " + props.selected + " * (0.0125 * (225px + 10vw) + (150px + 12vw)))"};
  width: calc(0.9 * (150px + 12vw));
  height: 3px;
  background-image: linear-gradient(to right, #3E56A900, #3E56A9f0, #3E56A9f0, #3E56A900);
`; */

const SNBox0 = styled.div`
  position: relative;
  width: 100%;
  /* height: calc(275px + 10vw); */
  height: calc(300px + 4vw);
  @media only screen and (min-width: 1441px) {
    height: calc(360px);
  }
  /* margin-top: calc(1 * (25px + 1vw)); */
  /* padding: 0 calc(30px + 2vw); */
  /* box-shadow: 0px 2px 6px #00000080; */
  /* background-image: linear-gradient(to right, #fbfbfb, #f0f0f0); */
  background: #3E56A9 linear-gradient(to right, #3E56A990, #1B264B90);
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center; */
  .scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const SNBox = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  height: 100%;
  /* background-color: #00000030; */
  transform: translate(0%, -50%);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: /* flex-start; */center;
  padding-top: calc(0 * (10px + 1.0vw));
  @media only screen and (min-width: 1441px) {
    padding-top: 25px;
  }
`;

const SNItem = styled.div`
  position: relative;
  cursor: pointer;
  /* width: calc(0.75 * (225px + 10vw));
  height: calc(0.75 * (225px + 10vw)); */

  /* width: ${props => props.selected === false ? "calc(1 * (130px + 10vw))" : "calc(1 * (150px + 12vw))"}; */
  width: ${props => props.selected === false ? "calc(1 * (130px + 10vw))" : "calc(1.2 * (130px + 10vw))"};
  max-width: ${props => props.selected === false ? "calc(1 * (270px))" : "calc(1.2 * (270px))"};

  /* @media only screen and (min-width: 1441px) {
    padding-top: 25px;
  } */

  /* height: calc(275px + 10vw); */
  /* border-radius: calc(0.075 * (225px + 10vw)); */
  /* border-radius: 0 calc(0.25 * (225px + 10vw)) 0 calc(0.25 * (225px + 10vw)); */
  /* border-radius: ${props => props.selected === false ? "5%" : "5%"}; */
  /* border-radius: 5px 50px 5px 50px; */
  margin: 0 calc(0.025 * (225px + 10vw));
  /* background-color: #00000030; */
  transition: all 0.4s;
  padding: 10px 6px;
  /* box-shadow: ${props => props.selected === false ? "3px 3px 6px #00000060" : "3px 3px 8px #000000b0"}; */
  /* transform: ${props => props.selected === false ? "scale(0.9)" : "scale(1.05)"}; */
  opacity: ${props => props.selected === false ? "0.5" : "1.0"};
  border: ${props => props.selected === true ? "1px solid #ffffff20" : "1px solid #ffffff00"};
  border-radius: 20px;
  &::after {
      position: absolute;
      content: "";
      top: -11px;
      transition: all 0.4s;
      left: ${props => props.selected === true ? "2%" : "50%"};
      width: ${props => props.selected === true ? "96%" : "0%"};
      height: 3px;
      /* background-image: linear-gradient(to right, #3E56A900, #3E56A9f0, #3E56A9f0, #3E56A900); */
      background-image: linear-gradient(to right, #ffffff00, #ffffffc0, #ffffffc0, #ffffff00);
    }
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

.snoverlay {
  position: relative;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 96%;
  height: 140px;
  transition: all 0.4s;
  border-radius: /* 10px */ ${props => props.selected === false ? "calc(58px + 2vw) 10px calc(58px + 2vw) 10px" : "10px"};
  /* background-color: ${props => props.selected === false ? "#ffffff90" : "#ffffff60"}; */
}

  .sntitle {
    z-index: 1;
    /* color: #3E56A9; */
    color: #ffffffe0;
    width: 90%;
    text-align: start;
    /* text-transform: uppercase; */
    font-size: calc(1.1 * (13px + 0.3vw));
    font-weight: 500;
    line-height: 1.3;
    transition: all 0.25s;
    margin-top: 8px;
    /* background-color: ${props => props.selected === false ? "#ffffff80" : "#ffffffb0"}; */
    padding: 10px 0 0 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .snsubtitle {
    z-index: 1;
    width: 90%;
    text-align: start;
    /* color: ${props => props.selected === false ? "#00000000" : "#000000a0"}; */
    color: ${props => props.selected === false ? "#00000000" : "#ffffffa0"};
    padding: 0 0 0px 0;
    transition: all 0.25s;
    font-size: calc(0.9 * (13px + 0.3vw));
    line-height: 1.3;
    font-weight: 400;
    max-height: ${props => props.selected === false ? "10px" : "60px"};
    opacity: ${props => props.selected === false ? "0.0" : "1.0"};

    /* max-lines: 3; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .snlink {
    /* z-index: 1; */
    max-height: ${props => props.selected === false ? "10px" : "60px"};
    opacity: ${props => props.selected === false ? "0.0" : "1.0"};
    /* color: ${props => props.selected === false ? "#30a2ff00" : "#30a2ffff"}; */
    /* width: 90%; */
    text-align: start;
    align-self: flex-end;
    margin-right: calc(1.0 * (14px + 1.0vw));
    font-weight: 500;
    transition: all 0.25s;
    font-size: calc(12px + 0.2vw);
    text-transform: uppercase;
    /* color: #30a2ff; */
  }
  @media only screen and (min-width: 1441px) {
.snoverlay {
  border-radius:  ${props => props.selected === false ? "86px 10px 86px 10px" : "10px"};
}
  margin: 0 calc(0.025 * (365px));
    .sntitle {
      font-size: calc(1.1 * (18px));
    }

    .snsubtitle {
    font-size: calc(0.9 * (13px + 4px));
    }

    .snlink {
    margin-right: 28px;
      font-size: calc(15px);
    }
  }
`;

export default SectionNews;
