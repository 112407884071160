import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import smoothscroll from 'smoothscroll-polyfill';
import { BrowserRouter, useLocation } from "react-router-dom";
/* import reportWebVitals from './reportWebVitals'; */

/* {
  key: 'ac3df4', // not with HashHistory!
  pathname: '/somewhere',
  search: '?some=search-string',
  hash: '#howdy',
  state: {
    [userDefined]: true
  }
} */

smoothscroll.polyfill();
const ScrollToTop = () => {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash && hash !== "") { return; }
    window.scrollTo(0, 0);
  }, [pathname]);// , hash ]);
  return null;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop /><App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('app-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/* reportWebVitals(); */
