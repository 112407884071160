import { LinkA, LinkLink, TextH4, TextP } from "comps/basic/Typography4";
/* import img1 from "img/header/anna-tallarico-studio-commercialista-milano-00.jpg";
import img2 from "img/header/anna-tallarico-studio-commercialista-milano-01.jpg";
import img3 from "img/header/anna-tallarico-studio-commercialista-milano-02.jpg"; */
import img1 from 'img/servizi/serv0-1440.jpg';
import img2 from 'img/servizi/serv1-1440.jpg';
import img3 from 'img/servizi/serv2-1440.jpg';
import img4 from 'img/servizi/serv3-1440.jpg';
import img5 from 'img/servizi/serv4-1440.jpg';
import { useEffect, useState } from "react";
import styled, { css } from 'styled-components';
import SectionServizi2 from "comps/compound/SectionServizi2";
import SectionTitle0 from "comps/simple/SectionTitle0";
import { useLocation } from "react-router-dom";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import RippleButton, { BlueInvertRippleButtonOutline } from "comps/simple/RippleContainer";
import { HashLink } from "comps/basic/HashLink";
import { MultiSnippet3Small } from "comps/complex/MultiSnippet";

const colorSSMain = "#3E56A9";
const colorSSTitleGray = "#a0a0a0";

const serviceItems = [
  {
    title: 'Contabilità e Bilancio',
    textShort: <p>La corretta e tempestiva rilevazione dei fatti amministrativi è di fondamentale importanza per un'impresa in quanto permette di avere informazioni sull’andamento gestionale ed è propedeutica alla redazione del bilancio, alle dichiarazioni fiscali ed alle analisi di bilancio. <strong>Il bilancio costituisce il biglietto da visita per l’azienda</strong> in quanto il mondo economico e finanziario lo richiede come lo strumento imprescindibile per acquisire le informazioni basilari per l’inizio e lo sviluppo di qualsiasi rapporto.</p>,
    cta: 'Approfondisci...',
    linkSection: '/#contabilita-bilancio',
    idSection: 'contabilita-bilancio',
    linkPage: '/contabilita-bilancio'
  },
  {
    title: 'Consulenza societaria',
    textShort: <p>Lo Studio, nel corso degli anni, si è occupato dei più vari e complessi problemi societari. In particolare, lo Studio affronta problemi relativi a:<ul><li>Valutazione della forma societaria ottimale date le esigenze del cliente.</li><li>Assistenza nell’adempimento degli atti societari.</li><li>Modifiche dell’atto costitutivo (capitale sociale, compagine sociale, etc.).</li><li>Predisposizione e stesura di bilanci d’esercizio, di periodo, di liquidazione.</li><li>Assistenza nelle operazioni straordinarie: ristrutturazione, riorganizzazione societaria, trasformazione, fusione, scissione, liquidazione, conferimento, affitto d’azienda.</li><li>Assistenza contrattuale: scioglimento, cessione, permuta.</li><li>Predisposizione atti di cessione quote societarie.</li><li>Partecipazione alle riunioni degli organi sociali.</li></ul></p>,
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-societaria',
    idSection: 'consulenza-societaria',
    linkPage: '/consulenza-societaria'
  },
  {
    title: 'Consulenza Fiscale',
    textShort: <p>Lo Studio Tallarico propone una serie di servizi riguardanti gli adempimenti fiscali. In particolare:<ul><li>Pareri sull’interpretazione ed applicazione della normativa fiscale.</li><li>Pianificazione fiscale per ogni genere di attività, dai privati cittadini alle società di medie dimensioni.</li><li>Assistenza specifica su <strong>IRE, IRES, IVA</strong>, imposizione sostitutiva.</li><li>Predisposizione delle dichiarazioni fiscali, determinazione delle imposte dovute, invio telematico.</li><li>Predisposizione <strong>dichiarazioni IMU</strong> per variazioni intervenute con riferimento ad immobili e terreni e compilazione della modulistica per il versamento.</li><li>Assistenza costante per ulteriori adempimenti previsti dalla normativa vigente.</li></ul></p>,
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-fiscale',
    idSection: 'consulenza-fiscale',
    linkPage: '/consulenza-fiscale'
  },
  {
    title: 'Revisione dei conti',
    textShort: <p>La revisione contabile e legale dei conti del bilancio garantisce l’accesso a <strong>migliori condizioni creditizie</strong>, facilita i rapporti con clienti e fornitori, permette lo sviluppo di nuovi rapporti anche con soggetti esteri. Se necessario, gli interventi vengono effettuati con l’ausilio di operatori esterni <strong>altamente professionali</strong> e affidabili.</p>,
    cta: 'Approfondisci...',
    linkSection: '/#revisione-dei-conti',
    idSection: 'revisione-dei-conti',
    linkPage: '/revisione-dei-conti'
  },
  {
    title: 'Contenzioso tributario',
    textShort: <p>Lo Studio Tallarico offre assistenza al contribuente in caso di verifiche fiscali, e lo segue nei rapporti con l’Agenzia delle Entrate al fine di <strong>prevenire l’insorgere di controversie tributarie</strong>. In caso di contenzioso, <strong>lo Studio Tallarico rappresenta il Cliente</strong> davanti alle Commissioni Tributarie Provinciali e Regionali mediante:
      <ul>
        <li>Predisposizione e presentazione di reclami e ricorsi.</li>
        <li>Elaborazione di controdeduzioni difensive.</li>
        <li>Istanze di sospensione e richieste di dibattito in pubblica udienza.</li>
        <li>Predisposizione e presentazione di ricorsi in appello.</li>
      </ul>
      Lo Studio assiste il Cliente anche nei suoi rapporti con il Concessionario della riscossione (Equitalia), nel caso di <strong>domande di rateizzazione, richieste di sgravio, verifiche della posizione.</strong></p>,
    cta: 'Approfondisci...',
    linkSection: '/#contenzioso-tributario',
    idSection: 'contenzioso-tributario',
    linkPage: '/contenzioso-tributario'
  }
];

const SSBar = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  background: linear-gradient(to left, #3E56A900 3%, #1B264Bf0,#3E56A990,  #1B264Bf0, #1B264B00 97%);
  margin: calc(0.0 * (14px + 1.0vw)) 0 calc(0.0 * (14px + 1.0vw)) 0;
`;

const SectionServiziOLD = () => {
  return (
    <SSeBox>
      <SectionTitle0 flip img={1} title="Prestazioni" subtitle="i piatti della casa" />
      <SectionHashTarget style={{ transform: "translateY(10px)" }} id="prestazioni" />
      {/* <SectionServizi2 /> */}
      {/* <SectionTitle shadow={false} bg={img2} align="right" title="Servizi e prestazioni" /> */}
      <Expandable0 servid={serviceItems[0].idSection} left isOpen img={img1} title={serviceItems[0].title} text={serviceItems[0].textShort} />
      <Expandable0 servid={serviceItems[1].idSection} img={img2} title={serviceItems[1].title} text={serviceItems[1].textShort} />
      <Expandable0 servid={serviceItems[2].idSection} left img={img3} title={serviceItems[2].title} text={serviceItems[2].textShort} />
      <Expandable0 servid={serviceItems[3].idSection} img={img4} title={serviceItems[3].title} text={serviceItems[3].textShort} />
      <Expandable0 servid={serviceItems[4].idSection} left img={img5} title={serviceItems[4].title} text={serviceItems[4].textShort} />
      <SSBar />
      <TextP className="pclass" left maxWidth="1100px" paramWidth="88%" verticalSpacing="calc(4.0 * (14px + 1vw))" ><span className="ptitle">ALTRI SERVIZI</span>La varietà di <strong>competenze</strong> dello Studio e il <strong>network</strong> di affiliazioni garantiscono un'ampia copertura della gamma di servizi tradizionali e straordinari: per informazioni su aree di intervento non illustrate, <LinkLink to="/#contatti">contattaci</LinkLink> e ti aiuteremo a ragionare sulla soluzione migliore</TextP>
    </SSeBox>
  );
}

const SSeBox = styled.div`
  z-index: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: /* calc(20px + 1vw) 0 0  */0;
  padding-bottom: calc(2.0 * (14px + 1.0vw));
  @media only screen and (min-width: 1441px) {
  padding-bottom: 50px;
  }
  .pclass {text-align:start;/* display: block;position:relative;
  margin-bottom: calc(3.0 * (14px + 1.0vw)); */}
  /* .pclass {
    width: 80%;
    max-width: 900px;
    font-size: calc(1.0 * (14px + 0.4vw));
    margin: calc(2.0 * (14px + 1vw)) 0;
  } */
`;

const risultatiItems = [
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>Modello 730</h2>,
    text: "di concentrarsi sulla crescita",
    icon: "fas fa-hands"
  },
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>Unico</h2>,
    text: "di non disperdere risorse",
    icon: "fas fa-hands"
  },
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>IMU e TASI</h2>,
    text: "di essere sempre aggiornati",
    icon: "fas fa-hands"
  }
];

const Expandable0 = ({ servid = "contabilita-bilancio", img, title = "SECTION TITLE", text = "Some short or pretty short text of some kind illustrating sharply the concept of the section item", ctaLink, ctaText = "Scopri di più", left = false, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const toggleOpen = () => { setOpen(s => !s); }

  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash && hash !== "" && hash === "#" + servid) { setOpen(true); }
  }, [hash]);// , hash ]);

  return (
    <E0BoxCollapsible onClick={toggleOpen} open={open} left={left} bg={img}>
      <SectionHashTarget id={servid} />
      <div className="extrabox">
        <MultiSnippet3Small theme="dark" color="#3E56A9" titles={[risultatiItems[0].title, risultatiItems[1].title, risultatiItems[2].title]} icons={[
          "fas fa-store", "fas fa-user-tie", "fas fa-wifi",
        ]} texts={[risultatiItems[0].text, risultatiItems[1].text, risultatiItems[2].text]} />
      </div>
      {left === true ?
        <>
          <E0OverlayMiddleLeft />
          <E0OverlayTextLeft open={open} >
            <TextH4 uppercase spaced color="#303030" firstletter>{title}<E0ItemIconLeft className={open === true ? "fas fa-minus" : "fas fa-plus"} /></TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
            {open === false ?
              <LinkA className="e0link">{/* ctaText */"Espandi"}<i className="servlinkicon fas fa-caret-down" /></LinkA> :
              <HI0 className="servlinkbox">
                <LinkA className="e0link e0llml">{/* ctaText */"Scopri di più"}<i className="servlinkicon fas fa-angle-double-right" /></LinkA>
                <HashLink smooth to="#contatti"><RippleButton effect="invert" rippleColor="#3E56A9" bgcolor="#3E56A9e0" iconSize={0.7} iconFAClass="fas fa-paper-plane" border="0px" color="#ffffff" text="Contattaci" /></HashLink></HI0>
            }
          </E0OverlayTextLeft>
        </> :
        <>
          <E0OverlayMiddleRight />
          <E0OverlayTextRight open={open} >
            <TextH4 uppercase spaced color="#303030" firstletter>{title}<E0ItemIconRight className={open === true ? "fas fa-minus" : "fas fa-plus"} /></TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
            {open === false ?
              <LinkA className="e0link">{/* ctaText */"Espandi"}<i className="servlinkicon fas fa-caret-down" /></LinkA> :
              <HI0 className="servlinkbox">
                <LinkA className="e0link e0llmr">{/* ctaText */"Scopri di più"}<i className="servlinkicon fas fa-angle-double-right" /></LinkA>
                <HashLink smooth to="#contatti"><RippleButton effect="invert" rippleColor="#3E56A9" bgcolor="#3E56A9e0" iconSize={0.7} iconFAClass="fas fa-paper-plane" border="0px" color="#ffffff" text="Contattaci" /></HashLink></HI0>
            }
          </E0OverlayTextRight>
        </>}
    </E0BoxCollapsible>
  );
}

const E0ItemIconLeft = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  color: #3E56A960;
  font-size: calc(0.8 * (18px + 0.7vw));
  transform: translate(180%, -50%);
  @media only screen and (min-width: 1441px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const E0ItemIconRight = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  color: #3E56A960;
  font-size: calc(0.8 * (18px + 0.7vw));
  transform: translate(-180%, -50%);
  @media only screen and (min-width: 1441px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

const E0Multi0 = styled.div`
  position: relative;
`;

const HI0 = styled.div`
  width: 80%;
  /* position: relative;
  top: 50%;
  right: calc(8.0 * (14px + 1.0vw));
  transform: translateY(-50%); */
  p {text-align: center;}
  i {display:inline;}
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const E0OverlayTextLeft = styled.div`
  position: relative;
  top: 0;
  /* left: 40%; */
  right: 0;
  order: 0;
  width: 60%;
    order: 2;
  /* width: 80%; */
  /* height: 100%; */
  background-image: linear-gradient(to left, #ffffffff 0vw, #ffffffff 25vw, #ffffffc0 100%);
  padding: ${props => props.open === true ? "50px 0 50px 0" : "50px"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* @media only screen and (max-width: 768px) {
    left: 25%;
    width: 75%;
  } */
  .servlinkicon {
    /* color:#3E56A9; */
    margin: 0 8px;
  }
  font-size: calc(1.0 * (14px + 0.4vw));
  /* h4 { font-size: calc(1.6 * (18px + 0.7vw)); } */
  h4 { font-size: calc(1.37 * (14px + 1vw)); }
  @media only screen and (min-width: 1200px) {
    font-size: calc(1.0 * (19px));
    h4 { font-size: calc(1.6 * (25px)); }
  }
  @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: ${props => props.open === true ? "25px" : "25px"};
    transition: background-color 0.3s;
    background-color: ${props => props.open === true ? "#ffffffd0" : "#ffffff90"};
    background-image: linear-gradient(to bottom, #000000c0 0px,  #00000000 6px,  #ffffff00 6px, #ffffff00 25%, #ffffffa0 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
    h4{
      font-weight: 800; letter-spacing: 0;
      }
  }
  p {text-align: end;margin-bottom: calc(0.3 * (14px + 1.0vw))}
ul,
menu {
  list-style: initial;text-align: start;margin: calc(0.8 * (14px + 1.0vw)) 0;padding:0 calc(0.8 * (14px + 1.0vw)) 0 10%;font-size:86%;
}
`;

const E0OverlayTextRight = styled(E0OverlayTextLeft)`
  /* left: 0; */
  right: 40%;
  left: 0;
  order: 0;
  /* background-image: linear-gradient(to right, #ffffffff, #fffffff0); */
  background-image: linear-gradient(to right, #ffffffff 0vw, #ffffffff 25vw, #ffffffc0 100%);
  padding: ${props => props.open === true ? "50px 0 50px 0" : "50px"};
  /* @media only screen and (max-width: 768px) {
    width: 75%;
  } */
  @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: ${props => props.open === true ? "25px" : "25px"};
    transition: background-color 0.3s;
    background-color: ${props => props.open === true ? "#ffffffd0" : "#ffffff90"};
    background-image: linear-gradient(to bottom, #000000c0 0px,  #00000000 6px,  #ffffff00 6px, #ffffff00 25%, #ffffffa0 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
    h4{
      font-weight: 800; letter-spacing: 0;
      }
  }
  /* @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.3s;
    background-color: ${props => props.open === true ? "#ffffff90" : "#ffffff00"};
    background-image: linear-gradient(to bottom, #000000c0 0%,  #00000000 2%,  #ffffff00 2%, #ffffff00 25%, #ffffffa0 25%, #ffffffc0 75%, #fffffff0 75%, #ffffffff 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
  } */
  p {text-align: start;}

`;

const E0OverlayMiddleLeft = styled.div`
  position: absolute;
  top: 0;
  left: 20%;
  width: 80%;
  height: 100%;
  background-image: linear-gradient(to left, #ffffff20, #ffffffa0);
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const E0OverlayMiddleRight = styled(E0OverlayMiddleLeft)`
  left: 0;
  background-image: linear-gradient(to right, #ffffff20, #ffffffa0);
`;

const E0BoxCollapsible = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;/*
  padding: ${props => props.open === true ? (props.left === true ? "100px 0 100px 0" : "100px 0 100px 0") : "100px"}; */
  min-height: ${props => props.open === true ? "200px" : "0px"};
  transition: all 0.3s;
  background-image: url(${props => props.bg});
  background-size: 75vw auto;
  background-position: ${props => props.left === true ? "0% 20%" : "100% 20%"};
  background-repeat: no-repeat;
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: ${props => props.left === false ? "flex-end" : "flex-start"}; */
  justify-content: space-between;
  align-items: center;
  .extrabox {
    order: 1;
    position: relative;
    width: 35%;
    z-index: 1;
    color: black !important;
    overflow: hidden;
    transition: opacity 0.4s;
    background-image: linear-gradient(to right, #ffffff00, #ffffffc0, #ffffffc0, #ffffff00);
    /* display: ${props => props.open === true ? "block" : "none"}; */
    opacity: ${props => props.open === true ? "1.0" : "0.0"};
    max-height: ${props => props.open === true ? "600px" : "0px"};
    div {
      transition: transform 0.75s 0.0s;
      transform:  ${props => props.open === false ? "translate(-200%, 0%)" : "translate(0%, 0%)"};
    }
    .ms3smallitem {
      width: 90%;
      margin: calc(0.5 * (14px + 1.0vw));
    }/* opacity: 0; */
  }
  @media only screen and (max-width: 900px) {
    background-size: cover;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* max-height: ${props => props.open === true ? "30%" : "0px"}; */
    min-height: ${props => props.open === true ? "200px" : "0px"};
    /* ${props => props.open === true && css`padding:0;`}; */
    .extrabox {
      order: 3;
      /* background-image: none; */background-image: linear-gradient(to right, #ffffffd0, #ffffffa0);
      background-color: white;
      width: 100%;
      .ms3smallbox {
        justify-content: center;
        padding-top: calc(10px + 0.4vw);
      }
      .ms3smallitem {
        width: 30%;
        margin: 0;
      }
    }
  }
  .e0text {
    max-width: 88%;
    transition: all 0.6s, opacity 0.4s;
    opacity: ${props => props.open === true ? "1.0" : "0.0"};
    max-height: ${props => props.open === true ? "500px" : "0px"};
    margin-top: ${props => props.open === true ? "calc(1.0 * (14px + 1.0vw))" : "0px"};
  }

  .e0link {
    font-size: calc(1.0 * (14px + 0.4vw));
    display: block;
    margin: ${props => props.open === true ? "calc(0.3 * (14px + 0.5vw)) 0 calc(2.0 * (14px + 0.5vw)) 0" : "0px"};
    /* transform: ${props => props.open === true ? "translate(75%, 0)" : "translate(0%, 0)"}; */
  }
  .e0llmr {
    align-self: flex-start;
  }
  .e0llml {
    align-self: flex-end;
  }
  @media only screen and (min-width: 1200px) {
    .e0link {font-size: calc(1.0 * (20px));}
  }
`;

export default SectionServiziOLD;
