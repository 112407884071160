import FAB from "comps/complex/FAB";
import Footer from "comps/compound/Footer";
import Header from "comps/compound/Header";
import Header2 from "comps/compound/Header2";
import Hero from "comps/compound/Hero";
import Hero2 from "comps/compound/Hero2";
import Hero3 from "comps/compound/Hero3";
import Hero4 from "comps/compound/Hero4";
import HeroFinal0 from "comps/compound/HeroFinal0";
import Menu from "comps/compound/Menu";
import Modal from "comps/compound/Modal";
import Nav2 from "comps/compound/Nav2";
import Main from "comps/pages/Main";
import { Sizes } from "data/Basic";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { useKey } from "react-use";
import styled, { css } from 'styled-components';

/*
performance checks
css: filter:, clip-path, animation
js: intersection-observer

export const Colors = {
  primary: "#3E56A9",
  accent: "#FCBF1E",
  lighter: "#A2AFDD",
  darker: "#293970",
  light: "#F0F2FA",
  dark: "#0E1325",
  white: "#FFFFFF",
  black: "#000000"
};
*/

const heroz = [<HeroFinal0 />, <Hero4 />, <Hero2 />, <Hero3 />, <Hero />, <Header2 />, <Header />];

function App() {
  const { pathname, hash } = useLocation(); /* e.g. (/, #contacts) or (/about-us, #where) */
  const [homepage, setHomepage] = useState(pathname === "/");
  const [pagetop, pagetopInView] = useInView({ threshold: 0.75 });
  const [pagebottom, pagebottomInView] = useInView({ threshold: 0.75 });


  const [head, setHead] = useState(0);
  const nextItem = () => (
    setHead(i => (

      ((i + 1) > 6) ?
        0 :
        (i + 1)
    ))
  );
  const prevItem = () => (
    setHead(i => (

      ((i - 1) < 0) ?
        6 :
        (i - 1)
    ))
  );
  useKey("g", prevItem);
  useKey("h", nextItem);
  /* const [sidebar, setSidebar] = useState(false);
  const sidebarShow = () => setSidebar(true);
  const [modal, setModal] = useState(false);
  const modalShow = () => setModal(true); */

  const [menuOpen, setMenuOpen] = useState(false);
  const menuShow = () => setMenuOpen(true);
  const menuDismissed = () => setMenuOpen(false);

  const [modalOpen, setModalOpen] = useState(false);
  const modalShow = () => setModalOpen(true);
  const modalDismissed = () => setModalOpen(false);

  const [fabVisible, setFabVisible] = useState(false);
  useEffect(() => { setFabVisible(!pagetopInView); }, [pagetopInView]);
  useEffect(() => { console.log("pathname: " + pathname); setHomepage(pathname === "/"); }, [pathname]);

  return (
    <AppBox /* grid */ /* onClick={() => setSidebar(s => !s)} */>

      <AppHero id="panoramica" ref={pagetop} show={homepage}>
        {
          heroz[head]
        /* head === 0 ? <HeroFinal0 /> : (head === 1 ? <Hero4 /> : (head === 2 ? <Hero2 /> : (head === 3 ? <Hero3 /> : (head === 4 ? <Hero /> : (head === 5 ? <Header2 /> : <Header />))))) */}
      </AppHero>
      <AppMain>
        <Main />
      </AppMain>
      <AppFooter id="foot" ref={pagebottom}>
        <Footer />
      </AppFooter>

      <AppTopbar full={homepage && pagetopInView}>
        <Nav2 menuOpen={menuOpen} menuToggleCallback={menuShow}
          shrinked={!pagetopInView} />
      </AppTopbar>

      {menuOpen && <Menu onMenuDismiss={menuDismissed} />}
      {modalOpen && <Modal onModalDismiss={modalDismissed} />}
      {fabVisible && <FAB fabCta={modalShow} fabUp={pagebottomInView} />}

      {/* <AppFAB up={pagebottomInView}></AppFAB> */}
      {/* <AppSidebar open={sidebar}></AppSidebar> */}
      {/* <AppModal open={modal}></AppModal> */}
    </AppBox>
  );
}

const appBaseIStyle = css`
  background-color: #ffffffa0;
  /* box-shadow: 0px 3px 12px #000000a0; */
`;

const AppHero = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: ${props => props.show === true ? "min(100vh, 750px)" : "0vh"};
  opacity: ${props => props.show === true ? "1.0" : "0.0"};
`;

const AppBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  /* font-family: 'Bodoni Moda', serif; */
  @media only screen and (min-width: 1441px) {
    max-width: 1441px;
    margin: auto;
    box-shadow: 6px 0px 20px #00000060;
  }
  ${props => props.grid === true && css`
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: repeating-linear-gradient(to right, #00000018 0%, #ffffff18 8.333%);
      z-index: 900;
      pointer-events: none;
    }
  `};
`;

const AppTopbar = styled.nav`
  position: fixed;
  height: ${props => "calc(" + (props.full === true ? Sizes.navHeightFullStr : Sizes.navHeightStr) + ")"};
  width: 100%;
  top: 0;
  left: 0;
  z-index: ${Sizes.topbarZIndex};
  ${appBaseIStyle};
  transition: all 0.4s;
  @media only screen and (min-width: 1441px) {
    top: 0;
    width: 1440px;
    /* right: calc((100vw - 1441px) / 2.0); */
    left: calc((100% - 1441px) / 2.0);
    height: ${props => "calc(" + (props.full === true ? Sizes.navHeightFullStrOver : Sizes.navHeightStrOver) + ")"};
  }
`;

const AppFAB = styled.div`
  position: fixed;
  width: calc(5.0 * (14px + 0.5vw));
  height: calc(5.0 * (14px + 0.5vw));
  right: calc(2.0 * (14px + 1.0vw));
  bottom: calc(1.0 * (14px + 1.0vw));
  background-color: #ffffffc0;
  box-shadow: 3px 3px 12px #000000a0;
  transition: all 0.4s;
  transform: ${props => props.up === true ? "translateY(calc( 0px - " + Sizes.footerHeightStr + "))" : "translateY(0px)"};
  border-radius: 999px;
  z-index: ${Sizes.FABZIndex};
`;

const AppSidebar = styled.div`
  position: fixed;
  height: 100vh;
  width: 30vw;
  top: 0;
  left: 0;
  transition: transform 0.4s, opacity 0.3s;
  transform: ${props => props.open === true ? "translateX(0%)" : "translateX(-100%)"};
  pointer-events: ${props => props.open === true ? "all" : "none"};
  opacity: ${props => props.open === true ? "1.0" : "0.0"};
  background-color: #ffffffc0;
  box-shadow: 4px 0px 16px #000000a0;
  z-index: ${Sizes.sidebarZIndex};
`;

const AppModal = styled.div`
  position: fixed;
  width: min(90vw, 1000px);
  height: min(90vh, 600px);
  top: 50%;
  left: 50%;
  transition: transform 0.4s, opacity 0.3s;
  pointer-events: ${props => props.open === true ? "all" : "none"};
  transform: ${props => props.open === true ? "translate(-50%, -50%)" : "translate(calc(-100% - 50vw), -50%)"};
  opacity: ${props => props.open === true ? "1.0" : "0.0"};
  background-color: #ffffffc0;
  box-shadow: 4px 0px 16px #000000a0;
  border-radius: calc(2.0 * (14px + 1.0vw));
  z-index: ${Sizes.modalZIndex};
`;

const AppMain = styled.main`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`; /* min-height: ${"calc(200vh - " + Sizes.footerHeightStr + ")"}; */

const AppFooter = styled.footer`
  position: relative;
  width: 100%;
  height: ${"calc(" + Sizes.footerHeightStr + ")"};
`;

export default App;
