import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useKey } from 'react-use';
import { Sizes } from "data/Basic";
import { RippleButton } from "comps/simple/RippleContainer";

const MBox = styled.div`
  position: relative;
  width: 100%;
  /* max-height: min(80vh, 540px); */
  overflow-y: auto;
  /* height: 100%; */
  /* padding: calc(1 * (14px + 1.0vw)) 0; */
  display: flex;
  /* flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center; */
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  font-size: calc(13px + 0.4vw);
  line-height: 1;
  padding-top: calc(1.0 * (14px + 0.6vw));
  padding-bottom: calc(2.0 * (14px + 1vw));
  .buttonboxclass {align-self: flex-end;margin-top: calc(1.4 * (14px + 1vw));}
  @media only screen and (max-width: 800px) {
    .buttonboxclass {align-self: center;}
  }
`;

const MField0 = styled.div`
  /* width: 90%;
  max-width: 400px; */
  width: 100%;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  /* padding: calc(0.2 * (14px + 0.4vw)) calc(2.4 * (14px + 0.4vw)) calc(0.2 * (14px + 0.4vw)) 0; */
  h4 {
    color: #ccc;
    font-size: calc(1.1 * (14px + 0.6vw));
    font-weight: 500;
    /* margin-left: calc(4.0 * (14px + 1.0vw)); */
    text-transform: uppercase;
  }
`;

const MField = styled.div`
  /* width: 90%;
  max-width: 400px; */
  width: 40%;
  min-width: 300px;
  /* flex-grow: 10; */
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: calc(2.0 * (14px + 1.0vw)) 0 0 0; */
  margin: calc(2.0 * (14px + 0.4vw)) 10px 0 10px;
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
`;

const MField3 = styled(MField)`
  /* max-width: 300px; */
  width: 50%;
  /* flex-grow: 0;
  flex-shrink: 1; */
  @media only screen and (max-width: 800px) {
    width: 80%;
  }
`;

const MLabel = styled.p`
  position: relative;
  margin-left: calc(0.4 * (14px + 1.0vw));
  text-transform: uppercase;
  font-weight: 600;
  color: #3E56A990;
  /* &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 110%;
    width: 160px;
    height: 2px;
    background-image: linear-gradient(to right, #3E56A990, #3E56A900);
  } */
`;

const MInput1 = styled.input`
  width: 100%;
  position: relative;
  border: 2px solid #3E56A9;
  border-width: 0 0px 1px 0px;
  color: #000000b0;
  /* border-radius: 25px; */
  outline: none;
  padding: calc(0.4 * (14px + 0.5vw)) calc(1.0 * (14px + 0.6vw));
  background-color: #ffffff00;
`;

const MInput2 = styled.input`
  position: relative;
  width: 100%;
  border: 2px solid #3E56A9;
  border-width: 0 0px 1px 0px;
  color: #000000b0;
  /* border-radius: 999px; */
  outline: none;text-align: center;
  padding: calc(0.4 * (14px + 0.5vw)) calc(2.0 * (14px + 0.6vw));
  background-color: #ffffff00;
    cursor: pointer;
`;

const MInput3 = styled.textarea`
  width: 100%;
  height: 160px;
  position: relative;
  border: 2px solid #3E56A9;
  border-width: 0px 1px 2px 0;
  color: #000000b0;
  border-radius: 25px;
  outline: none;
  padding: calc(0.4 * (14px + 0.5vw)) calc(1.0 * (14px + 0.6vw));
  background-color: #ffffff00;
  @media only screen and (max-width: 800px) and (max-height: 700px) {
    height: 100px;
  }
`;

const MField2 = styled(MField)`
  position: relative;
  /* max-width: 300px; */
  width: 30%;
  /* flex-grow: 0;
  flex-shrink: 1; */
  .fmseldpic {
    /* content: ''; */
    position: absolute;
    top: calc(25% - (0.6 * (14px + 1.0vw)));
    right: calc(1.0 * (14px + 0.5vw));
    font-size: calc(1.2 * (14px + 1.0vw));
    color: #3E56A9c0;
    z-index: 1;
    cursor: pointer;
    pointer-events:none;
    transition: all 0.3s;
    transform: ${props => props.selectOpen === false ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;

const MField2Select = styled.div`
  position: absolute;
  top: calc(4px + 40% - (0.5 * (14px + 1.0vw)));
  left: 12px;
  width: 160px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: calc(0.4 * (14px + 0.5vw)) 0 ;
  border-radius: 25px;
  background-color: #ffffffe0;
  box-shadow: 1px 1px 5px #00000080;
  z-index: ${Sizes.modalSelectZIndex};
  cursor: pointer;
  font-size: calc(12px + 0.2vw);
  opacity: 0.0;
  animation: appear 0.3s ease-out 0.0s 1 forwards;
  @keyframes appear {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
`;

const MField2SelectItem = styled.div`
  position: relative;
  height: 25px;
  width: 100%;
  padding: 0 calc(0.8 * (14px + 0.5vw));
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
  background-color: #ffffff00;
  color: #000000a0;
  transition: all 0.12s;
  z-index: ${Sizes.modalSelectZIndex};
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #3E56A920;
      color: #000000e0;
    }
  }
`;

const selItems = [
  "Altro", "Partita IVA", "Privati", "Startup", "Società"
];

const MDX = styled.i`
  position: absolute;
  top: calc(1.2 * (14px + 0.5vw));
  right: calc(2.0 * (14px + 0.5vw));
  font-size: calc(1.0 * (14px + 1.0vw));
  color: #b0b0b0;
  cursor: pointer;
  z-index: ${Sizes.modalSelectZIndex};
`;

const Modal = ({ onModalDismiss }) => {
  const [select, setSelect] = useState(false);
  const [selItem, setSelItem] = useState(0);
  const [dismissing, setDismissing] = useState(false);
  const dismiss = () => setDismissing(true);
  const animEnd = (e) => { if (e.animationName === "modal-go" || e.animationName === "modal-go-overwide") { onModalDismiss(); } }
  const clickOut = (e) => { e.stopPropagation(); dismiss(); }
  const clickIn = (e) => { e.stopPropagation(); }
  const clickInSelect = (e) => { setSelect(false); e.stopPropagation(); }
  const clickSelectItem = (e, iind) => { setSelItem(iind); setSelect(false); e.stopPropagation(); }
  useKey("Escape", dismiss);

  const firstUpdate = useRef(true);
  const location = useLocation();
  /* TODO: compare url and hash, this quits menu even if you click the link to your very url (location key changes) */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (firstUpdate.current === true) { firstUpdate.current = false; } else { dismiss(); } }, [location]);

  const toggleSelect = () => {
    setSelect(s => !s);
  }

  return (
    createPortal(
      <ModalOverlay onAnimationEnd={animEnd} onClick={clickOut} dismissing={dismissing}>
        <ModalDialog onClick={select ? clickInSelect : clickIn} dismissing={dismissing}>
          <MDX className="fas fa-minus" onClick={dismiss} />
          <MBox>
            <MField0>
              <h4>Lascia un messaggio</h4>
            </MField0>
            <MField>
              <MLabel>Nome</MLabel>
              <MInput1 />
            </MField>
            <MField>
              <MLabel>E-mail</MLabel>
              <MInput1 />
            </MField>
            <MField3>
              <MLabel>Testo</MLabel>
              <MInput3 />
            </MField3>
            <MField2 selectOpen={select}>
              {select &&
                <MField2Select>
                  {selItems.map(
                    (iit, iind) =>
                      <MField2SelectItem onClick={(e) => { clickSelectItem(e, iind); }}><p>{iit}</p></MField2SelectItem>
                  )}
                </MField2Select>
              }
              <i /* onClick={toggleSelect} */ className="fas fa-caret-down fmseldpic" />
              <MLabel>Area</MLabel>
              <MInput2 onClick={toggleSelect} value={selItems[selItem]} />
              <RippleButton onMyClick={dismiss} effect="invert" bgcolor="#ffffffa0" iconSize={0.8} iconFAClass="fas fa-paper-plane" rippleColor="#ffffff" border="2px" color="#3E56A9" text="Invia" />
            </MField2>
          </MBox>
        </ModalDialog>
      </ModalOverlay>
      , document.getElementById("modal-root")
    )
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffffc0;
  /* display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center; */
  z-index: ${Sizes.modalOverlayZIndex};
  display: block;
  animation: ${props => (props.dismissing === false) ? "modal-appear 0.2s ease-out 0.0s 1 forwards" :
    "modal-vanish 0.2s ease-out 0.0s 1 forwards"};
  @keyframes modal-appear { 0% { opacity: 0.0; display: block;} 100% { opacity: 1.0; display: block;} }
  @keyframes modal-vanish { 0% { opacity: 1.0; display: block;} 99% { opacity: 0.0; display: block;} 100% { opacity: 0.0; display: none;} }
`;

const ModalDialog = styled.div`
  position: fixed;
  width: min(90vw, 1000px);
  max-height: min(90vh, 600px);
  top: 50%;
  left: 50%;
  padding: calc(0.6 * (14px + 1.0vw)) calc(0.8 * (14px + 1.0vw));
  background-color: #fffffff0;
  box-shadow: 0px 0px calc(1.0 * (14px + 1.0vw)) #000000a0;
  border-radius: calc(2.0 * (14px + 1.0vw));
  z-index: ${Sizes.modalDialogZIndex};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* background-image: linear-gradient(to right, #272121f0, #272121ff); */
  /* @media only screen and (max-width: 1440px) { */
    animation: ${props => (props.dismissing === false) ? "modal-come 0.2s ease-out 0.0s 1 forwards" :
    "modal-go 0.2s ease-out 0.0s 1 forwards"};
  /* } */
  @keyframes modal-come {
    0% { transform: translate(-150%, -50%); opacity: 0.0; }
    100% { transform: translate(-50%, -50%); opacity: 1.0; }
  }
  @keyframes modal-go {
    0% { transform: translate(-50%, -50%); opacity: 1.0; }
    100% { transform: translate(-150%, -50%); opacity: 0.0; }
  }
/*
  @media only screen and (min-width: 1441px) {
    animation: ${props => (props.dismissing === false) ? "modal-come-overwide 0.3s ease-out 0.0s 1 forwards" :
    "modal-go-overwide 0.2s ease-out 0.0s 1 forwards"};
  }
  @keyframes modal-come-overwide {
    0% { transform: translate(-150%, -50%); opacity: 0.0; }
    100% { transform: translate(-50%, -50%); opacity: 1.0; }
  }
  @keyframes modal-go-overwide {
    0% { transform: translate(-50%, -50%); opacity: 1.0; }
    100% { transform: translate(-150%, -50%); opacity: 0.0; }
  } */
`;

export default Modal;
