import { LinkA, TextH4, TextP } from "comps/basic/Typography4";
import img1 from "img/header/anna-tallarico-studio-commercialista-milano-00.jpg";
import img2 from "img/header/anna-tallarico-studio-commercialista-milano-01.jpg";
import img3 from "img/header/anna-tallarico-studio-commercialista-milano-02.jpg";
import { useEffect, useState } from "react";
import styled from 'styled-components';
import SectionServizi2 from "comps/compound/SectionServizi2";
import SectionTitle0 from "comps/simple/SectionTitle0";
import { useLocation } from "react-router-dom";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";

const colorSSMain = "#3E56A9";
const colorSSTitleGray = "#a0a0a0";

const serviceItems = [
  {
    title: 'Contabilità e Bilancio',
    textShort: "La corretta e tempestiva rilevazione dei fatti amministrativi è di fondamentale importanza per",
    cta: 'Approfondisci...',
    linkSection: '/#contabilita-bilancio',
    idSection: 'contabilita-bilancio',
    linkPage: '/contabilita-bilancio'
  },
  {
    title: 'Consulenza societaria',
    textShort: "Lo Studio, nel corso degli anni, si è occupato dei più vari e complessi problemi societari. In particolare",
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-societaria',
    idSection: 'consulenza-societaria',
    linkPage: '/consulenza-societaria'
  },
  {
    title: 'Consulenza Fiscale',
    textShort: "Lo Studio Tallarico Anna propone una serie di servizi riguardanti gli adempimenti fiscali",
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-fiscale',
    idSection: 'consulenza-fiscale',
    linkPage: '/consulenza-fiscale'
  },
  {
    title: 'Contenzioso tributario',
    textShort: "Lo Studio Tallarico Anna offre assistenza al contribuente in caso di verifiche fiscali",
    cta: 'Approfondisci...',
    linkSection: '/#contenzioso-tributario',
    idSection: 'contenzioso-tributario',
    linkPage: '/contenzioso-tributario'
  },
  {
    title: 'Revisione dei conti',
    textShort: "La corretta e tempestiva rilevazione dei fatti amministrativi è di fondamentale importanza per",
    cta: 'Approfondisci...',
    linkSection: '/#revisione-dei-conti',
    idSection: 'revisione-dei-conti',
    linkPage: '/revisione-dei-conti'
  },
];

const SectionServizi = () => {
  return (
    <SSeBox>
      <SectionTitle0 flip img={1} title="Prestazioni" subtitle="i piatti della casa" />
      {/* <SectionServizi2 /> */}
      {/* <SectionTitle shadow={false} bg={img2} align="right" title="Servizi e prestazioni" /> */}
      <Expandable0 servid={serviceItems[0].idSection} isOpen img={img1} title={serviceItems[0].title} text={serviceItems[0].textShort} />
      <Expandable0 servid={serviceItems[1].idSection} left img={img2} title={serviceItems[1].title} text={serviceItems[1].textShort} />
      <Expandable0 servid={serviceItems[2].idSection} img={img3} title={serviceItems[2].title} text={serviceItems[2].textShort} />
      <Expandable0 servid={serviceItems[3].idSection} left img={img1} title={serviceItems[3].title} text={serviceItems[3].textShort} />
      <Expandable0 servid={serviceItems[4].idSection} img={img2} title={serviceItems[4].title} text={serviceItems[4].textShort} />
    </SSeBox>
  );
}

const SSeBox = styled.div`
  z-index: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: /* calc(20px + 1vw) 0 0  */0;
`;

const Expandable0 = ({ servid = "contabilita-bilancio", img, title = "SECTION TITLE", text = "Some short or pretty short text of some kind illustrating sharply the concept of the section item", ctaLink, ctaText = "Scopri di più", left = false, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const toggleOpen = () => { setOpen(s => !s); }

  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash && hash !== "" && hash === "#" + servid) { setOpen(true); }
  }, [hash]);// , hash ]);

  return (
    <E0Box onClick={toggleOpen} open={open} bg={img}>
      <SectionHashTarget id={servid} />
      {left === true ?
        <>
          <E0Overlay0 />
          <E0Overlay>
            <TextH4 uppercase color="#505050" firstletter>{title}</TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
            <LinkA className="e0link">{ctaText}</LinkA>
          </E0Overlay>
        </> :
        <>
          <E0Overlay02 />
          <E0Overlay2>
            <TextH4 uppercase color="#505050" firstletter>{title}</TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
            <LinkA className="e0link">{ctaText}</LinkA>
          </E0Overlay2>
        </>}
    </E0Box>
  );
}

const E0Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(to left, #ffffff80, #ffffffd0);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    left: 25%;
    width: 75%;
  }
`;

const E0Overlay2 = styled(E0Overlay)`
  left: 0;
  background-image: linear-gradient(to right, #ffffff80, #ffffffd0);
  @media only screen and (max-width: 768px) {
    width: 75%;
  }
`;

const E0Overlay0 = styled.div`
  position: absolute;
  top: 0;
  left: 20%;
  width: 80%;
  height: 100%;
  background-image: linear-gradient(to left, #ffffff20, #ffffffa0);
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const E0Overlay02 = styled(E0Overlay0)`
  left: 0;
  background-image: linear-gradient(to right, #ffffff20, #ffffffa0);
`;

const E0Box = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  padding: ${props => props.open === true ? "200px" : "100px"};
  min-height: ${props => props.open === true ? "400px" : "0px"};
  transition: all 0.3s;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  .e0text {
    max-width: 88%;
    transition: all 0.6s, opacity 0.4s;
    opacity: ${props => props.open === true ? "1.0" : "0.0"};
    max-height: ${props => props.open === true ? "500px" : "0px"};
  }
`;

export default SectionServizi;
