import styled from 'styled-components';
import img0 from 'img/header/header1b.png';
import img1 from 'img/header/header2e.png';
import img00 from 'img/header/anna-tallarico-studio-commercialista-milano-00.jpg';
import img01 from 'img/header/anna-tallarico-studio-commercialista-milano-01.jpg';
import img02 from 'img/header/anna-tallarico-studio-commercialista-milano-02.jpg';
import { useState } from 'react';
import { HashLink } from "comps/basic/HashLink";
import DotsNav from "comps/simple/DotsNav";
import { Sizes } from "data/Basic";

const hItems = [
  { img: img0 },
  { img: img1 }
];

const Hero2 = ({ headRef, fullNav = true }) => {
  const [sel, setSel] = useState(1);
  const nextItem = () => (setSel(i => ((i + 1) % hItems.length)));
  /* const newKey = () => "hbox" + (new Date().now().milliseconds()); */
  return (
    <HBox onClick={nextItem} ref={headRef} fullNav={fullNav} >
      {sel === 0 ?
        <HBox0 key={/* newKey +  */"hbox0" + sel} fullNav={fullNav} /* bg={img01} */ bg2={hItems[0].img}>
          <div className="hboxdiv">
            <div className="frow"><h2>Startup</h2><i className="fas fa-seedling"></i></div>
            <p>Per una crescita strategica e senza ostacoli</p>
            <div className="frow2"><HashLink to="">ESPLORA</HashLink><i className="fas fa-angle-double-right"></i></div>
          </div>
        </HBox0>
        :
        <HBox0 key={/* newKey +  */"hbox1" + sel} fullNav={fullNav} /* bg={img01} */ bg2={hItems[1].img}>
          <div className="hboxdiv">
            <div className="frow"><h2>Partite IVA</h2><i className="fas fa-hands-helping"></i></div>
            <p>Libere di lavorare senza preoccuparsi dei dettagli</p>
            <div className="frow2"><HashLink to="">ESPLORA</HashLink><i className="fas fa-angle-double-right"></i></div>
          </div>
        </HBox0>}
      <HeaderDots onClick={(e) => e.stopPropagation()}>
        <DotsNav marginPx={4} borderPx={0} dotsNumber={hItems.length} sizePx={23} activeDot={((sel > -1) && (sel < hItems.length)) ? sel : 0} dotsCallback={(ind) => { setSel(ind); }} color="#3E56A960" colorActive="#3E56A9f0" />
      </HeaderDots>
    </HBox>
  );
}

const HeaderDots = styled.div`
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%, 0%);
  padding: 5px 10px;
  z-index: 2;
  opacity: 1.0;
  transition: all 0.3s;
  @media only screen and (max-width: 800px) {
    background-color: #00000080;
    border-radius: 22px;
    box-shadow: 4px 4px 4px #00000030;
  }
  @media only screen and (max-width: 330px) {
    bottom: 15px;
  }
`;

const HBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
`;

const HBox0 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: appear 0.6s ease-out 0.0s 1 forwards;
  opacity: 0.0;
  cursor: pointer;
  @keyframes appear {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  padding-top: ${props => props.fullNav ? "calc(" + Sizes.navHeightFullStr + ")" : "calc(" + Sizes.navHeightStr + ")"};
  padding-left: calc(30px + 2vw);
  background-image: url(${props => props.bg2});
  background-size: auto 90%;
  background-position: 96% center;
  /* background-position: 4% center; */
  background-repeat: no-repeat;
  /* background-color: #ffffff90; */
  /* background-image: linear-gradient(to left,
    #ffffffff 0%, #ffffffe0 40%,
    #ffffffa0 40%, #ffffffa0 60%,
    #ffffff60 60%, #ffffff60 80%,
    #ffffff00 80%, #ffffff00 100%
  ); */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (min-width: 1441px) {
    padding-top: calc(${props => props.fullNav ? Sizes.navHeightFullStrOver : Sizes.navHeightStrOver});
  }
  .hboxdiv {
    margin: /* calc(30px + 3vw) */0 0 0 calc(20px + 2vw);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    background-image: linear-gradient(to right, #fffffff0, #fffffff0, #ffffffe0, #ffffffb0);
    box-shadow: 24px 12px 10px #00000008;
    border-radius: 15px 85px 15px 85px;
    padding: 40px 60px;
  }
    p {
      /* font-size: calc(1*(14px + 0.4vw));
      font-weight: 400; */
      font-size: calc(1.3*(14px + 0.4vw));
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      color: #606060d0;
      line-height: 1.3;
      /* max-width: 500px; */
      text-align: end;
      margin-top:calc(0.4*(14px + 0.4vw));
      margin-left:calc(5*(14px + 0.4vw));
    }
  .frow {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
  h2 {
    color: #3E56A9;
    font-size: calc(2.4*(14px + 0.4vw));
    font-weight: 600;
    /* text-transform: uppercase; */
    font-family: "Montserrat", sans-serif;
    color: #3E56A9b0;
    text-align: center;
    line-height: 1.3;
    }
    i {
      position: absolute;
      /* top: 30px; */
      left: -20px;
      /* margin-left: calc(0.5 * (12px + 1vw)); */
      font-size: calc(6.4*(14px + 0.4vw));
      color: #3E56A920;
    }
  }
  .frow2 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    margin-top: 12px;
    cursor: pointer;
    a {
      font-size: calc(1*(14px + 0.4vw));
      font-weight: 500;
      color: #3E56A9b0;
      line-height: 1.5;
      transition: all 0.25s;
    }
    i {
      margin-left: calc(0.3 * (12px + 1vw));
      font-size: calc(1.6*(14px + 0.4vw));
      color: #3E56A990;
      transition: all 0.25s;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        a {
          color: #3E56A9f0;
          transform: translateX(10px);
        }
        i {
          color: #3E56A9f0;
          transform: translateX(6px);
        }
      }
    }
  }
`;

export default Hero2;
