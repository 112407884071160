import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

const colorMSMain = "#3E56A9";
const colorMSTitleGray = "#a0a0a0";
const colorAccent = "#30A8FF";

export const MultiSnippet3Small = ({ titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], color = colorMSMain, columnMarginBottom, columnThreshold = "900px", theme = "dark", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <MS3BoxSmall className="ms3smallbox" columnThreshold={columnThreshold} ref={thisRef} {...moreProps}>
      <MS3ItemSmall className="ms3smallitem" inview={thisInView} index={0} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[0]} /><h3>{titles[0]}</h3></MS3ItemSmall>
      <MS3ItemSmall className="ms3smallitem" inview={thisInView} index={1} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[1]} /><h3>{titles[1]}</h3></MS3ItemSmall>
      <MS3ItemSmall className="ms3smallitem" inview={thisInView} index={2} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[2]} /><h3>{titles[2]}</h3></MS3ItemSmall>
    </MS3BoxSmall>
  );
}

const MS3BoxSmall = styled.div`
  position: relative;
  width: min(100%, 1200px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}
`;

const MS3ItemSmall = styled.div`
  position: relative;
  height: auto;
  /* width: 30%; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: calc(1.0 * (14px + 1.0vw)) calc(12px + 1vw);
  transform: translateX(-200%);
  opacity: 0.0;
  animation: ${props => props.inview === true ? "fallinplace3 0.6s ease " + ((props.index || 0) * 0.2) + "s 1 forwards" : "none"};
  @keyframes fallinplace3 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(-0%); opacity: 1.0; }
  }
  &>i {
    font-size: calc(16px + 1.4vw);
    @media only screen and (min-width: 1025px) {
      font-size: 29px;
    }
    /* color: ${props => props.color + "90"}; */
    color: ${props => props.color + "e0"};
  }
  &>h3 {
    font-size: calc(12px + 0.1vw);
    line-height: 1.25;
    font-weight: 600;
    /* color: ${props => props.theme === "dark" ? "#ffffff90" : (props.theme === "medium" ? "#ffffffa0" : "#000000a8")}; */
    color: ${props => props.theme === "dark" ? "#ffffffd0" : (props.theme === "medium" ? "#ffffffa0" : "#000000d8")};
    letter-spacing: 1px;
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) calc(12px + 0.12vw);
  }
  @media only screen and (max-width: 330px) {
    &>h3 {font-size: 12px;}
  }
  @media only screen and (max-width: ${props => props.columnThreshold}) {
    width: 90%;
    max-width: 460px;
    /* margin-bottom: ${props => props.columnMarginBottom !== "" ? props.columnMarginBottom : "initial"}; */
  }
  @media only screen and (min-width: 1441px) {
    margin: 15px;
    &>h3 {
      font-size: 14px;
      margin: 16px 0 15px 15px;
    }
  }
`;
export const MultiSnippet3 = ({ displace = false, titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = colorMSMain, columnMarginBottom, columnThreshold = "900px", theme = "dark", ...moreProps }) => {
  const [thisRef, thisInView,] = useInView({ threshold: 0.25, triggerOnce: true });
  return (
    <MS3Box columnThreshold={columnThreshold} ref={thisRef} {...moreProps}>
      <MS3Item displace={displace ? 0 : -1} className="ms3itembox1" onClick inview={thisInView} index={0} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[0]} /><h3>{titles[0]}</h3><p>{texts[0]}</p></MS3Item>
      <MS3Item displace={displace ? 1 : -1} className="ms3itembox2" inview={thisInView} index={1} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[1]} /><h3>{titles[1]}</h3><p>{texts[1]}</p></MS3Item>
      <MS3Item displace={displace ? 0 : -1} className="ms3itembox1" inview={thisInView} index={2} columnMarginBottom={columnMarginBottom} columnThreshold={columnThreshold} theme={theme} color={color}><i className={icons[2]} /><h3>{titles[2]}</h3><p>{texts[2]}</p></MS3Item>
    </MS3Box >
  );
}

const MS3Box = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  @media only screen and (min-width: 1441px) {margin: 26px 0;}

  /* @media only screen and (max-width: ${props => props.columnThreshold}) {
    .ms3itembox1 {
      transform: translate(-20%, 0%);
    }
    .ms3itembox2 {
      transform: translate(20%, 0%);
    }
  } */
`;

const MS3Item = styled.div`
  position: relative;
  height: auto;
  width: 25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: calc(12px + 1vw) 0;
  transform: translateX(-200%);
  opacity: 0.0;
  animation: ${props => props.inview === true ?
    (props.displace === -1 ? "fallinplace31" : (props.displace === 0 ? "fallinplace32" : "fallinplace33")) + " 0.6s ease " + ((props.index || 0) * 0.2) + "s 1 forwards" : "none"};
  @keyframes fallinplace31 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(0%); opacity: 1.0; }
  }
  @keyframes fallinplace32 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(30%); opacity: 1.0; }
  }
  @keyframes fallinplace33 {
    0% { transform: translateX(-200%); opacity: 0.0; }
    100% { transform: translateX(-30%); opacity: 1.0; }
  }
  &>i {
    font-size: calc(32px + 2vw);
    @media only screen and (min-width: 1025px) {
      font-size: 50px;
    }
    color: ${props => props.color + "c0"};
  }
  &>h3 {
    /* font-size: calc(16px + 0.25vw); */
    font-size: calc(1.2 * (13px + 0.1vw));
    line-height: 1.25;
    font-weight: 600;
    /* color: ${props => props.theme === "dark" ? "#ffffffc0" : (props.theme === "medium" ? "#ffffffd0" : "#00000078")}; */color: #b0b0b0;
    letter-spacing: 1px;
    &::first-letter { color: ${props => "" + props.color + "c8"}; }
    margin: calc(12px + 0.12vw) 0 calc(10px + 0.06vw) 0;
    /* @media only screen and (max-width: 330px) {
      font-size: calc(12px + 1vw);
    } */
  }
  &>p {
    /* font-size: calc(12px + 0.25vw); */
    font-size: calc(13px + 0.1vw);
    line-height: 1.5;
    font-weight: 400;
    /* color: ${props => props.theme === "dark" ? "#ffffffb0" : (props.theme === "medium" ? "#ffffffc0" : "#00000090")}; */color: 222;
  }
  @media only screen and (max-width: 330px) {
    &>h3 {font-size: 15px;}
    &>p {font-size: 13px;}
  }
  @media only screen and (max-width: ${props => props.columnThreshold}) {
    width: 90%;
    max-width: 460px;
    margin-bottom: ${props => props.columnMarginBottom !== "" ? props.columnMarginBottom : "initial"};
  }
  @media only screen and (min-width: 1441px) {
    margin: 26px 0;
    &>i {font-size: 60px;}
    &>h3 {
      font-size: 20px;
      margin: 16px 0 13px 0;
    }
    &>p {font-size: 16px;}
  }
`;

/* export const MultiSnippet3 = ({ titles, icons, texts, color = colorMSMain, spaced = true, upper = true }) => {
  return (
    <MS4Box spaced={spaced} upper={upper}>
      <MS3Item color={color}><MS4Icon iconColor={color} className={icons[0]} /><MS4Title color={color}>{titles[0]}</MS4Title><MS4Text>{texts[0]}</MS4Text></MS3Item>
      <MS3Item color={color}><MS4Icon iconColor={color} className={icons[1]} /><MS4Title color={color}>{titles[1]}</MS4Title><MS4Text>{texts[1]}</MS4Text></MS3Item>
      <MS3Item color={color}><MS4Icon iconColor={color} className={icons[2]} /><MS4Title color={color}>{titles[2]}</MS4Title><MS4Text>{texts[2]}</MS4Text></MS3Item>
    </MS4Box>
  );
} */

export const MultiSnippet4 = ({ five = false, titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = colorMSMain, spaced = true, upper = true, textColor = "#000000b0", iconColor, titleColor, firstL = false }) => {
  return (
    <MS4Box className="ms4box" spaced={spaced} upper={upper}>
      <MS4Item><MS4Icon num={0} iconColor={iconColor || color + "c0"} className={icons[0]} /><MS4Title firstL={firstL} iconColor={iconColor || color + "c0"} titleColor={titleColor || color + "d0"}>{titles[0]}</MS4Title><MS4Text textColor={textColor}>{texts[0]}</MS4Text></MS4Item>
      <MS4Item><MS4Icon num={1} iconColor={iconColor || color + "c0"} className={icons[1]} /><MS4Title firstL={firstL} iconColor={iconColor || color + "c0"} titleColor={titleColor || color + "d0"}>{titles[1]}</MS4Title><MS4Text textColor={textColor}>{texts[1]}</MS4Text></MS4Item>
      <MS4Item><MS4Icon num={2} iconColor={iconColor || color + "c0"} className={icons[2]} /><MS4Title firstL={firstL} iconColor={iconColor || color + "c0"} titleColor={titleColor || color + "d0"}>{titles[2]}</MS4Title><MS4Text textColor={textColor}>{texts[2]}</MS4Text></MS4Item>
      <MS4Item><MS4Icon num={3} iconColor={iconColor || color + "c0"} className={icons[3]} /><MS4Title firstL={firstL} iconColor={iconColor || color + "c0"} titleColor={titleColor || color + "d0"}>{titles[3]}</MS4Title><MS4Text textColor={textColor}>{texts[3]}</MS4Text></MS4Item>
    </MS4Box>
  );
}

const MS4Box = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  margin: calc(12px + 1vw) 0;
  h3 {
    letter-spacing: ${props => props.spaced === false ? "0" : "calc(1px + 0.1vw)"};
    text-transform:  ${props => props.upper === true ? "uppercase" : "none"};
  }
`;

const MS4Item = styled.div`
  position: relative;
  height: auto;
  width: 24%;
  padding: 1.25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
    /* &:nth-of-type(even) {
      transform: translate(0%, 18%);
    } */
  @media only screen and (max-width: 1100px) {
    width: 45%;
    max-width: 420px;
    margin-bottom: 2.5vmax;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 500px;
    margin-bottom: 2.5vmax;
    &:nth-of-type(odd) {h3{order: 0;}}
    &:nth-of-type(even) {h3{order: 2;}}
  }
`;

const MS4Icon = styled.i`
  width: 100%;
  font-size: calc(32px + 2vw);
  color: ${props => props.iconColor};
  filter: ${props => "brightness(" + (200 - props.num * 30) + "%) contrast(" + (100 + props.num * 20) + "%)"};
  transition: all 0.35s;
  @media only screen and (max-width: 800px) {
    width: auto;
    display: inline-block;
    order: 1;
  }
`;

const MS4Title = styled.h3`
  width: 100%;
  font-size: calc(16px + 0.25vw);
  font-weight: 600;
  /* color: ${colorMSTitleGray}; */
  color: ${props => props.titleColor};
  transition: all 0.35s;
  /* text-transform: uppercase; */
  &::first-letter { color: ${props => props.firstL === true ? props.iconColor : props.titleColor}; }
  margin: 8px 0;
  @media only screen and (max-width: 800px) {
    width: auto;
    /* background-color: green; */
    display: inline-block;
    margin: 8px 12px;

  }
`;

const MS4Text = styled.p`
  width: 100%;
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  color: ${props => props.textColor};
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 800px) {
    order: 3;
  }
  .more-link {
    position: relative;
    text-transform: uppercase;
    font-size: calc(11px + 0.1vw);
    font-weight: 600;
    align-self: flex-end;
    text-align: end;
    margin-right: calc(10px + 0.6vw);
    margin-top: 10px;
    color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    text-decoration: none;
    transition: color .25s ease-out;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
`;
