import React from 'react';
import styled from 'styled-components';

const DotsNav = ({ borderPx = -1, marginPx = -1, activeSwell = false,
  dotsNumber = 3, activeDot = 0, dotsCallback = (ind) => { },
  sizePx = 26, color = "#606060", colorActive = "#000000", vertical = false }) => {
  const dotsArray = Array(dotsNumber).fill(0);
  return (
    <DotsNavButtons vertical={vertical}>
      {dotsArray.map((it, ind) => (
        <DotsNavButton activeSwell={activeSwell} marginPx={marginPx > -1 ? marginPx : (sizePx / 4.0)} sizePx={sizePx} borderPx={borderPx > -1 ? borderPx : 0} vertical={vertical} active={ind === activeDot} color={color} colorActive={colorActive} key={"dotsnav-btn-" + ind}
          onClick={() => { dotsCallback && dotsCallback(ind); }} />))
      }
    </DotsNavButtons>
  );
}

const DotsNavButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${props => ((props.vertical === true) ? "column" : "row")};
  align-items: center;
  justify-content: center;
  padding: 7px 0;
`;

const DotsNavButton = styled.div`
  position: relative;
  margin: ${props => (props.vertical === false) ? ("0px " + props.marginPx + "px") : ("" + props.marginPx + "px 0")};
  width: ${props => props.sizePx + "px"};
  height: ${props => props.sizePx + "px"};
  border-radius: 50%;
  border-style: solid;
  border-color: ${props => props.colorActive};
  border-width: ${props => props.borderPx + "px"};
  background-color: ${props => (props.active === true) ? props.colorActive : props.color};
  transform: ${props => (props.activeSwell === true && props.active === true) ? "scale(1.15)" : "scale(1.0)"};
  transition: all 0.5s;
  cursor: pointer;
`;

export default DotsNav;
