import { HashLink } from "comps/basic/HashLink";
import styled from 'styled-components';
import img0 from 'img/header/header1b.png';
import img1 from 'img/header/header2f.png';
import { Colors, Sizes } from "data/Basic";
import { useState } from "react";
import DotsNav from "comps/simple/DotsNav";
import RippleButton, { BlueInvertRippleButton, BlueInvertRippleButtonOutline, BlueRippleButton } from "comps/simple/RippleContainer";

const hItems = [
  {
    title: "Partita IVA", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: img0, dens: "#A2AFDD40",
    icon: 'fas fa-people-carry'
  },
  {
    title: "Privati", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: img1, dens: "#3E56A940",
    icon: 'fas fa-hands-helping'
  },
  {
    title: "Startup", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: img0, dens: "#29397040",
    icon: 'fas fa-seedling'
  },
  {
    title: "Società", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: img1, dens: "#1B264B40",
    icon: 'fas fa-user-tie'
  }
];

const HI0 = styled.div`
a {display: block;}
  position: absolute;
  top: 50%;
  right: calc(8.0 * (14px + 1.0vw));
  transform: translateY(-50%);
  &>a {
    margin: calc(2.0 * (14px + 0.5vw));
  }
  p {text-align: center;}
  i {/* color: red; */}
`;

const Hero = () => {
  const [sel, setSel] = useState(0);
  const nextItem = () => (setSel(i => ((i + 1) % hItems.length)));
  return (
    <HeroBox key={/* newKey +  */"hbox0" + sel} onClick={nextItem} bg={hItems[sel].img}>
      <HeroItem>
        <HI0>
          <HashLink smooth to="#soluzioni"><RippleButton effect="invert" bgcolor="#ffffffa0" iconSize={0.8} iconFAClass="fas fa-hands-helping"  /* hoverColor="#ffffff" */ rippleColor="#ffffff" /* colorActive="#ffffff"  */ border="2px" color="#3E56A9" text="Soluzioni" /></HashLink>
          <HashLink smooth to="#contatti"><RippleButton effect="invert" rippleColor="#3E56A9" bgcolor="#3E56A9e0" iconSize={0.7} iconFAClass="fas fa-paper-plane" border="0px" color="#ffffff" text="Contattaci" /></HashLink>
        </HI0>
        <i className={"iclass " + hItems[sel].icon} />
        <h2>{hItems[sel].title}</h2>
        {hItems[sel].text}
        <HeaderDots onClick={(e) => e.stopPropagation()}>
          <DotsNav marginPx={4} borderPx={0} dotsNumber={hItems.length} sizePx={23} activeDot={((sel > -1) && (sel < hItems.length)) ? sel : 0} dotsCallback={(ind) => { setSel(ind); }} color="#3E56A960" colorActive="#3E56A9f0" />
        </HeaderDots>
      </HeroItem>
    </HeroBox>
  );
}
const HeaderDots = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: calc(0.4 * (14px + 0.5vw)) calc(0.8 * (14px + 0.5vw));
  border-radius: 999px;
  box-shadow: 2px 2px 6px #00000090;
  background-color: #ffffffa0;
`;
const HeroItem = styled.div`
  /* background-image: linear-gradient(to right, #ffffffd0, #ffffffd0 60%, #ffffffa0 60%, #ffffffa0 70%, #ffffff50 70%, #ffffff50 80%, #ffffff00 80%, #ffffff00 100%); */
  background-image: linear-gradient(to right, #fffffff0, #ffffff20);
  /* width: 80%; */
  width: 100%;
  /* height: 60%; */
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%); */
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: calc(3.0 * (14px + 1.0vw)) calc(6.0 * (14px + 1.0vw));
  margin: calc(2.0 * (14px + 1.0vw)) 0 0 /* calc(2.0 * (14px + 1.0vw)) */0;
  text-align: start;
  box-shadow: 0px 4px 12px #00000020;
  /* box-shadow: -15vw 10px 10px #00000040; */
  /* transform-origin: 0% 50%;
  transform: scale(0.85); */
  .iclass {
    position: absolute;
    /* top: calc(2.0 * (14px + 0.5vw));
    left: calc(2.0 * (14px + 0.5vw)); */
    top: calc(2.0 * (14px + 0.5vw));
    left: calc(6.0 * (14px + 0.5vw));
    /* font-size: calc(7.2 * (14px + 1.0vw)); */
    font-size: calc(6.0 * (14px + 1.0vw));
    color: ${Colors.primary + "30"};
    opacity: 0.0;
    animation: appeari 0.4s ease-out 0.0s 1 forwards;
    @keyframes appeari {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  h2{
    /* font-size: calc(3.0 * (14px + 0.8vw)); */
    font-size: calc(2.2 * (14px + 0.8vw));
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: calc(1px + 0.1vw);
    color: ${Colors.primary + "c0"};
    mix-blend-mode: normal;
    &::first-letter{color: ${Colors.primary};}
    /* transform: translate(25%, 0%); */
    transform: translate(25%, 0%);
    text-align: center;
    opacity: 0.0;
    animation: appearh2 0.4s ease-out 0.0s 1 forwards;
    @keyframes appearh2 {
      0% { opacity: 0.0; transform: translate(-50%, 0%); }
      100% { opacity: 1.0; transform: translate(25%, 0%); }
    }
  }
  .pclass{
    /* font-size: calc(1.0 * (14px + 0.8vw)); */
    font-size: calc(0.9 * (14px + 0.7vw));
    transform: translate(26%, 0%);
    width: min(100%, 520px);
    text-align: start;
    opacity: 0.0;
    color: #555;
    animation: appearp 0.6s ease-out 0.0s 1 forwards;
    @keyframes appearp {
      0% { opacity: 0.0; transform: translate(-50%, 0%); }
      100% { opacity: 1.0; transform: translate(26%, 0%); }
    }
  }
`;

const HeroBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: calc((120px + 4vw));
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: red; */
  /* cursor: inherit; */
  background-color: white;
  background-image: url(${props => props.bg});
  background-size: auto 90%;
  background-position: 96% 5vh;
  background-repeat: no-repeat;

  animation: appearhi 0.6s ease-out 0.0s 1 forwards;
  opacity: 0.3;
  /* cursor: pointer; */
  @keyframes appearhi {
    0% { opacity: 0.3; }
    100% { opacity: 1.0; }
  }
`;

export default Hero;
