import styled, { css } from 'styled-components';
import bg0 from "img/sectiontitle-bg-0.jpg";
import bg1 from "img/ui/transtt014407.png";

const bgs = [bg0, bg0];
const SectionTitle0 = ({ curved = false, align = "left", flip = false, title = "Contatti", subtitle = "", img = 0 }) => {
  return (
    <ST0Box align={align} flip={flip} bg={bgs[0]}>
      <ST00 curved={curved} />
      <div className="stov0" />
      <div className="stov1" />
      <h2>{title}</h2>
      <h4>{subtitle}</h4>
    </ST0Box>
  );
}

const ST00 = styled.div`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: calc(4.4 * (14px + 1.0vw));
    background: /* #00000000 */  linear-gradient(to bottom, #1B264Bf0, #3E56A9f0);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, calc(100% - 100px) 100%, calc(100% - 145px) 50%, 0% 50%);
  /* background: #3E56A990 linear-gradient(to left, #3E56A9b0, #1B264Bb0); */
  mix-blend-mode: multiply;
  z-index: 1;
  @media only screen and (min-width: 1000px) {
    height: calc(5.8 * (14px + 1.0vw));
    background: /* #000000ff */ linear-gradient(to left, #3E56A9f0, #1B264Bf0);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    ${props => props.curved && css`
      height: calc(7.8 * (14px + 1.0vw));
      background-image: url(${bg1});
      background-size: 100% 100%;
      transform: translate(0%, -44%);
      clip-path: none;
    `};

    /* clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 47.5% 100%, 44% 50%, 0% 50%); */
    ${props => props.curved === false && css`
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 845px 100%, 780px 50%, 0% 50%)
    `};
  }
  @media only screen and (min-width: 1441px) {
    height: calc(5.8 * (28px));
    ${props => props.curved && css`
      height: calc(7.8 * (28px));
    `};
  }
`;

const ST0Box = styled.div`/*
border-top: 1px solid #3E56A9; */
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: ${props => props.align === "left" ? "flex-start" : "flex-end"};
  /* padding: calc(1.4 * (10px + 1vw)) 0; */
  padding: calc(3.6 * (14px + 1.0vw)) 0 calc(1 * (14px + 1.0vw)) 0;
  /* margin-bottom: calc(2 * (10px + 1vw)); */
  /* background-image: linear-gradient(to right, #ffffffff, #ffffffe8, #ffffffd0, #ffffff50), linear-gradient(to bottom, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffffff); */

    /* z-index: 2; */
  h2 {
    transition: all 0.35s;
    font-size: calc(3.8 * (12px + 0.6vw));
    font-weight: 600;
    letter-spacing: 1px;
    color: #555;
    line-height: 1;
    @media only screen and (min-width: 1000px) {
    line-height: 1.5;
  }
    text-align: center;
    transform: ${props => props.align === "left" ? "translateX(10vw)" : "translateX(-10vw)"};
    &::first-letter { color: #3E56A9; }
    position: relative;
    font-family: 'Bodoni Moda', serif;
  }
  h4 {
    position: relative;
    line-height: 2;
    transition: all 0.35s, font-size 0.0s;
    /* font-family: 'Bodoni Moda', serif; */
    letter-spacing: calc(1px + 0.1vw);
    font-size: calc(1.1 * (12px + 0.6vw));
    font-weight: 600;
    text-transform: uppercase;
    color: #3E56A980;
    text-align: end;
    transform: ${props => props.align === "left" ? "translateX(20vw)" : "translateX(-20vw)"};
    &::after {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      background-image: linear-gradient(to ${props => props.align === "left" ? "left" : "right"}, #3E56A900, #3E56A920, #3E56A9f0, #3E56A920, #3E56A900);
      width: 300%;
      height: 3px;
      transform: ${props => props.align === "left" ? "translateX(-35%)" : "translateX(-35%)"};
    }
  }
  @media only screen and (max-width: 768px) {
    align-items: center;
    h2 {
      transform: translateX(-15%);
    }
    h4 {
      transform: translateX(-15%);
      &::after {
        width: 100%;
        background-image: linear-gradient(to ${props => props.align === "left" ? "right" : "left"}, #3E56A900, #3E56A9a0, #3E56A900);
        transform: translateX(0) /* scale(1) */;
      }
    }
  }
  .stov0 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bg});
    background-size: 100% auto;
    background-position: 50% 36%;
    background-repeat: no-repeat;
    ${props => props.flip === true && css`transform: scaleX(-1);`};
    filter: grayscale(75%);
   /*  z-index: 1; */
  }
  .stov1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;background-image: linear-gradient(${props => props.align === "left" ? "45deg" : "-45deg"},
    #ffffffff 0%, #ffffffd0 40%,
    #ffffffc0 40%, #ffffffc0 60%,
    #ffffff40 60%, #ffffff40 80%,
    #ffffff00 80%, #ffffff00 100%),
    linear-gradient(to bottom, #ffffff00, #ffffffa0, #ffffffff);
   /*  z-index: 1; */
  }
  /* &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #3E56A9a0 0px, #3E56A900 12px);
  } */
  @media only screen and (min-width: 1441px) {
    padding: calc(3.6 * (28px)) 0 calc(1 * (28px)) 0;
    h2 {
      font-size: calc(3.8 * (21px));
      transform: ${props => props.align === "left" ? "translateX(140px)" : "translateX(-140px)"};
    }
    h4 {
      letter-spacing: calc(1px + (1.4 * (1px)));
      font-size: calc(1.1 * (21px));
      transform: ${props => props.align === "left" ? "translateX(280px)" : "translateX(-280px)"};
      }
    }
  }
`;

export default SectionTitle0;
