import styled from 'styled-components';
import logo512 from 'img/logobase2-1024.png';
import SpreadMenu from "comps/compound/SpreadMenu";
import { HashLink } from "comps/basic/HashLink";
import { Sizes } from "data/Basic";

const Nav2 = ({ shrinked = false, menuToggleCallback, menuOpen }) => {
  return (
    <NavBox shrinked={shrinked}>
      <NavTitle shrinked={shrinked}>
        <NavLogo shrinked={shrinked} smooth to="/#panoramica"><img src={logo512} alt="Nadia Silistrini" /></NavLogo>
        <h2>Anna Tallarico</h2>
        <h4>Studio commercialista</h4>
      </NavTitle>
      <NavMenu shrinked={shrinked} menuOpen={menuOpen}>
        <SpreadMenu shrinked={shrinked} />
        <i onClick={(e) => { e.stopPropagation(); menuToggleCallback(); }} className="fas fa-bars" />
      </NavMenu>
    </NavBox>
  );
}

const NavBox = styled.div`
  height: ${props => "calc(" + ((props.shrinked === true) ? Sizes.navHeightStr : Sizes.navHeightFullStr) + ")"};
  width: 100%;
  /* padding: calc(6px + 0.2vw) calc(12px + 0.8vw); */padding: ${props => (props.shrinked === false) ? "calc(0.25 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw)) calc(0.25 * (14px + 1.0vw)) calc(5.0 * (14px + 1.0vw))" : "calc(0.25 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw)) calc(0.25 * (14px + 1.0vw)) calc(4.0 * (14px + 1.0vw))"};
  transition: all 0.4s;
  box-shadow: ${props => (props.shrinked === false) ? "0px 3px 6px #00000050" : "0px 3px 6px #000000b0"};
    background-image: ${props => (props.shrinked === true) ?
    "linear-gradient(to right, #ffffffc8, #ffffffb8)" :
    "linear-gradient(to right, #fffffff0, #ffffffd8)"};
  color: #202020;
  pointer-events: all;
  /* background-color: white; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: ${props => (props.shrinked === false) ? "space-between" : "flex-start"};
  align-items: center;
  @media only screen and (max-width: 600px) {
    justify-content: center;
    /* padding: ${props => (props.shrinked === false) ? "calc(0.25 * (14px + 1.0vw)) calc(0.7 * (14px + 1.0vw)) calc(0.25 * (14px + 1.0vw)) calc(4.5 * (14px + 1.0vw))" : "calc(0.25 * (14px + 1.0vw)) calc(0.7 * (14px + 1.0vw)) calc(0.25 * (14px + 1.0vw)) calc(3.6 * (14px + 1.0vw))"}; */
  }
  @media only screen and (min-width: 1441px) {
    height: ${props => "calc(" + ((props.shrinked === true) ? Sizes.navHeightStrOver : Sizes.navHeightFullStrOver) + ")"};
    /* padding: ${props => (props.shrinked === false) ? "calc(0.25 * (14px + 14px)) calc(2.0 * (14px + 14px)) calc(0.25 * (14px + 14px)) calc(5.0 * (14px + 14px))" : "calc(0.25 * (14px + 14px)) calc(2.0 * (14px + 14px)) calc(0.25 * (14px + 14px)) calc(4.0 * (14px + 14px))"}; */
  }
`;

const NavLogo = styled(HashLink)`
  position: absolute;
  top: 50%;
  left: 0;
  height: ${props => "calc(1 * (" + ((props.shrinked === true) ? Sizes.navHeightStr : Sizes.navHeightFullStr) + "))"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  padding-right: calc(0.15 * (14px + 1.0vw));
  img {
    transition: all 0.2s;
    position: relative;
    height: 80%;
    width: auto;
    /* @media only screen and (max-width: 600px) {
      height: 65%;
    } */
  }
  transform: translate(-104%, -50%);
  opacity: 1;
  /* @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 5%;
    width: auto;
    height: 100%;
    opacity: ${props => (props.shrinked === true) ? "1" : "0.4"};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.shrinked === true) ? "translateX(-20%)" : "translateX(0%)"};
    img {
      position: relative;
      height: ${props => (props.shrinked === true) ? "calc(100% - 16px)" : "calc(100% - 16px)"};
      width: auto;
    }
  } */
  @media only screen and (min-width: 1441px) {
    height: ${props => "calc(1 * (" + ((props.shrinked === true) ? Sizes.navHeightStrOver : Sizes.navHeightFullStrOver) + "))"};
  }
`;

const NavLogoOLD = styled(HashLink)`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  img {
    transition: all 0.2s;
    position: relative;
    height: 70%;
    width: auto;
    @media only screen and (max-width: 600px) {
      height: 65%;
    }
  }
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 5%;
    width: auto;
    height: 100%;
    opacity: ${props => (props.shrinked === true) ? "1" : "0.4"};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.shrinked === true) ? "translateX(-20%)" : "translateX(0%)"};
    img {
      position: relative;
      height: ${props => (props.shrinked === true) ? "calc(100% - 16px)" : "calc(100% - 16px)"};
      width: auto;
    }
  }
`;

const NavTitle = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
  /* width: calc(11 * (19px + 1.8vw)); */
  /* margin-left: ${props => !props.shrinked ? "0" : "calc(0.5 * (14px + 0.5vw))"}; */
  h2 {
    transition: all 0.35s;
    font-family: 'Bodoni Moda', serif;
    /* font-size: ${props => props.full ? "calc(4 * (12px + 0.6vw))" : "calc(2.6 * (12px + 0.6vw))"}; */
    /* font-size: ${props => !props.shrinked ? "calc(3 * (12px + 0.6vw))" : "calc(3.4 * (12px + 0.6vw))"}; */
    font-size: calc(0.84 * 2.85 * (12px + 0.6vw));
    line-height: 0.9;
    font-weight: 600;
    /* letter-spacing: ${props => !props.shrinked ? "calc(1px)" : "0px"}; */
    /* text-transform: uppercase; */
    color: #404040;
    text-align: center;
    /* &::first-letter { color: #3E56A9; } */
  }
  h4 {
    transition: all 0.35s, font-size 0.0s;
    font-family: 'Bodoni Moda', serif;
    letter-spacing: calc(1px + 0.1vw);
    /* font-size: ${props => props.full ? "calc(1.4 * (12px + 0.6vw))" : "calc(0 * (12px + 0.6vw))"}; */
    font-size: ${props => !props.shrinked ? "calc(0.74 * 1.0 * (12px + 0.6vw))" : "calc(0 * (12px + 0.6vw))"};
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;
    color: #3E56A9a0;
    /* text-align: center; */
    align-self: flex-end;
    /* &::first-letter { color: #30A2FF; } */
    /* transform: translateX(-12%); */
    @media only screen and (max-width: 700px) {
      font-size: ${props => !props.shrinked ? "calc(1.1 * (12px + 0.6vw))" : "calc(0 * (12px + 0.6vw))"};
    }
  }
  transform: scale(0.9);
  opacity: 0.86;
  @media only screen and (min-width: 1441px) {
    h2 {
      font-size: calc(0.84 * 2.85 * (12px + 8px));
    }
    h4 {
      letter-spacing: calc(1px + (0.14 * 1px)));
      font-size: ${props => !props.shrinked ? "calc(0.74 * 1.0 * (12px + 0.6vw))" : "calc(0 * (12px + 0.6vw))"};
    }
  }
`;

const NavMenu = styled.div`
  position: relative;
  padding-top: ${props => (props.shrinked === true) ? "8px" : "0"};
  margin-left: ${props => props.shrinked === true ? "auto" : "0"};
  /* margin-right: calc(1.0 * (14px + 1.0vw)); */
  transition: all 0.25s;
  &>i {
    padding-top: ${props => (props.shrinked === true) ? "7px" : "0"};
    color: #0099cc;
    cursor: pointer;
    font-size: calc(20px + 1vw);
    transition: all 0.25s;
    transform: ${props => (props.menuOpen === true) ? "rotateZ(90deg)" : "rotateZ(0deg)"};
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  @media only screen and (min-width: 1000px) {
    &>i {
      font-size: calc(20px + 1vw);
      display: none;
    }
  }
  @media only screen and (min-width: 1441px) {
    /* &>i {
      font-size: calc(20px + 1vw);
      display: block;
    }
    &>div {
      display: none;
    } */
  }
  @media only screen and (max-width: 999px) {
    margin-left: ${props => props.shrinked === true ? "auto" : "calc(0.25 * (14px + 0.5vw))"};
    &>div {
      display: none;
    }
  }
`;

export default Nav2;
