import styled from 'styled-components';
import { useState } from 'react';
import imgpiva from "img/clienti/freelance4.jpg";
import imgprivati from "img/clienti/privati4.jpg";
import imgstartup from "img/clienti/startup4.jpg";
import imgaziende from "img/clienti/aziende4.jpg";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";

const picW = 3375;
const picH = 2250;
const picR = picH / picW;
const picN = 4;
const w = 600;

const TBox = styled.div`
  position: relative;
  width: 800px;
  height: 450px;
  cursor: pointer;
  border-radius: calc(1 * (10px + 1vw)) calc(6 * (10px + 1vw)) calc(1 * (10px + 1vw)) calc(6 * (10px + 1vw));
  overflow: hidden;
  box-shadow: 3px 3px 6px #000000b0;
    padding: 20px 0;
    margin: 20px 0 50px 0;
  @media only screen and (max-width: 820px) {
    width: 100%;
    height: calc(98.4vw * (4.5/8.0));
    border-radius: 0;
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 720px) {
    width: 100%;
    height: 100vw;
    border-radius: 0;
    /* max-width: 100%; */
    /* height: 98.4vw; */
    /* height: calc(98.4 * 8px); */
    /* max-height: calc(98.4 * 7px); */
  }
  @media only screen and (min-width: 1000px) {
    overflow: visible;
    box-shadow: 3px 3px 6px #00000000;
    width: 100%;
    height: unset;
    /* height: 380px; */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    border-radius:unset;
    /* padding: 20px 0; */
    padding: 20px 0 0 0;
    /* margin: 20px 0; */
    margin: 20px 0 0 0;
  }
  .ms0i:last-child{
    transform-origin: center right;
  }
`;

const TItem = styled.div`
  user-select: none;
  transition: all 0.3s;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* filter: ${props => props.selected === true ? "contrast(110%)" : "contrast(100%)"}; */
    i {
      position: absolute;
    /* left: calc((1.0 * (14px + 0.4vw))); */right:calc(0.75 * (14px + 0.5vw));
    /* top: calc((1.0 * (14px + 0.4vw))); */bottom:calc(0.75 * (14px + 0.5vw));/* transform: translate(-50%, -50%); */
    position: absolute;
    z-index:  ${props => props.selected === true ? "3" : "1"};
    /* color: ${props => props.selected === true ? "#ffffff70" : "#ffffff00"}; */
    color: ${props => props.selected === true ? (props.color + "b0") : (props.color + "00")};
    transition: all 0.3s;
    font-size: ${props => props.selected === true ? "calc(2.0 * (14px + 0.2vw))" : "calc(0.0 * (14px + 0.4vw))"};

  }
  h2 {
    position: relative;
    transition: font-size 0.3s;
    width: 100%;
    padding: ${props => props.selected === true ? "calc(20px + 0.4vw) 12.5% 0 12.5%" :
    "calc(12px + 0.3vw) 12.5%"};
    /* background-image: ${props => props.selected === true ? "linear-gradient(to right, #0E132560, #0E132580)" :
    "linear-gradient(to right, #0E132510, #0E132540)"}; */
    background-image: ${props => props.selected === true ? "linear-gradient(to right, #000000b8, #000000d8, #000000d8)" :
    "linear-gradient(to right, #00000068, #000000a0, #000000a0)"};
    /* background-color: ${props => props.selected === true ? "#0E132590" :
    "#0E132540"}; */
    /* background-color: ${props => props.selected === true ? "#F0F2FAb0" :
    "#F0F2FA00"}; */
    color: ${props => props.selected === true ? "#ffffffff" : "#ffffffa0"};
    /* color: ${props => props.selected === true ? "#000000ff" : "#000000c0"}; */
    /* font-size: ${props => props.selected === true ? "calc(24px + 0.6vw)" : "calc(0.65 * (24px + 0.6vw))"};
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px; */

    /* font-size: calc(1.2 * (14px + 0.4vw)); */
    font-size: ${props => props.selected === true ? "calc(1.3 * (14px + 0.4vw))" : "calc(1.1 * (14px + 0.4vw))"};
    font-weight: 500;
          color: #ffffffb0;
    line-height: 1.6;
    text-transform: uppercase;
    letter-spacing: calc(0.06 * (14px + 0.5vw));
    z-index:  1;
    /* &::first-letter { color: ${props => props.color + "c0"}; *//* #FCBF1Ec0; */ }
  }
  p {
    position: relative;
    transition: color 0.5s 0.3s;
    width: 100%;
    padding: ${props => props.selected === true ? "0 13.5% calc(20px + 0.4vw) 13.5%" :
    "0"};
    /* background-color: ${props => props.selected === true ? "#0E132590" :
    "#0E132500"}; */
    /* background-image: ${props => props.selected === true ? "linear-gradient(to right, #0E132560, #0E132580)" :
    "linear-gradient(to right, #0E132510, #0E132540)"}; */
    background-image: ${props => props.selected === true ? "linear-gradient(to right, #000000b8, #000000d8, #000000d8)" :
    "linear-gradient(to right, #00000000, #00000000)"};
    /* background-color: ${props => props.selected === true ? "#F0F2FAb0" :
    "#F0F2FA00"}; */
    color: ${props => props.selected === true ? "#ffffffb0" :
    "#ffffff00"};
    /* color: ${props => props.selected === true ? "#000000c0" :
    "#00000000"}; */
    font-size: ${props => props.selected === true ? "calc(12px + 0.2vw)" : "0.0"};
    font-weight: 400;
    opacity: ${props => props.selected === true ? "1.0" : "0.0"};
    z-index:  1;
  }
  z-index:  ${props => props.selected === true ? "2" : "1"};

  .tgbtover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    /* background-color: ${props => props.selected === false ? "#1B264B90" : "#1B264B40"}; */
    /* background-image: ${props => props.selected === true ? "radial-gradient(#1B264B00, #1B264B50)" : "linear-gradient(115deg, #1B264B00, #1B264B00, #1B264B80)"}; */
    /* background-color: ${props => props.selected === true ? "#F0F2FA30" : "#F0F2FAb0"}; */
    /* background-image: ${props => props.selected === true ? "radial-gradient(#ffffff00, #Ffffff00)" : "radial-gradient(#ffffff40, #Ffffff40)"}; */
    /* background: #3E56A990 linear-gradient(to right, #3E56A940, #1B264B80); */
    background:  ${props => props.selected === true ? ("linear-gradient(to left, #ffffff60, #ffffff90) " + props.color + "60") :
    ("linear-gradient(to left, #ffffff50, #ffffff90) " + props.color + "20")};
    mix-blend-mode: ${props => props.selected === true ? "multiply" : "normal"};
  }

  top: ${props => props.selected === true ? "0" : (props.area * 100) / (picN - 1) + "%"};
  left: ${props => props.selected === true ? "0" : 100 * (picN - 1) / picN + "%"};
  height: ${props => props.selected === true ? "100%" : 100 / (picN - 1) + "%"};
  width: ${props => props.selected === true ? 100 * (picN - 1) / (picN) + "%" : 100 / (picN) + "%"};

  @media only screen and (max-width: 720px) {
    left: ${props => props.selected === true ? "0" : (props.area * 100) / (picN - 1) + "%"};
    /* top: ${props => props.selected === true ? "0" : 100 * (picN - 1) / picN + "%"}; */
    top: ${props => props.selected === true ? 100 * (1) / picN + "%" : "0%"};
    width: ${props => props.selected === true ? "100%" : 100 / (picN - 1) + "%"};
    height: ${props => props.selected === true ? 100 * (picN - 1) / (picN) + "%" : 100 / (picN) + "%"};
  }

  @media only screen and (min-width: 1000px) {
    position: relative;
    flex-grow: ${props => props.selected === true ? "10" : "5"};
    /* border-radius: ${props => props.selected === true ? "10px 80px 10px 80px" : "0px"}; */
    box-shadow: ${props => props.selected === true ? "4px 4px 12px #00000090" : "2px 2px 12px #00000000"};
    z-index:  ${props => props.selected === true ? "2" : "1"};
    /* overflow: hidden; */
    /* height: 100%; */
    width: ${props => props.selected === true ? "60%" : "34%"};
    /* height: ${props => props.selected === true ? "380px" : "calc(0.8 * (380px))"}; */
    height: 280px;
    top: unset;
    left:unset;/*
    margin: 20px 0; */
    transform: ${props => props.selected === true ? "scale(1.05)" : "scale(1.0)"};
  i {
    /* position: absolute;
    z-index:  ${props => props.selected === true ? "3" : "1"};
    top: calc(0% - (1.5 * (14px + 0.4vw)));
    left: calc(50% - (3.0 * (14px + 0.4vw))); */
    /* color: ${props => props.selected === true ? "#ffffff70" : "#ffffff00"}; */
    /* color: ${props => props.selected === true ? (props.color + "a0") : (props.color + "00")};
    transition: all 0.3s;
    font-size: ${props => props.selected === true ? "calc(6.0 * (14px + 0.2vw))" : "calc(0.0 * (14px + 0.4vw))"}; */
    /* mix-blend-mode: normal; */

  }
  }

  @media only screen and (min-width: 1200px) {
    width: ${props => props.selected === true ? "40%" : "20%"};
    height: 360px;
  }

`;

const MultiShowOne0 = () => {
  const [selected, setSelected] = useState(0);
  return (
    <>
      <TBox>
        {targetItems.map((sItem, sIndex) => (
          <TItem color={sItem.color} onClick={() => { setSelected(selected === sIndex ? ((selected + 1) % 4) : sIndex); }} area={sIndex > selected ? (sIndex - selected - 1) : (3 - (selected - sIndex))} selected={selected === sIndex} bg={sItem.img} className="ms0i" key={sIndex + "tbit"}>

            <div className="tgbtover" />
            <h2>{sItem.title}</h2>
            <p>{sItem.textShort}<i className={sItem.icon} /></p>
          </TItem>
        ))}
      </TBox>
    </>
  );
}

const targetItems = [
  {
    title: 'Partite IVA',
    icon: 'fas fa-people-carry',
    textShort: <span>Sicuri di venir supportati da collaboratori partecipi.<br />Liberi  di concentrarsi sulla crescita e la propria attività.<br />Bilanci, consulenza tributaria, fatturazione, f24 ecc.</span>,
    cta: 'Approfondisci...',
    linkSection: '/#contabilita-e-bilancio',
    linkPage: '/contabilita-e-bilancio',
    img: imgpiva,
    color: '#1AC35E'
  },
  {
    title: 'Startup',
    icon: 'fas fa-seedling',
    textShort: <span>Tranquilli di ottimizzare i costi e non disperdere risorse.<br />Fiduciosi di avere una strategia sostenibile.<br />Consulenza del lavoro, business plan, startup, agevolazioni ecc.</span>,
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-societaria',
    linkPage: '/consulenza-societaria',
    img: imgstartup,
    color: '#1AC3A5'
  },
  {
    title: 'Privati',
    icon: 'fas fa-hands-helping',
    textShort: <span>Sereni di non perdersi fra scadenze e incombenze .<br />Forti di essere guidati nel comprendere norme e disposizioni.<br />Dichiarazione dei redditi, IMU e TASI, buste paga, cedolini ecc.</span>,
    cta: 'Approfondisci...',
    linkSection: '/#consulenza-fiscale',
    linkPage: '/consulenza-fiscale',
    img: imgprivati,
    color: /* '#0000ff' */'#FAC332'
  },
  {
    title: 'Società',
    icon: 'fas fa-user-tie',
    textShort: <span>Tranquilli di ottimizzare i costi e non disperdere risorse.<br />Fiduciosi di avere una strategia sostenibile.<br />Consulenza del lavoro, business plan, startup, agevolazioni ecc.</span>,
    cta: 'Approfondisci...',
    linkSection: '/#contenzioso-tributario',
    linkPage: '/contenzioso-tributario',
    img: imgaziende,
    color: '#30a2ff'
  }
];

const TItem2 = styled.div`
  transition: all 0.35s;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: ${props => props.selected === true ? "0" : (props.area * w) / (picN - 1) + "px"};
  top: ${props => props.selected === true ? "0" : (picR * w) + "px"};
  width: ${props => props.selected === true ? w + "px" : w / (picN - 1) + "px"};
  height: ${props => props.selected === true ? (picR * w) + "px" : (picR * w) / (picN - 1) + "px"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: ${props => props.selected === true ? "calc(24px + 0.6vw)" : "calc(0.65 * (24px + 0.6vw))"};
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.selected === true ? "#ffffffff" : "#ffffffa0"};
    z-index: 1;
  }
  p {
    font-size: calc(12px + 0.3vw);
    font-weight: 400;
    color: #ffffffc0;
    width: 75%;
    z-index: 1;
    transition: opacity 0.75s 0.25s, font-size 0.0s;
    opacity: ${props => props.selected === true ? "1.0" : "0.0"};
    font-size: ${props => props.selected === true ? "calc(12px + 0.3vw)" : "0.0"};
    /* position: ${props => props.selected === true ? "static" : "absolute"}; */
    /* display: ${props => props.selected === true ? "block" : "none"}; */
  }
  .tgbtover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.75s;
    background-color: ${props => props.selected === false ? "#1B264B60" : "#1B264B00"};
    background-image: linear-gradient(to bottom, #DADFF100 0%, #DADFF100 25%, #DADFF180 25%, #F0F2FA80 75%, #F0F2FA00 75%, #F0F2FA00 100%);
  }
`;

const TItemTransform = styled.div`
  transition: all 0.35s;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  width: ${w + "px"};
  height: ${(picR * w) + "px"};
  transform-origin: bottom;
  transform: ${props => props.selected === true ?
    "translate(0, 0) scale(1.0)" :
    "translate(" + 100 / (picN - 1) * props.area + "%, 0) scale(" + 1 / (picN - 1) + ") translate(-100%, 100%)"
  };
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: ${props => props.selected === true ? "calc(24px + 0.6vw)" : "calc(0.65 * (24px + 0.6vw))"};
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.selected === true ? "#ffffffff" : "#ffffffa0"};
    z-index: 1;
  }
  p {
    font-size: calc(12px + 0.3vw);
    font-weight: 400;
    color: #ffffffc0;
    width: 75%;
    z-index: 1;
    transition: opacity 0.75s 0.25s, font-size 0.0s;
    opacity: ${props => props.selected === true ? "1.0" : "0.0"};
    font-size: ${props => props.selected === true ? "calc(12px + 0.3vw)" : "0.0"};
    /* position: ${props => props.selected === true ? "static" : "absolute"}; */
    /* display: ${props => props.selected === true ? "block" : "none"}; */
  }
  .tgbtover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.75s;
    background-color: ${props => props.selected === false ? "#1B264B60" : "#1B264B00"};
    background-image: linear-gradient(to bottom, #DADFF100 0%, #DADFF100 25%, #DADFF180 25%, #F0F2FA80 75%, #F0F2FA00 75%, #F0F2FA00 100%);
  }
`;

const TBoxOLD = styled.div`
  position: relative;
  width: ${w + "px"};
  height: ${(picR * w * (picN / (picN - 1))) + "px"};
  cursor: pointer;
  background-color: mediumseagreen;
`;

export default MultiShowOne0;
