import bg from 'img/prefooter/commercialista-milano-studio-tallarico-prefooter-0.jpg';
import styled from 'styled-components';
import { PrefooterStrings } from 'data/Strings';

const Prefooter = ({ quote = PrefooterStrings.quote, by = PrefooterStrings.author, img, children, ...moreProps }) => {
  return (
    <PFBox src={img ? img : bg} {...moreProps}>
      <PFOverlay />
      {
        children ? children
          :
          <>
            <PFQuote>{quote}</PFQuote>
            <PFAuthor>{by}</PFAuthor>
          </>
      }
      {children}
    </PFBox>
  );
}

const PFDivider = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(8px + 0.2vw);
  z-index: 1;
  background-image: linear-gradient(to right, #A2AFDD, #1B264B, #293970);
`;

const PFBox = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  /* margin-top: calc(12px + 1vw); */
  padding: calc(20px + 3vw) 0 calc(12px + 9vw) 0;
  overflow: hidden;
  background-image: url(${props => props.src});
  background-position: 100% 5%;
  background-size: cover;
  filter: saturate(50%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const PFOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, #ffffffff, #ffffffb0);
`;

const PFQuote = styled.em`
  position: relative;
  width: 80%;
  max-width: 900px;
  z-index: 1;
  color: #000000f0;
  font-size: calc(14px + 0.4vw);
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
  padding-right: calc(10px + 1vw);
`;

const PFAuthor = styled.p`
  position: relative;
  width: 86%;
  max-width: 900px;
  /* padding-right: 10px; */
  z-index: 1;
  color: #00000070;
  font-size: calc(14px + 0.4vw);
  font-weight: 600;
  text-align: end;
  margin-top: calc(8px + 0.4vw);
`;

export default Prefooter;
