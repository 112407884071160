import { HashLink } from "comps/basic/HashLink";
import { Colors } from "data/Basic";
import styled from 'styled-components';
import imgLogo512 from 'img/logobase11024.png';
import { FooterStrings } from "data/Strings";
/* import imgLogo512 from 'img/logobase2-1024.png'; */

const Footer = () => {
  return (
    <FooterBox>
      <HashLink smooth to="/#panoramica"><img src={imgLogo512} alt={"© " + (new Date().getFullYear()) + " Studio Tallarico - Milano (MI)"} /></HashLink>
      <div>
        <p className="footer-copy">{"© " + (new Date().getFullYear()) + " Studio Tallarico - Milano (MI)"}</p>
        <p className="footer-email">{FooterStrings.email}</p>
        <p className="footer-tel">{FooterStrings.phone}</p>
      </div>
    </FooterBox>
  );
}

const FooterBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #3E56A9 linear-gradient(to right, #3E56A990, #1B264B90);
  color: #ffffffa0;
  background-color: #000000;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  &>a {
    position: relative;
    margin-right: calc(0.0 * (12px + 0.4vw));
    height: 65%;
    opacity: 0.85;
    transition: all 0.35s;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    &>img {
      transition: all 0.35s;
      position: relative;
      height: 100%;
      width: auto;
      opacity:0.8;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1.0;
        &>img {
          opacity:0.95;
        }
      }
    }
  }
  &>div {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    font-size: calc(12px + 0.2vw);
    .footer-copy {
      color: ${Colors.light + "d0"};
    }
    .footer-email {
      color: ${Colors.light + "a0"};
    }
    .footer-tel {
      color: ${Colors.light + "a0"};
    }
  }
  transition: all 0.3s;
`;

export default Footer;
