import { HashLink } from "comps/basic/HashLink";

export const shortFAQGenerali = [
  {
    title: "E’ necessario avere un commercialista per aprire un'attività?",
    text: <p>L’apertura della <strong>partita Iva</strong> e l’iscrizione in cciaa o ad altri enti può esser fatta anche autonomamente senza l’ausilio del commercialista.<br />
    E’ però assolutamente consigliabile rivolgersi ad un commercialista iscritto all’albo dei Dottori Commercialisti ed Esperti Contabili per evitare di incorrere in errori fatali o errate impostazioni che sono molto frequenti nel “fai da te”.</p>
  },
  {
    title: "Quanto tempo prima riceverò gli F24 da pagare?",
    text: "Naturalmente no. Nel caso in cui lo psicologo suggerisca la necessità della psicoterapia, ne spiegherà al paziente i motivi. Spetta a quest’ultimo decidere. Una decisione meditata e convinta è la premessa per un’alleanza terapeuta–paziente indispensabile per la realizzazione di un lavoro proficuo e perché la psicoterapia sia efficace.",
    image: ""
  },
  {
    title: "Di cosa c’è bisogno per cominciare?",
    text: <p>Lo psicoterapeuta cognitivo aiuta a comprendere che le basi del nostro vissuto sono il <em>che cosa</em> e il <em>come</em>. Usa spesso la metafora dei due scienziati: uno scienziato è il paziente, esperto del <em>che cosa</em>. Infatti, lui solo conosce i propri pensieri, l’intensità delle proprie emozioni, i comportamenti con cui reagisce agli eventi della vita. Lo psicoterapeuta è lo scienziato del <em>come</em>, cioè del modo in cui fatti, pensieri, emozioni, comportamenti formano un puzzle di cui alcune parti non sono facilmente accessibili (modalità profonde di funzionamento) e che, scoperte e riposizionate, permettono di comprendere il senso di quello che succede e di riprendere in mano la propria vita.</p>
  },
  {
    title: "Quando posso sapere le tasse che dovrà pagare?",
    text: "La capacità di apprendere, cambiare ed evolvere è presente a tutte le età. Anche una persona anziana ha il diritto di affrontare, mitigare, superare la sofferenza. Ha diritto alla cura, sia che si tratti di cure mediche, psicologiche, riabilitative, o assistenziali. Se è vero che il passato non può essere cambiato, è altrettanto vero che è possibile cambiare il proprio modo di vederlo e giudicarlo e fare pace con se stessi. Agli anziani che mi dicono: «A che serve? Tanto sono vecchio, devo morire», rispondo: «Non è detto che debba succedere adesso o domani». Tutti noi abbiamo il diritto di vivere più serenamente il nostro presente.",
    image: ""
  },
  {
    title: "Quando posso sapere le tasse che dovrà pagare?",
    text: "La capacità di apprendere, cambiare ed evolvere è presente a tutte le età. Anche una persona anziana ha il diritto di affrontare, mitigare, superare la sofferenza. Ha diritto alla cura, sia che si tratti di cure mediche, psicologiche, riabilitative, o assistenziali. Se è vero che il passato non può essere cambiato, è altrettanto vero che è possibile cambiare il proprio modo di vederlo e giudicarlo e fare pace con se stessi. Agli anziani che mi dicono: «A che serve? Tanto sono vecchio, devo morire», rispondo: «Non è detto che debba succedere adesso o domani». Tutti noi abbiamo il diritto di vivere più serenamente il nostro presente.",
    image: ""
  }
];

export const FAQGenerali = [
  ...shortFAQGenerali,
  {
    title: "Perché andare dallo psicologo? Non basta parlare in famiglia o con un amico?",
    text: "I familiari e gli amici non hanno le competenze dello psicologo, ma anche se fossero psicologi hanno un rapporto che non garantisce la necessaria distanza emotiva e obiettività nel vedere i problemi. Non è deontologicamente corretto occuparsi professionalmente di persone con cui si hanno rapporti nella sfera privata. La persona stessa difficilmente si aprirebbe senza riserve, sapendo che familiari e amici non possono restare emotivamente neutri: il timore del giudizio o l’idea di causare dolore, preoccupazione e sconcerto nelle persone amate sono molto condizionanti. Inoltre non si riconoscerebbe loro l’autorevolezza necessaria per accettare l’aiuto. Se un problema è affrontato e risolto positivamente grazie alla rete familiare e sociale, quasi sicuramente non c’era bisogno di un aiuto professionale."
  },
  {
    title: "È vero che dallo psicologo ci vanno i matti?",
    text: "Purtroppo questo pregiudizio impedisce a persone che potrebbero trarre beneficio dall’aiuto di uno psicologo, di affrontare la vita con meno sofferenza e più gratificazione. Il timore del giudizio, di essere considerati deboli e incapaci di farcela da soli, può essere un serio ostacolo. Ricordiamo che dallo psicologo ci vanno persone che riconoscono di attraversare un momento di difficoltà e responsabilmente chiedono di essere aiutati a superarlo. Domandare un supporto quando se ne sente il bisogno è un gesto di benevolenza e rispetto nei confronti di sé."
  },
  {
    title: "Possiamo considerare lo psicologo un medico?",
    text: "No, lo psicologo non è un medico e non può utilizzare atti tipici della professione medica, come la prescrizione di farmaci. Naturalmente non è proibito avere due lauree, quindi se uno psicologo è laureato anche in medicina e iscritto all’Albo dei Medici, può esercitare come medico. Sta al professionista informare correttamente e spiegare con termini comprensibili le proprie competenze."
  },
  {
    title: "Che differenza c’è fra psicologo, psicanalista, psicoterapeuta, psichiatra e neurologo?",
    text: <p>Spesso è difficile orientarsi tra i nomi che indicano figure professionali diverse ma che si occupano di mente e cervello. Ti invito a leggere il mio <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">articolo</HashLink> <em>Lo psicologo, questo sconosciuto</em>, dove troverai le spiegazioni necessarie per comprendere le diverse competenze.</p>
  },
  {
    title: "Devo raccontare proprio tutto allo psicologo?",
    text: "Di solito il paziente inizia parlando dei problemi che lo disturbano, ma a volte il livello di ansia e di confusione è tale che la persona non sa bene da dove cominciare o addirittura non riesce ad aprire bocca, o al contrario parla di molte cose diverse. È compito dello psicologo aiutarlo con delicatezza a focalizzarsi sui motivi che lo hanno portato a chiedere il colloquio. Quando si instaura un rapporto di fiducia (ed è necessario un po’ di tempo) il paziente parla più liberamente di sé e di ciò che ritiene importante, con l’obiettivo di stare meglio e superare le difficoltà. Non sempre ciò accade. Se allo psicologo sono nascoste informazioni rilevanti, il lavoro con il paziente ne risentirà, anche seriamente."
  },
  {
    title: "Come funziona il primo colloquio?",
    text: "Il primo colloquio è utile innanzitutto per conoscersi e ascoltare le difficoltà che il paziente porta. Sulla base di questo colloquio, lo psicologo può capire se la persona necessita di una consulenza psicologica, di ulteriori colloqui psicodiagnostici approfonditi, che aiutino a comprendere, ad esempio, se c’è bisogno di un trattamento psicoterapico, oppure se è più opportuno l’invio ad altri professionisti. Lo psicologo pone delle domande e ascolta in modo empatico e non giudicante quello che il paziente dice."
  },
  {
    title: "Perché esistono tanti tipi di psicoterapia? Come faccio a scegliere?",
    text: <p>La storia della psicologia e della psicoterapia è complessa, ma è possibile comprenderla iniziando a focalizzare alcuni elementi di base. Puoi leggere il mio <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">articolo</HashLink> Lo psicologo, questo sconosciuto, in cui troverai alcune informazioni. È opportuno che il professionista informi il paziente circa il proprio orientamento teorico e spieghi perché può essere utile nel suo caso. Un professionista serio non esiterà a consigliare un tipo di terapia diversa da quella che pratica, se ritiene che il paziente ne trarrà maggiori benefici (ad es. uno psicanalista può inviare il paziente a un terapeuta cognitivo–comportamentale, o a terapeuti sistemico–relazionali, e viceversa).</p>
  },
  {
    title: "Quanto può durare una psicoterapia? Quante sono le sedute a settimana?",
    text: "Dipende dall’orientamento (psicanalitico, cognitivo, comportamentale ecc.) dello psicoterapeuta, dal tipo di problema che il paziente porta, dai suoi obiettivi e dalla sua volontà e capacità di collaborare e impegnarsi. La franchezza, la correttezza e la trasparenza dello psicoterapeuta sono indispensabili per aiutare il paziente a scegliere se accettare il percorso psicoterapeutico consigliato e, in caso positivo, a comprendere i segnali di miglioramento, le criticità, i progressi e anche quando il lavoro può dirsi concluso."
  },
  {
    title: "I miei familiari possono partecipare alla psicoterapia?",
    text: "Se la terapia ha carattere individuale, i familiari non possono partecipare. In caso di necessità e se il paziente è d’accordo, possono essere coinvolti con modalità e limiti molto chiari e precisi."
  },
  {
    title: "Quanto costa una psicoterapia?",
    text: "Dipende dal professionista, il quale è tenuto a comunicare nel modello di Consenso Informato le tariffe praticate."
  },
  {
    title: "Si può interrompere un percorso di psicoterapia?",
    text: "Sì, anche perché per la sua buona riuscita sono indispensabili la collaborazione e la motivazione. È importante che terapista e paziente concordino una seduta in cui parlarne, in modo da verificare i benefici e le criticità del lavoro fatto."
  },
  {
    title: "Durante la psicoterapia si devono prendere farmaci?",
    text: "Non è detto. Se il disagio emotivo del paziente è tanto elevato da compromettere la vita quotidiana e la psicoterapia stessa, il professionista può consigliare l’invio al medico di famiglia o allo psichiatra, che a loro giudizio possono prescrivere i farmaci appropriati. In questo caso, il contatto e la collaborazione tra le diverse figure professionali, con il consenso del paziente, può essere molto utile."
  },
  {
    title: "Posso diventare dipendente dallo psicoterapeuta?",
    text: "In tutte le relazioni di aiuto si instaura un certo grado di dipendenza. È compito del professionista valutarla adeguatamente e impedire che diventi un ostacolo all’evoluzione e all’autonomia del paziente, cosa che sarebbe in contraddizione con il fine stesso della psicoterapia."
  }
];
