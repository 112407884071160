import Prefooter from "comps/compound/Prefooter";
import styled from 'styled-components';
import SectionStudio from "comps/compound/SectionStudio";
import SectionServizi from "comps/compound/SectionServizi";
import SectionContacts from "comps/compound/SectionContacts";
import SectionFAQ2 from "comps/compound/SectionFAQ2";
import SectionNews from "comps/compound/SectionNews";
import SectionServiziOLD from "comps/compound/SectionServiziOLD";
/* import { TextBox, TextH1, TextH3, TextLink, TextSpan, TextP, Icon } from "comps/Typography";
import Header2 from "comps/Header2";
import Servizi from 'comps/Servizi';
import SectionServizi from "comps/SectionServizi";
import SectionNews from "comps/SectionNews";
import SectionFAQ2 from "comps/SectionFAQ2";
import Studio from 'comps/Studio';
import Esplora from 'comps/Esplora';
import Contatti from 'comps/Contatti';
import sizes from 'data/Sizes';
import colors from 'data/Colors'; */
/* import SectionContacts from "comps/SectionContacts";
import Target from "comps/Target";
import TargetA from "comps/TargetA";
import TargetB from "comps/TargetB";
import TargetC from "comps/TargetC";
import SectionTitle0 from "comps/SectionTitle0";
import SectionClienti from "comps/SectionClienti"; */

const MainBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const Main = () => {
  return (
    <MainBox>
      {/* <Header2 headRef={headRef} fullNav={fullNav} />
      <Studio />
      <SectionClienti />
      <SectionServizi />
       */}
      <SectionStudio />
      <SectionServiziOLD />
      <SectionNews />
      <SectionFAQ2 />
      <SectionContacts />
      <Prefooter />
    </MainBox>
  );
}

export default Main;
