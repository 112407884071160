export const Colors = {
  primary: "#3E56A9",
  accent: "#FCBF1E",
  lighter: "#A2AFDD",
  darker: "#293970",
  light: "#F0F2FA",
  dark: "#0E1325",
  white: "#FFFFFF",
  black: "#000000"
};

export const Sizes = {
  navHeightStr: "(70px + 1vw)",
  navHeightFullStr: "(80px + 2vw)",
  navHeightStrOver: "(84px)",
  navHeightFullStrOver: "(108px)",
  footerHeightStr: "(120px + 6vw)",
  sidebarZIndex: "12",
  topbarZIndex: "10",
  modalOverlayZIndex: "11",
  modalDialogZIndex: "12",
  modalSelectZIndex: "13",
  FABZIndex: "12"
};
