import React, { useState } from 'react';
import styled from 'styled-components';
import bg1 from 'img/header/header4-0.jpg';
import bg2 from 'img/header/header4-1.jpg';
import bg3 from 'img/header/header4-0.jpg';
import bg4 from 'img/header/header4-1.jpg';
import titleimg from 'img/header/title.svg';
import DotsNav from "comps/simple/DotsNav";
import { Link } from "react-router-dom";
import { GreenInvertCircleRippleButton2, GreenInvertCircleRippleButton } from "comps/simple/RippleContainer";
import { useKey } from 'react-use';
import debounce from "lodash.debounce";
import { Colors, Sizes } from "data/Basic";
import { HashLink } from "comps/basic/HashLink";
import { TextH4 } from 'comps/basic/Typography4';

const hItems = [
  {
    title: "Partita IVA", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: bg1, dens: "#A2AFDD40",
    icon: 'fas fa-people-carry'
  },
  {
    title: "Privati", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: bg2, dens: "#3E56A940",
    icon: 'fas fa-hands-helping'
  },
  {
    title: "Startup", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: bg3, dens: "#29397040",
    icon: 'fas fa-seedling'
  },
  {
    title: "Società", text: <p className="pclass">Supporto completo e rassicurante per portatori positivi di <strong>partita IVA</strong> e altre comooodità nono necessarie del mondo</p>,
    img: bg4, dens: "#1B264B40",
    icon: 'fas fa-user-tie'
  }
];

const Hbox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #000; */
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: ${"calc(1.2 * " + Sizes.navHeightFullStr + ")"};
  @media only screen and (min-width: 1441px) {
    padding-top: ${"calc(1.2 * " + Sizes.navHeightFullStrOver + ")"};
  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0.0;
  animation: home-page-reveal 1.0s ease-in 0.0s 1 forwards;
  @keyframes home-page-reveal {
      0% { opacity: 0.5; }
      100% { opacity: 1.0; }
  }
  h2 {
    color: #000000f0;
    font-family: "Bodoni Moda", serif;
    font-size: calc(2.1 * (11px + 1.25vw));
    font-weight: 900;
    letter-spacing: 2%;
    margin: 0 0 calc(0.6 * (20px + 0.8vw)) 0;
    display: none;
    .h2ttfl {
      color: #fffffff0;
    }
    .h2ttll {
      color: #3E56A9f0;
    }
  }
  @media only screen and (max-width: 700px) {
    justify-content: center;
    padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStr + ")"};
  @media only screen and (min-width: 1441px) {
    padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStrOver + ")"};
  }
    h2 {
      display: block;
      z-index: 1;
      margin: calc(1.0 * (14px + 1.0vw)) 0;
        font-weight: 600;
      .h2ttfl {
        color: #000000f0;
      }
      .h2ttll {
        display: block;
        width: 100%;
        color: #3E56A9ff;
        text-align: end;
        font-weight: 800;
      }
    }
  }
`;

const HBox0 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc((1495/1920) * 100%);
  /* height: 100%; */
  /* background-image: linear-gradient(to bottom, #00000000, #00000000, #00000000, #00000090); */
  background-color: /* #ffffffa0; */#ffffff60;
  /* border-radius: 0 0 0 300px; */
`;

const HBox1 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: calc((1495/1920) * 100%);
  /* height: 100%; */
  /* background-image: linear-gradient(to bottom, #00000000, #00000000, #00000000, #00000090); */
  background-color: #00000040;
  /* border-radius: 0 0 0 300px; */
`;

const HTitle = styled.img`
  position: absolute;
  width: 80%;
  top: ${"calc(1.3 * " + Sizes.navHeightFullStr + ")"};
  @media only screen and (min-width: 1441px) {
    top: ${"calc(1.3 * " + Sizes.navHeightFullStrOver + ")"};
  }
  left: 50%;
  transform: translate(-50%, 0%);
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const HItem = styled.div`
  position: relative;
  /* border-radius: 60px; */border-radius: 0;
  /* width: 70%; */width: 100%;
  /* height: 40vh; */
  padding: /* calc(1.8 * (20px + 0.6vw)) */calc(1.2 * (20px + 0.6vw)) 0 /* calc(1.3 * (20px + 0.6vw)) */calc(1.0 * (20px + 0.6vw)) 0;
  /* background-color: #ffffffa0; */
  /* background-color: #ffffffe0; */background-image: linear-gradient(to right, #ffffff00, #ffffffd0, #ffffff00);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center/* flex-start */;
  align-items: center;
  /* box-shadow: 3px 3px 12px #00000030; */box-shadow: none;
  h4 {
    /* transform: translateX(-100px); */transform: translateX(-200px);
    font-size: calc(1.6 * (14px + 1vw));
    opacity: 0.0;
    animation: appearhithh4 0.7s ease 0.2s 1 forwards;
    @keyframes appearhithh4 {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .pclass{
    width: 86%;
    max-width: 600px;
    font-size: calc(1.0 * (14px + 0.4vw));
    margin-top: calc(0.6 * (14px + 0.5vw));
    opacity: 0.0;
    animation: appearhitp4 0.8s ease 0.35s 1 forwards;
    @keyframes appearhitp4 {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .ctas {
    .fabutton {
      transform: scale(0.9);
    }
    /**/transform: translateX(150px);
    align-self: /* flex-end; */center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: calc(1.0 * (14px + 1.0vw)) /* calc(1.6 * (14px + 1.0vw)) */0 0 0;
    div {
    margin-right: calc(0.6 * (14px + 1.0vw));
    }
    opacity: 0.0;
    animation: appeari 0.9s ease 0.45s 1 forwards;
    @keyframes appeari {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .iclass {
    position: absolute;
    top: 50%;left: 17%;
    transform: translate(0%, -50%);
    font-size: calc(4.0 * (14px + 1.0vw));
    color: /* ${Colors.primary + "18"}; */${Colors.primary + "90"};
    mix-blend-mode: darken;
    /* color: #ffffffff; */
  }
  opacity: 0.0;
  transform: translate(-50%, 0%);
  animation: appearhit4 0.4s ease 0.0s 1 forwards;
  @keyframes appearhit4 {
    0% { opacity: 0.0;transform: translate(-70%, 0%); }
    100% { opacity: 1.0;transform: translate(0%, 0%); }
  }
  /* @media only screen and (max-width: 1000px) {
    .iclass {
      font-size: calc(3.8 * (14px + 1.0vw));
      top: 0;
      left: unset;
      right: 0;
      transform: translate(-25%, -45%);
    }
  } */
  @media only screen and (max-width: 1000px) {
    width: 100%;
    border-radius: 0;
    margin: calc(2.0 * (14px + 1.0vw)) 0 calc(2.5 * (14px + 1.0vw)) 0;
    .iclass {
      font-size: calc(3.8 * (14px + 1.0vw));
      color: ${Colors.primary + "90"};
      top: 0;
      left: unset;
      right: 0;
      transform: translate(-25%, -45%);
      color: #ffffffff;
    }
    .ctas {
      align-self: center;
      margin: calc(1.0 * (14px + 1.0vw)) 0 0 0;
      div {
        margin-right: calc(0.6 * (14px + 1.0vw));
      }
      .fabutton {
        transform: scale(0.84);
      }
    }
    h4 {
      transform: translateX(0px);
    }
  }
`;

const Hero4 = ({ headRef, fullNav = true }) => {
  const [item, setItem] = useState(0);
  const boxColors = ["#b4bf1f", "#fbb74c", "#947150", "#cac0b4"];
  const itemCount = hItems.length;
  const swipeOn = debounce(() => { setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  const swipeBack = debounce(() => { setItem(i => (i > 0) ? (i - 1) : (itemCount - 1)); }, 10);
  useKey("ArrowLeft", swipeBack);
  useKey("ArrowRight", swipeOn);
  return (
    <Hbox key={/* newKey +  */"hbox4" + item} bg={hItems[item].img} ref={headRef} onClick={swipeOn}>
      <HBox0 /><HBox1 />
      <HTitle src={titleimg} />
      <h2><span className="h2ttfl">Tutta</span> la nostra esperienza <span className="h2ttll">dedicata a te</span></h2>
      {
        [hItems[0]].map((hitem, index) =>
          <HItem key={/* newKey +  */"hboxitem4" + item} selected={item === index}>
            <i className={"iclass " + hItems[item].icon} />
            {/* <h2>{hItems[item].title}</h2> */}
            <TextH4 uppercase color="#404040" spaced firstletter>{hItems[item].title}</TextH4>
            {hItems[item].text}
            <div className="ctas">
              <HashLink to="#prestazioni"><GreenInvertCircleRippleButton /* click={dbctawaModalShow} */ iconSize={1.0} iconPadding="0 0 2px 2px" iconFAClass="fas fa-info" rippleColor={"#DADFF190"} darkColor="#293970f0" effect="circle-darken" className="fabutton" /></HashLink>
              <HashLink to="#contatti"><GreenInvertCircleRippleButton2 /* click={dbctawaModalShow} */ iconSize={1.0} iconPadding="0 2px 0 0" iconFAClass="fas fa-paper-plane" rippleColor={"#DADFF190"} darkColor="#0070CCf0" effect="circle-darken" className="fabutton" /></HashLink>
            </div>
            {/* <div className="hid-content">
            <div onClick={(e) => e.stopPropagation()}>
              <h2>{hitem.title}</h2>
              <p>{hitem.subtitle}</p>
              <span className="hid-button"><BlueInvertRippleButton text={hitem.cta} icon="fas fa-share" /></span>
            </div>
          </div> */}
          </HItem>
        )
      }
      <HeaderDots onClick={(e) => e.stopPropagation()}>
        <DotsNav marginPx={4} borderPx={0} dotsNumber={hItems.length} sizePx={23} activeDot={((item > -1) && (item < hItems.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#3E56A980" colorActive="#3E56A9ff" />
      </HeaderDots>

      {/* <HItemContent >
        {headerItems.map((hitem, index) =>
          <HItemSubContent selected={item === index} onClick={(e) => e.stopPropagation()}>
            <HashLink smooth to="#soluzioni"><h2>{hitem.title}</h2></HashLink>
            <p>{hitem.subtitle}</p>
            <span className="hid-button"><HashLink smooth to="#soluzioni"><BlueInvertRippleButton style={{ marginBottom: "calc(15px + 0.4vw)", marginTop: "calc(8px + 0.2vw)" }} text={hitem.cta} icon="fas fa-search" /></HashLink></span>
            <HDots onClick={(e) => e.stopPropagation()}>
              <DotsNav marginPx={4} borderPx={0} dotsNumber={headerItems.length} sizePx={23} activeDot={((item > -1) && (item < headerItems.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#0099cc60" colorActive="#0099cc" />
            </HDots>
          </HItemSubContent>
        )}
      </HItemContent> */}
    </Hbox>
  );

}
const HeaderDots = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-30%, -30%);

  padding: calc(0.4 * (14px + 0.5vw)) calc(0.8 * (14px + 0.5vw));
  /* border-radius: 999px;
  box-shadow: 2px 2px 6px #00000090;
  background-color: #ffffffa0; */

  z-index: 12;
  opacity: 1.0;
  transition: all 0.3s;
  @media only screen and (max-width: 800px) {
    transform: translate(-10%, -30%);
    /* transform: translate(-50%, -30%); */
    /* right: 50%; */
  }
`;

/* const headerItems = [
  { id: 0, img: bg1, title: "Psicoterapia cognitiva", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet mollis velit, non rutrum dolor tempor vitae. Vivamus eu est interdum.", text: "", cta: "Scopri di più" },
  { id: 1, img: bg2, title: "Psicologia della salute", subtitle: "Integer semper turpis vel neque blandit, at laoreet mauris ultricies. Suspendisse convallis imperdiet accumsan.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" }
]; */

export default Hero4;
