const printfix = () => {
  const m0 = 0.9;
  const t0 = 1.1;
  /* const d0 = 1.1; */
  let fix00m = 0.1;
  let fix00t = 120.0;
  let fix00tt = 16.0 * 60.0;
  let fixx = [];
  for (let d = 0; d < 20; d++) {
    let fix0m = fix00m;
    let fix0t = fix00t;
    let fix0tt = fix00tt;
    let fix = new Map();
    for (let fixtt = 0.0; fixtt < fix0tt;) {
      let aa = new Date(2021, 1, 12 + d, 8, fixtt)
      fix.set(aa, fix0m);
      fixtt += fix0t;
      fix0m *= m0;
      fix0t *= t0;
    }
    console.log("fix: ", fix);
    fixx.push((Array.from(fix.values())).reduce((a, b) => a + b))
    fix00m *= m0;
    fix00t *= t0;
    /* fix00tt *= t0; */
  }
  console.log("fixx: ", fixx);
}

const printfix2 = () => {
  const m0 = 0.9;
  const t0 = 1.1;
  /* const d0 = 1.1; */
  let fix00m = 0.1;
  let fix00t = 120.0;
  let fix00tt = 16.0 * 60.0;
  let fixx = [];
  for (let d = 0; d < 20; d++) {
    let fix0m = fix00m;
    let fix0t = fix00t;
    let fix0tt = fix00tt;
    let fix = new Map();
    for (let fixtt = 0.0; fixtt < fix0tt;) {
      let aa = new Date(2021, 1, 12 + d, 8, fixtt)
      fix.set(aa, fix0m);
      fixtt += fix0t;
      fix0m *= m0;
      fix0t *= t0;
    }
    console.log("fix: ", fix);
    fixx.push((Array.from(fix.values())).reduce((a, b) => a + b))
    fix00m *= m0;
    fix00t *= t0;
    fix00tt *= t0; // stretch fix day, to be changed
  }
  console.log("fixx: ", fixx);
}

/*
const fixStart = 6.0;
let fix = [];
let fixTemp = 1.0;
fix.push(fixTemp);
while (fixTemp > 0.025) {
  fixTemp *= 0.8;
  fix.push(fixTemp);
}
console.log("fix start: ", fix.reduce((a, b) => a + b, 0));
console.log("fix: ", fix);
 */
export const MenuItems = {
  items: ["home", "about"]
};

export const getItems = () =>
  [
    { title: 'HOME', icon: "fas fa-home", parent: false, link: '/#start' },
    {
      title: 'SERVICES', icon: "fas fa-palette", parent: true, children: [
        { title: 'DESIGN', parent: false, link: '/#recipes-design' },
        { title: 'DEVELOPMENT', parent: false, link: '/#recipes-develop' },
        { title: 'MARKETING', parent: false, link: '/#recipes-divulge' }]
    }
  ];

export const menuItems00 = [
  {
    title: 'LO STUDIO', icon: "fas fa-home", parent: true, children: [
      { title: 'Studio Tallarico', parent: false, link: '/#studio-tallarico' },
      { title: 'Soluzioni', parent: false, link: '/#soluzioni' },
      { title: 'News e articoli', parent: false, link: '/#news-articoli' },
      { title: 'Domande frequenti', parent: false, link: '/#domande-frequenti' },
      { title: 'Contatti', parent: false, link: '/#contatti' }]
  },
  {
    title: 'SERVIZI', icon: "fas fa-drafting-compass", parent: true, children: [
      { title: 'Contabilità e Bilancio', parent: false, link: '/#contabilita-bilancio' },
      { title: 'Consulenza societaria', parent: false, link: '/#consulenza-societaria' },
      { title: 'Consulenza Fiscale', parent: false, link: '/#consulenza-fiscale' },
      { title: 'Contenzioso tributario', parent: false, link: '/#contenzioso-tributario' },
      { title: 'Revisione dei conti', parent: false, link: '/#revisione-conti' }]
  },
  { title: 'CONTATTI', icon: "fas fa-phone", parent: false, link: '/#contatti' }/*,
  { title: 'EXECUTING', icon: "fas fa-code", parent: false, link: '/#baking' },
  { title: 'THE TEAM', icon: "fas fa-handshake", parent: false, link: '/#cooks' },
  { title: 'CONTACTS', icon: "", parent: false, link: '/#contacts' } */
];
