import { LinkLink, TextP } from "comps/basic/Typography4";
import { MultiSnippet3 } from "comps/complex/MultiSnippet";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import SectionTitle0 from "comps/simple/SectionTitle0";
import { TextBlock1 } from "comps/simple/TextBlocks";
import { ContactsStrings } from "data/Strings";
import styled from 'styled-components';

const emailCaption = "SCRIVICI"
const email = "anna.tallarico@gmail.com"
const phoneCaption = "CHIAMACI"
const phone = "+39 0236504499"
const addressCaption = "INCONTRIAMOCI"
const address = "Via B. D’Alviano, 71/A - 20146 Milano (MI)"


const SC1 = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /* margin-bottom: calc(40px + 2vw); */
  padding: 0;
  .sc1div {
    margin: calc(20px + 1vw);
    position: relative;
    min-width: calc(260px + 6vw);
    padding: calc(1.5 * (12px + 0.6vw)) calc(2.5 * (12px + 0.6vw));
    font-size: calc(13px + 0.35vw);
    border-radius: 999px;
    border: 1px solid #293970a0;
    border-width: 0px 2px 6px 0px;
    /* color: #ffffffd0; */
    color: #000000d0;
    cursor: pointer;
    transition: all 0.5s;
    user-select: none;
    .sc1idiv {
      width: calc(48px + 2vw);
      height: calc(48px + 2vw);
      font-size: calc(24px + 1vw);
      border-radius: calc(24px + 1vw);
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #667CC7;
      background-image: linear-gradient(135deg, #667CC7a0, #3E56A9a0);
      box-shadow: 2px 2px 6px #00000060;
      color: #ffffffb0;
      opacity: 1;
      transition: all 0.35s, opacity 1.0s;
      z-index: 0;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        /* background-color: #293970ff; */
        background-color: #3E56A9;
        color: #ffffffff;
        font-weight: 500;
        transform: translate(5px, 3px);
        .sc1idiv {
            color: #ffffffff;
            background-color: #3E56A9;
            background-image: linear-gradient(135deg, #A2AFDDa0, #293970a0);
            box-shadow: 2px 2px 10px #000000a0;
            transform-origin: 60% 40%;
            opacity: 0;
            transform: scale(2.2);
          }
        }
      }
    }
`;

const SC2 = styled.div`
  position: absolute;
  width: 100%;
  /* max-width: 1000px; */
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  z-index: 5;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -110%);
  /* background-color: red; */
  .sc2div {
    margin: calc(30px + 2vw) 0 calc(10px + 0.4vw) 0;
    position: relative;
    min-width: calc(260px + 6vw);
    width: 84%;
    max-width: 750px;
    padding: calc(1.5 * (12px + 0.6vw)) /* calc(8.5 * (12px + 0.6vw)) */;
    font-size: calc(13px + 0.35vw);
    border-radius: 999px;
    border: 1px solid #293970a0;
    border-width: 0px 2px 6px 0px;
    background-image: linear-gradient(to bottom, #ffffffe0, #ffffffc0, #ffffffc0, #ffffff80);
    color: #000000d0;
    transition: all 0.5s;
    .sc2idiv {
      width: calc(48px + 2vw);
      height: calc(48px + 2vw);
      font-size: calc(24px + 1vw);
      border-radius: calc(24px + 1vw);
      position: absolute;
      top: 0;
      left: 10%;
      transform: translate(-50%, -50%);
      @media only screen and (max-width: 650px) {
        left: 50%;
        transform: translate(-50%, -75%);
      }
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #667CC7;
      background-image: linear-gradient(135deg, #667CC7a0, #3E56A9a0);
      box-shadow: 2px 2px 6px #00000060;
      color: #ffffffb0;
      opacity: 0.8;
      }
    }
`;

const SectionContacts = () => {
  return (
    <SCBox0>
      <SectionHashTarget id="contatti" />
      <SectionTitle0 img={0} flip title="Contatti" subtitle="chiarimenti e proposte" />
      <SC1>
        {/* <TextP verticalSpacing="calc(20px + 1vw)">
          Contact us with no hesitation for a <strong>free consultation</strong>, any piece of information, or just a friendly chat. Contact us with no hesitation for a free consultation, any piece of information, or just a friendly chat. Contact us with no hesitation for a free consultation, any piece of information, or just a friendly chat
        </TextP> */}
        {ContactsStrings.text0}
        {/* <TextBlock1><p>Contact us with no hesitation for a <strong>free consultation</strong>, any piece of information, or just a friendly chat</p><div className="jump" /></TextBlock1> */}
        <MultiSnippet3 /* displace={true} */ style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="700px" theme="light" color="#3E56A9" titles={[ContactsStrings.ctaemail, ContactsStrings.ctaphone, ContactsStrings.ctaaddress]} icons={[
          "fas fa-envelope", "fas fa-phone", "fas fa-map-marker-alt",
        ]} texts={[
          ContactsStrings.email, ContactsStrings.phone, ContactsStrings.address
        ]} />
      </SC1>

      {/* <SCItem> */}
      {/* <Icon1 size={2.4} className="scicon fas fa-envelope" />
          <Text2 className="sccaption" color="#808080" firstletter>{emailCaption}</Text2> */}
      {/* <IconNextCaption2 icon="fas fa-envelope" caption={emailCaption} />
          <Text3 strong className="scinfo">{email}</Text3>
        </SCItem>
        <SCItem>
          <Icon1 size={2.4} className="scicon fas fa-phone" />
          <Text2 className="sccaption" color="#808080" firstletter>{phoneCaption}</Text2>
          <Text3 strong className="scinfo">{phone}</Text3>
        </SCItem>
        <SCItem>
          <Icon1 size={2.4} className="scicon fas fa-map-marker-alt" />
          <Text2 className="sccaption" color="#808080" firstletter>{addressCaption}</Text2>
          <Text3 strong className="scinfo">{address}</Text3>
        </SCItem> */}
      <ContactsMap>
        {/* <SC2>
          <div className="sc2div">
            <div className="sc2idiv"><i className="fas fa-map-marker-alt" /></div>
            <p>Via Bartolomeo D'Alviano, 71A, 20146 Milano MI</p>
          </div>
        </SC2> */}
        <ContactsMapContainer>
          {/* <iframe title="Raggiungici!" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.4038965340624!2d9.230477733593323!3d45.542199981446316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786b897d438eb0b%3A0xcda6bece59ae03a0!2sVia%20Fratelli%20Picardi%2C%2067%2C%2020099%20Sesto%20San%20Giovanni%20MI!5e0!3m2!1sen!2sit!4v1587856913167!5m2!1sen!2sit" width="100%" height="100%" frameBorder="0" style={{ border: "0" }} allowFullScreen={true}></iframe> */}
          <iframe title="Raggiungic!" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.501196578807!2d9.135480515330931!3d45.45970677910092!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c3d4e341cda9%3A0x3401d0aa7a6d5a8e!2sStudio%20Associato%20Edoardo%20Rizzo%20%26%20Anna%20Tallarico!5e0!3m2!1sen!2sit!4v1614088033720!5m2!1sen!2sit" width="100%" height="100%" frameBorder="0" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
        </ContactsMapContainer>
      </ContactsMap>
    </SCBox0 >
  );
}

const ContactsMap = styled.div`
  position: relative;
  width: 90%;
  width: 100%;
  height: calc(250px + 7vw);
  display: grid;
  place-items: center;
  margin: /* calc(150px + 6vw) 0 */ calc(1.0 * (14px + 0.5vw)) 0;
  @media only screen and (max-width: 768px) {width: 100%;}
`;

const ContactsMapContainer = styled.div`
  position: relative;
  /* border-radius: 10px 250px 10px 250px; */
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* z-index: 1; */
  /* box-shadow: 4px 4px 8px #60606090; */
  @media only screen and (max-width: 768px) {border-radius: 0;}
  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #ffffff00 65%, #ffffffff);
    mix-blend-mode: normal;
  }
`;

const SCBox0 = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const SCItem = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-bottom: calc(18px + 1.4vw);
  }

  .scicon {
    opacity: 0.6;
  }

  .sccaption {
    /* margin: calc(14px + 1vw) 0; */
  }

  .sinfo {
  }
`;

export default SectionContacts;
