import { HashLink } from "comps/basic/HashLink";
import styled, { css } from 'styled-components';

/* const colorPrimary = "#0090c0"; */
const colorPrimary = "#3E56A9";
const colorLinkPrimary = "#30a2ff";
const colorLinkHoverPrimary = "#0081EA";

const SHBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.right === false ? "flex-end" : "flex-start"};
  margin: calc(2.0 * (14px + 1.0vw)) calc(1.0 * (14px + 1.0vw));
  transform: ${props => props.right === false ? "translate(-10%, 0)" : "translate(10%, 0)"};
  h4 {
    font-size: calc(1.8 * (14px + 0.4vw));
    font-weight: 600;
    color: #404040;
    line-height: 1.25;
    letter-spacing: calc(0.04 * (14px + 0.5vw));
    font-family: 'Bodoni Moda', serif;
    /* &::first-letter { color: #30A2FF; } */
    /* text-align: center;
    text-transform: uppercase; */
  }
  h5 {
    position: relative;
    font-size: calc(1.1 * (14px + 0.4vw));
    font-weight: 600;
    color: #3E56A970;
    line-height: 1.25;
    text-transform: uppercase;
    transform: ${props => props.right === false ? "translate(30%, 0)" : "translate(-30%, 0)"};
    letter-spacing: calc(0.06 * (14px + 0.5vw));
    &::after {
      content: "";
      position: absolute;
      top: calc(45% - 1px);
      ${props => props.right === false ? css`left: calc(-8.2 * (14px + 1.0vw));` : css`right: calc(-8.2 * (14px + 1.0vw));`};
      width: calc(8.0 * (14px + 1.0vw));
      height: 3px;
      background-image: ${props => props.right === false ? "linear-gradient(to right, #3E56A900, #3E56A9a0)" :
    "linear-gradient(to left, #3E56A900, #3E56A9a0)"};
    }
    opacity: 0.8;
  }
`;

export const SubHeadline = ({ title = "Cosa ti offriamo", subtitle = "IMPEGNO", right = false }) => {
  return (
    <SHBox right={right}>
      <h4>{title}</h4>
      <h5>{subtitle}</h5>
    </SHBox>
  );
}

/* common styles */
const textBaseProps = css`
  position: relative;
  line-height: 1.55;
  text-align: ${props => (props.textAlign === "left" || props.textAlign === "start") ? "start" : ((props.textAlign === "right" || props.textAlign === "end") ? "end" : "center")};
  margin: ${props => props.verticalSpacing ? (props.verticalSpacing === true ? "calc(20px + 1vw) 0" : ("" + props.verticalSpacing + " 0")) : "0"};
  ${props => props.uppercase === true && css`text-transform: uppercase;`};
  ${props => props.spaced === true && css`letter-spacing: calc(1px + 0.06vw);`};
  ${props => props.firstletter && (
    props.firstletter.length > 0 ? css`&::first-letter { color: ${props.firstletter}; }`
      : css`&::first-letter { color: ${colorPrimary}; }`
  )};
`;

/* variable styles */
function textBaseProps2(props, defSmallSize, defSize, defLargeSize, defColor, defWeightStrong, defWeight) {
  return css`
    ${textBaseProps};
    font-size: ${props => (props.size && props.size.length > 0) ? props.size : (
      (props.small === true) ? defSmallSize : (
        (props.large === true) ? defLargeSize : defSize
      )
    )};
    color: ${props => (props.color && props.color.length > 0) ? props.color : defColor};
    font-weight: ${props => (props.strong && (props.strong === true)) ? defWeightStrong : defWeight};
  `;
}

export const TextP = styled.p`
  .ptitle {
    letter-spacing: calc(1px + 0.02vw);
    line-height: 1;
    font-size: calc(1.0 * (18px + 0.8vw));
    font-weight: 600;
    margin-right: calc(0.4 * (14px + 0.5vw));
    margin-left: calc(-0.2 * (14px + 1vw));
    color: ${props => (props.titleColor && props.titleColor.length > 0) ? props.titleColor : colorPrimary + "b0"};
  }
  width: ${props => (props.paramWidth && props.paramWidth !== "") ? props.paramWidth : "90%"};
  max-width: ${props => (props.maxWidth && props.maxWidth !== "") ? props.maxWidth : "1100px"};
  ${props => textBaseProps2(props, "calc(12px + 0.3vw)", "calc(14px + 0.4vw)", "calc(14px + 0.5vw)", "#383838", "500", "400")};
  ${props => props.right === true && css`text-align: end;`};
  ${props => props.left === true && css`text-align: start;`};
  line-height: 1.75;
  @media only screen and (min-width: 1441px) {
    ${props => textBaseProps2(props, "calc(16px)", "calc(20px)", "calc(22px)", "#383838", "500", "400")};
  }
`;

export const TextH4 = styled.h4`
  ${props => textBaseProps2(props, "calc(1.0 * (18px + 0.7vw))", "calc(1.25 * (18px + 0.7vw))", "calc(1.5 * (18px + 0.7vw))", "#303030", "600", "500")};
  @media only screen and (min-width: 1441px) {
    ${props => textBaseProps2(props, "calc(1.0 * (28px))", "calc(1.25 * (28px))", "calc(1.5 * (28px))", "#303030", "600", "500")};
  }
  `;

export const TextH3 = styled.h3`
  ${props => textBaseProps2(props, "calc(1.0 * (19px + 0.8vw))", "calc(1.25 * (19px + 0.8vw))", "calc(1.5 * (19px + 0.8vw))", "#282828", "700", "600")};`;

export const TextH2 = styled.h2`
  ${props => textBaseProps2(props, "calc(1.0 * (20px + 0.9vw))", "calc(1.25 * (20px + 0.9vw))", "calc(1.5 * (20px + 0.9vw))", "#202020", "800", "700")};`;

export const TextH1 = styled.h1`
  ${props => textBaseProps2(props, "calc(22px + 1vw)", "calc(22px + 1vw)", "calc(22px + 1vw)", "#181818", "900", "800")};`;

export const LinkA = styled.a`
  font-size: ${props => (props.size && props.size.length > 0) ? props.size : "calc(14px + 0.6vw)"};
  color: ${colorPrimary};
  font-weight: 500;
  ${textBaseProps};
      position: relative;
      /* &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: ${colorPrimary};
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      } */
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          color: #30a2ff;
          /* &::after {
            left: 0%;
            width: 100%;
          } */
        }
      }
`;
export const LinkALink = styled.a`
  font-size: ${props => (props.size && props.size.length > 0) ? props.size : "inherit"};
  color: ${props => (props.fontColor && props.fontColor.length > 0) ? props.fontColor : colorLinkPrimary};
  font-weight: 500;
  ${textBaseProps};
  position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: ${props => (props.fontHoverColor && props.fontHoverColor.length > 0) ? props.fontHoverColor : colorLinkHoverPrimary};
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      }
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          color: ${props => (props.fontHoverColor && props.fontHoverColor.length > 0) ? props.fontHoverColor : colorLinkHoverPrimary};
          &::after {
            left: 0%;
            width: 100%;
          }
        }
      }
`;

export const LinkLink = styled(HashLink)`
  font-size: ${props => (props.size && props.size.length > 0) ? props.size : "inherit"};
  color: ${props => (props.fontColor && props.fontColor.length > 0) ? props.fontColor : colorLinkPrimary};
  font-weight: 500;
  ${textBaseProps};
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: ${props => (props.fontHoverColor && props.fontHoverColor.length > 0) ? props.fontHoverColor : colorLinkHoverPrimary};
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      }
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          color: ${props => (props.fontHoverColor && props.fontHoverColor.length > 0) ? props.fontHoverColor : colorLinkHoverPrimary};
          &::after {
            left: 0%;
            width: 100%;
          }
        }
      }
`;

export const Icon = styled.i`
  position: relative;
  font-size: ${props => "calc(" + ((props.size && props.size > 0) ? props.size : "1.0") + " * (14px + 0.6vw))"};
  color: ${props => (props.color && props.color.length > 0) ? props.color : colorPrimary};
`;
