import React, { useState } from 'react';
import styled from 'styled-components';
import bg1 from 'img/header/anna-tallarico-studio-commercialista-milano-00.jpg';
import bg2 from 'img/header/anna-tallarico-studio-commercialista-milano-01.jpg';
import bg3 from 'img/header/anna-tallarico-studio-commercialista-milano-02.jpg';
/* import bg4 from 'img/bg/header4.jpg'; */
import DotsNav from "comps/simple/DotsNav";
import { Link } from "react-router-dom";
import { BlueInvertRippleButton } from "comps/simple/RippleContainer";
import { useKey } from 'react-use';
import debounce from "lodash.debounce";
import { Sizes } from "data/Basic";
import { HashLink } from "comps/basic/HashLink";

const headerItems = [
  { id: 0, img: bg1, title: "Psicoterapia cognitiva", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet mollis velit, non rutrum dolor tempor vitae. Vivamus eu est interdum.", text: "", cta: "Scopri di più" },
  { id: 1, img: bg2, title: "Psicologia della salute", subtitle: "Integer semper turpis vel neque blandit, at laoreet mauris ultricies. Suspendisse convallis imperdiet accumsan.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" }
];

const Hero3 = ({ headRef, fullNav = true }) => {
  const [item, setItem] = useState(0);
  const boxColors = ["#b4bf1f", "#fbb74c", "#947150", "#cac0b4"];
  const itemCount = 3;
  const swipeOn = debounce(() => { setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  const swipeBack = debounce(() => { setItem(i => (i > 0) ? (i - 1) : (itemCount - 1)); }, 10);
  useKey("ArrowLeft", swipeBack);
  useKey("ArrowRight", swipeOn);
  return (
    <Hbox ref={headRef} onClick={swipeOn} bgColor={boxColors[item]}>
      {
        headerItems.map((hitem, index) =>
          <HItem selected={item === index} bg={hitem.img}>
            {/* <div className="hid-content">
            <div onClick={(e) => e.stopPropagation()}>
              <h2>{hitem.title}</h2>
              <p>{hitem.subtitle}</p>
              <span className="hid-button"><BlueInvertRippleButton text={hitem.cta} icon="fas fa-share" /></span>
            </div>
          </div> */}
          </HItem>
        )
      }

      <HItemContent /*  selected={item === index} */>
        {headerItems.map((hitem, index) =>
          <HItemSubContent selected={item === index} onClick={(e) => e.stopPropagation()}>
            <HashLink smooth to="#soluzioni"><h2>{hitem.title}</h2></HashLink>
            <p>{hitem.subtitle}</p>
            <span className="hid-button"><HashLink smooth to="#soluzioni"><BlueInvertRippleButton style={{ marginBottom: "calc(15px + 0.4vw)", marginTop: "calc(8px + 0.2vw)" }} text={hitem.cta} icon="fas fa-search" /></HashLink></span>
            <HDots onClick={(e) => e.stopPropagation()}>
              <DotsNav marginPx={4} borderPx={0} dotsNumber={headerItems.length} sizePx={23} activeDot={((item > -1) && (item < headerItems.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#0099cc60" colorActive="#0099cc" />
            </HDots>
          </HItemSubContent>
        )}
      </HItemContent>
    </Hbox>
  );
}

const Hbox = styled.div`
  position: relative;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  padding: calc(10px + 0.4vw);
  /* @media only screen and (min-height: 1081px) {
    height: 1080px;
    padding-bottom: 100px;
  } */
  background-color: ${props => props.bgColor};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0.0;
  animation: home-page-reveal 1.0s ease-in 0.0s 1 forwards;
  @keyframes home-page-reveal {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
  }
`;

const HItemSubContent = styled.div`
  /* position: relative; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &>h2, &>p, hid-button { position: relative; }
  z-index: 3;
  cursor: initial;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  user-select: none;

  background-color: #ffffffc0;
  border-radius: 35px;
  padding: 30px 30px 40px 30px;
  width: 90%;
  max-width: 900px;
  box-shadow: 3px 3px 10px #000000b0;

  transition: z-index 0.0s, /* visibility 0.0s,  */opacity 0.4s, transform 0.25s;
  opacity: ${props => (props.selected === true) ? "1.0" : "0.0"};
  /* transform: ${props => (props.selected === true) ? "translate(-50%, -50%)" : "translate(-45%, -50%)"}; */
  z-index: ${props => (props.selected === true) ? "2" : "1"};

  &>h2 {
  user-select: none;
    font-size: calc(18px + 1vw);
    font-weight: 500;
    text-transform: uppercase;
    color: #404040;
    margin-bottom: calc(8px + 0.6vw);
    &::first-letter { color: #0099cc; }
    @media only screen and (min-width: 800px) {
      align-self: flex-start;
      margin-left: calc(30px + 3vw);
    }
    @media only screen and (min-width: 1441px) {
      font-size: 36px;
    }
  }
  &>p {
  user-select: none;
    font-size: calc(14px + 0.4vw);
    font-weight: 400;
    color: #202020;
    margin-bottom: calc(12px + 0.6vw);
  }
  .hid-button {
    @media only screen and (min-width: 800px) {
      align-self: flex-end;
      margin-right: calc(30px + 3vw);
    }
  }
  @media only screen and (max-width: 400px) {
    &>h2 {font-size: 17px;}
    &>p {font-size: 13px;}
    min-height: 290px;
    width: 100%;
    border-radius: 0;
    padding: 20px 20px 25px 20px;
    box-shadow: 0px 3px 10px #000000b0;
    background-color: unset;
    background-image: linear-gradient(to right, #ffffff90, #ffffffd0 30%, #ffffffd0 70%, #ffffff90);
  }
  @media only screen and (min-width: 1441px) {
    &>h2 {font-size: 36px;}
    &>p {font-size: 22px;}
  }
  /* @media only screen and (max-width: 500px) {
    .someclassname {
      opacity: 0.0;
    }
  } */
`;

const HItemContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: calc(${Sizes.navHeightFullStr});
  @media only screen and (min-width: 1441px) {
    top: calc(${Sizes.navHeightFullStrOver});
  }
  /* bottom: calc(3 * (10px + 0.4vw) + 26px); */
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* transition: z-index 0.0s, opacity 0.5s, transform 0.25s;
  opacity: ${props => (props.selected === true) ? "1.0" : "0.0"};
  transform: ${props => (props.selected === true) ? "translate(0%, 0)" : "translate(5%, 0)"};
  z-index: ${props => (props.selected === true) ? "2" : "1"}; */
  cursor: pointer;
  /* &>div {
  } */
`;

const HItem = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* display: grid;
  place-items: center; */
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: z-index 0.0s, /* visibility 0.0s,  */opacity 0.5s, transform 0.5s;
  /* visibility: ${props => (props.selected === true) ? "visible" : "hidden"}; */
  opacity: ${props => (props.selected === true) ? "1.0" : "0.0"};
  transform: ${props => (props.selected === true) ? "translate(0%, 0)" : "translate(-30%, 0)"};
  z-index: ${props => (props.selected === true) ? "1" : "0"};
  /* display: grid;
  place-items: center; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  /* background-color: #3E56A990; */
  mix-blend-mode: color;
  }
`;

const HDots = styled.div`
  /* position: relative; */
  position: absolute;
  left: 50%;
  bottom:0;
  transform: translate(-50%, 50%);
  padding: 5px 10px;
  background-color: #ffffffc0;
  box-shadow: 3px 3px 6px #000000b0;
  border-radius: 22px;
  z-index: 2;
  opacity: 1.0;
  @media only screen and (min-width: 900px) {
    left: 30%;
  }
`;

const HOver = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #ffffffb0;
  z-index: 2;
`;

export default Hero3;
