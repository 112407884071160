import styled, { css } from 'styled-components';

export const SubHeadline0 = ({ spaced = false, screen = false, title = "Cosa ti offriamo", subtitle = "IMPEGNO", right = false }) => {
  return (
    <SHBox spaced={spaced} screen={screen} className="subheadbox" right={right}>
      <h4>{title}</h4>
      <h5>{subtitle}</h5>
    </SHBox>
  );
}

const SHBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.right === false ? "flex-end" : "flex-start"};
  margin: ${props => props.spaced ? "calc(2.0 * (14px + 1.0vw)) calc(1.0 * (14px + 1.0vw))" : "calc(0.0 * (14px + 1.0vw)) calc(1.0 * (14px + 1.0vw)) calc(1.0 * (14px + 1.0vw)) calc(1.0 * (14px + 1.0vw))"};
  transform: ${props => props.right === false ? "translate(-10%, 0)" : "translate(10%, 0)"};
  mix-blend-mode: ${props => props.screen === true ? "multiply" : "normal"};
  h4 {
    font-size: calc(1.8 * (12px + 1.4vw));
    font-weight: 600;
    color: #404040;
    line-height: 1.25;
    letter-spacing: calc(0.04 * (14px + 0.5vw));
    font-family: 'Bodoni Moda', serif;
    &::first-letter { color: #3E56A9; }
  }
  h5 {
    position: relative;
    letter-spacing: calc(1px + 0.1vw);
    font-size: calc(1.0 * (12px + 0.6vw));
    font-weight: 600;
    text-transform: uppercase;
    color: #3E56A980;
    transform: ${props => props.right === false ? "translate(30%, 0)" : "translate(-30%, 0)"};
    opacity: 1;
    &::after {
      content: "";
      position: absolute;
      top: calc(45% - 1px);
      ${props => props.right === false ? css`left: calc(-8.2 * (14px + 1.0vw));` : css`right: calc(-8.2 * (14px + 1.0vw));`};
      width: calc(8.0 * (14px + 1.0vw));
      height: 3px;
      background-image: ${props => props.right === false ? "linear-gradient(to right, #3E56A900, #3E56A9a0)" :
    "linear-gradient(to left, #3E56A900, #3E56A9a0)"};
    }
  }
  @media only screen and (min-width: 1441px) {
    margin: ${props => props.spaced ? "calc(2.0 * (28px)) calc(1.0 * (28px))" : "calc(0.0 * (28px)) calc(1.0 * (28px)) calc(1.0 * (28px)) calc(1.0 * (28px))"};
    h4 {
    font-size: calc(1.8 * (32px));
    letter-spacing: calc(0.04 * (20px));
    }
    h5 {
      letter-spacing: calc(2px);
      font-size: calc(1.0 * (20px));
      &::after {
        top: calc(45% - 1px);
        ${props => props.right === false ? css`left: calc(-8.2 * (28px));` : css`right: calc(-8.2 * (28px));`};
        width: calc(8.0 * (27px));
      }
    }
  }
`;
