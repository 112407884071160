import styled from 'styled-components';
import { GreenInvertCircleRippleButton } from 'comps/simple//RippleContainer';
import { HashLink } from "comps/basic/HashLink";
import debounce from "lodash.debounce";
import { Sizes } from "data/Basic";
/* import debounce from 'lodash.debounce'; */

const FAB = ({ fabUp = false, waModalShow, fabCta }) => {
  const dbcta = debounce(fabCta, 200);
  return (
    <FABMenuBox id="fab-id" up={fabUp}>
      {/* <HashLink to="/#contatti"> */}
      <GreenInvertCircleRippleButton click={dbcta/* waModalShow */} iconSize={1.25} iconPadding="0 0 2px 2px" iconFAClass="fab fa-whatsapp" rippleColor={"#DADFF190"} darkColor="#293970f0" effect="circle-darken" className="fabutton" />{/* </HashLink> */}
    </FABMenuBox>
  );
}

const FABMenuBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: calc(14px + 0.6vw);
  z-index: ${Sizes.FABZIndex};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s;
  transform: ${props => props.up === true ? "translateY(calc( 0px - " + Sizes.footerHeightStr + "))" : "translateY(0px)"};
  /* transform: ${props => (props.fabUp === true) ? "translateY(-" + "120px" + ")" : "none"}; */
  /* @media only screen and (max-width: 1000px) {
    transform: ${props => (props.fabUp === true) ? "translateY(-" + "300px" + ")" : "none"};
  } */
  @media only screen and (min-width: 1441px) {
    right: calc((100% - 1440px) / 2.0);
  }
  .fabutton {
    /* margin-left: calc(14px + 0.4vw); */
    transform: translateY(-400px);
    opacity: 0.0;
    animation: fabuttonappear 0.3s ease-out 0.0s 1 forwards;
    @keyframes fabuttonappear {
      0% { transform: translateY(-600px); opacity: 0.0;  }
      50% { opacity: 0.25;  }
      100% { transform: translateY(0px); opacity: 1.0;  }
    }
  }
`;

export default FAB;
