import styled from 'styled-components';

export const TitleImg1 = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", ...moreProps }) => {
  return (
    <TIB1 {...moreProps} color={color} src={src} theme={theme} align={align}>
      <div />
      <h3>{title}</h3>
    </TIB1>
  );
}

const TIB1 = styled.div`
  position: relative;
  width: 100%;
  height: calc((280px + 18vw) * (275 / 1024));
  user-select: none;
  background-color: ${props => props.theme === "dark" ? "#272121" : "f5f5f5"};
  padding: calc(30px + 1.5vw) 0;
  &>div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 2px 10px #00000050;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: ${props => "linear-gradient(to " + props.align + ", #ffffff40, #ffffffb0, #fffffff0 40%, #ffffffff 40%, #ffffffff)"}, linear-gradient(to bottom, #1B264B00, #1B264B90);
    }
  }
  h3 {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(22px + 1vw);
    letter-spacing: calc(1.5 * (1px + 0.2vw));
    font-weight: 500;
    color: ${props => props.theme === "dark" ? "#ffffffe0" : (props.theme === "medium" ? "#ffffffe0" : "#00000080")};
    text-align: ${props => props.align === "left" ? "start" : "end"};
    padding: ${props => props.align === "left" ? "0 0 0 calc(30px + 2vw)" : "0 calc(30px + 2vw) 0 0"};
    &::first-letter { font-weight: 700; color: ${props => props.color + "d8"}; }
    @media only screen and (max-width: 600px) {
      font-size: calc(14px + 1.6vw);
    }
    @media only screen and (max-width: 400px) {
      padding: ${props => props.align === "left" ? "0 0 0 20px" : "0 20px 0 0"};
    }
  }
`;

export const TitleImg1_0 = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", ...moreProps }) => {
  return (
    <TIB1_0 {...moreProps} color={color} src={src} theme={theme} align={align}>
      <div><div className="overlay" /></div>
      <h3>{title}</h3>
    </TIB1_0>
  );
}

const TIB1_0 = styled.div`
  position: relative;
  width: 100%;
  /* height: calc(500px * (275 / 1024)) */
  height: calc((280px + 18vw) * (275 / 1024));
  user-select: none;
  &>div {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 2px 10px #00000050;
    /* opacity: 0.75; */
    .overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      /* background-image: ${props => props.theme === "dark" ? ("linear-gradient(to " + props.align + ", #27212130, #272121c0, #272121e0)") : (props.theme === "medium" ? ("linear-gradient(to " + props.align + ", #98989830, #989898c0, #989898e0)") : ("linear-gradient(to " + props.align + ", #f5f5f530, #f5f5f5c0, #f5f5f5e0)"))}; */
      background-image: ${props => "linear-gradient(to " + props.align + ", #ffffff40, #ffffffb0, #fffffff0 40%, #ffffffff 40%, #ffffffff)"}, linear-gradient(to bottom, #1B264B00, #1B264B90);
    /* mix-blend-mode: screen; */
    }
  }
  h3 {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    /*
  letter-spacing: calc(5px + 0.7vw);
  line-height: 1.25;
  font-size: calc(30px + 3vw);
  @media only screen and (max-width: 500px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(28px + 2vw);
  }
  @media only screen and (max-width: 400px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(26px + 1.8vw);
  }
  @media only screen and (max-width: 330px) {
    letter-spacing: calc(4px + 0.4vw);
    font-size: calc(24px + 1vw);
  }
  */
    font-size: calc(22px + 1vw);
    letter-spacing: calc(4px + 0.6vw);
    @media only screen and (max-width: 600px) {
      font-size: calc(14px + 1.6vw);
      letter-spacing: calc(2px + 0.8vw);
    }
    font-weight: 500;
    text-transform: uppercase;
    color: ${props => props.theme === "dark" ? "#ffffffe0" : (props.theme === "medium" ? "#ffffffe0" : "#000000a0")};
    text-align: ${props => props.align === "left" ? "start" : "end"};
    padding: ${props => props.align === "left" ? "0 0 0 calc(30px + 2vw)" : "0 calc(30px + 2vw) 0 0"};
    @media only screen and (max-width: 400px) {
      padding: ${props => props.align === "left" ? "0 0 0 20px" : "0 20px 0 0"};
    }
    &::first-letter { color: ${props => props.color}; }
  }
  background-color: ${props => props.theme === "dark" ? "#272121" : "f5f5f5"};
  padding: calc(30px + 1.5vw) 0;
`;
