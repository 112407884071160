import React from 'react';
import styled from 'styled-components';
/* import imgTypeProfessional from 'img/imgs1/website-types/professional0-1920-515.jpg';
import imgTypePersonal from 'img/imgs1/website-types/personal0-1920-515.jpg';
import imgTypeCommunity from 'img/imgs1/website-types/community0-1920-515.jpg';
import imgTypeCommercial from 'img/imgs1/website-types/commercial0-1920-515.jpg';
import imgTypeInformational from 'img/imgs1/website-types/informational0-1920-515.jpg'; */
import imgpiva from "img/clienti/freelance3.jpg";
import imgprivati from "img/clienti/privati3.jpg";
import imgstartup from "img/clienti/startup3.jpg";
import imgaziende from "img/clienti/aziende3.jpg";
import { Expandable0 } from "comps/complex/Expandables";
import { MultiSnippet3Small } from "comps/complex/MultiSnippet";

const SectionServizi2 = () => {

  return (
    <WTBox>
      <Expandable0 maxHeight={"1000px"} color={"#3E56A9"} align={"left"} src={imgpiva} servid="contabilita-bilancio" title={"Contabilità e Bilancio"}>
        <p style={{ fontSize: "calc(13px + 0.3vw)", margin: "calc(30px + 1vw) calc(40px + 2vw) 0 calc(40px + 2vw)" }}>Business websites are the modern business cards. From small local stores, to big digital companies, to freelance professionals of all sorts: tons of information are conveyed and countless contacts are exchanged every day through business websites</p>
        <MultiSnippet3Small style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="light" color="#3E56A9" titles={[
          "LOCAL STORE", "FREELANCE PRO", "DIGITAL COMPANY",
        ]} icons={[
          "fas fa-store", "fas fa-user-tie", "fas fa-wifi",
        ]} texts={[
          "",
          "",
          ""
        ]} />
      </Expandable0>
      <Expandable0 maxHeight={"1000px"} color={"#3E56A9"} align={"right"} src={imgprivati} servid="consulenza-societaria" title={"Consulenza societaria"}>
        <p style={{ fontSize: "calc(13px + 0.3vw)", margin: "calc(30px + 1vw) calc(40px + 2vw) 0 calc(40px + 2vw)" }}>Ever looked up your name on a search engine? Blogs, photo galleries, online diaries, digital resume, expanded business cards: get your name out there and present your best digital self with a fully customized personal website.</p>
        <MultiSnippet3Small style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="light" color="#3E56A9" titles={[
          "TRAVEL BLOG", "PHOTO JOURNAL", "WRITER'S CORNER",
        ]} icons={[
          "fas fa-trailer", "fas fa-photo-video", "fas fa-pencil-alt",
        ]} texts={[
          "",
          "",
          ""
        ]} />
      </Expandable0>
      <Expandable0 maxHeight={"1000px"} color={"#3E56A9"} align={"left"} src={imgstartup} servid="consulenza-fiscale" title={"Consulenza Fiscale"}>
        <p style={{ fontSize: "calc(13px + 0.3vw)", margin: "calc(30px + 1vw) calc(40px + 2vw) 0 calc(40px + 2vw)" }}>Discussion boards and interactive social platforms are usually complex websites, sporting extensive user management systems and handling loads of user-generated content. Come up with a fresh idea for online communities and let us deal with the technical subtleties.</p>
        <MultiSnippet3Small style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="light" color="#3E56A9" titles={[
          "REVIEWS REPOSITORY", "JOB MARKETPLACE", "SOCIAL MEDIA",
        ]} icons={[
          "fas fa-edit", "fas fa-briefcase", "fas fa-users",
        ]} texts={[
          "",
          "",
          ""
        ]} />
      </Expandable0>
      <Expandable0 maxHeight={"1000px"} color={"#3E56A9"} align={"right"} src={imgaziende} servid="contenzioso-tributario" title={"Contenzioso tributario"}>
        <p style={{ fontSize: "calc(13px + 0.3vw)", margin: "calc(30px + 1vw) calc(40px + 2vw) 0 calc(40px + 2vw)" }}>Commercial websites come in many shapes and sizes, they all include some degree of transactional operations: from reservations for a small brick and mortart business, to single payment/subscription components, to full blown eCommerce solutions for digital products or physical goods. eCommerce platforms, tailored to the client's taste, are the prevalent solution, but payment capabilities can be added to regular websites whenever complex cart systems are unnecessary.</p>
        <MultiSnippet3Small style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="light" color="#3E56A9" titles={[
          "PRODUCT LANDING PAGE", "DIGITAL MARKETPLACE", "SUBSCRIPTIONS AND PAYMENTS",
        ]} icons={[
          "fas fa-shopping-basket", "fas fa-shopping-cart", "fas fa-money-bill-wave",
        ]} texts={[
          "",
          "",
          ""
        ]} />
      </Expandable0>
      <Expandable0 maxHeight={"1000px"} color={"#3E56A9"} align={"left"} src={imgpiva} servid="revisione-conti" title={"Revisione dei conti"}>
        <p style={{ fontSize: "calc(13px + 0.3vw)", margin: "calc(30px + 1vw) calc(40px + 2vw) 0 calc(40px + 2vw)" }}>When the goal is to convey knowledge and provide countless hours of engaging content, you're looking at informational websites. Massive amounts of content are updated daily (e.g. news websites) on such websites, or slowly piled up to build an evolving body of quality information (e.g. wikis)</p>
        <MultiSnippet3Small style={{ maxWidth: "1000px" }} /* style={{ margin: "0" }} */ columnMarginBottom="calc(40px + 1vw)" columnThreshold="500px" theme="light" color="#3E56A9" titles={[
          "EDUCATIONAL", "WIKI-LIKE", "NEWS WEBSITE",
        ]} icons={[
          "fas fa-chalkboard-teacher", "fas fa-book-open", "fas fa-newspaper",
        ]} texts={[
          "",
          "",
          ""
        ]} />
      </Expandable0>
    </WTBox>
  );
}

const WTBox = styled.div`
  width: 100%;
  margin: calc(30px + 2vw) 0 0 0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 2px 2px 6px #00000090;
`;

export default SectionServizi2;
