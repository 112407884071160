import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from "react-use";
import { TitleImg1 } from "comps/simple/TitleImgs";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";

export const Expandable0NoClick = ({ src, title = "SOME TITLE", color = "#77CCBB", theme = "dark", align = "left", children = <p>Some content</p>, maxHeight = "200px", ...moreProps }) => {
  const [expanded, setExpanded] = useState(false);
  /* const toggle = () => { setExpanded(e => !e); } */
  return (
    <E0Box expanded={expanded} maxHeight={maxHeight} theme={theme}>
      <TitleImg1 src={src} title={title} color={color} theme={theme} align={align} />
      <div className="e0-content" />
    </E0Box>
  );
}

export const Expandable0 = ({ servid = "contabilita-bilancio", src, title = "SOME TITLE", color = "#30a2ff", theme = "light", align = "left", children = <p>Some content</p>, maxHeight = "300px", ...moreProps }) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => { setExpanded(e => !e); }

  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash && hash !== "" && hash === "#" + servid) { setExpanded(true); }
  }, [hash]);// , hash ]);

  return (
    <E0Box align={align} expanded={expanded} maxHeight={maxHeight} theme={theme}>
      <SectionHashTarget id={servid} />
      <TitleImg1 style={{ cursor: "pointer" }} src={src} title={title} color={color} onClick={toggle} theme={theme} align={align} />
      <i className={expanded ? "iconExpand fas fa-minus" : "iconExpand fas fa-plus"} />
      <div className="e0-content">
        {children}
      </div>
    </E0Box>
  );
}

const E0Box = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => (props.theme === "dark") ? "#272121" : ((props.theme === "medium") ? "#989898" : "#ffffff")};
  color: ${props => (props.theme === "dark") ? "#ffffffc0" : ((props.theme === "medium") ? "#ffffffd0" : "#000000a0")};
  .iconExpand {
    position: absolute;
    top: calc(((280px + 18vw) * (275 / 2048)) - 0.8 * (9px + 0.6vw));
    right: ${props => (props.align === "left") ? "calc(3.5 * (9px + 0.6vw))" : "calc(100% - (3.5 * (9px + 0.6vw)))"};
    font-size: calc(1.6 * (9px + 0.6vw));
    color: #3E56A9f0;
    pointer-events: none;
  }
  .e0-content {
    pointer-events: none;
    /* padding: ${props => (props.expanded === true) ? "calc(30px + 1vw) calc(40px + 2vw)" : "0"}; */
    position: relative;
    overflow: hidden;
    width: 100%;
    transition: all 0.6s ease 0.1s;
    opacity: ${props => (props.expanded === true) ? "1.0" : "0.0"};
    max-height: ${props => (props.expanded === true) ? props.maxHeight : "0px"};
    will-change: max-height, opacity;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
`;
