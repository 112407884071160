import { LinkLink, TextP } from "comps/basic/Typography4";

export const emailString = "anna.tallarico@gmail.com";
export const phoneString = "+39 0236504499";
export const addressString = "Via B. D’Alviano, 71/A - 20146 Milano (MI)";

export const ContactsStrings = {
  text0: <TextP maxWidth="880px" paramWidth="88%" verticalSpacing="calc(2.0 * (14px + 1vw))" >Per i dubbi più frequenti puoi consultare la sezione <LinkLink to="/#domande-frequenti">FAQ</LinkLink>, alternativamente contattaci e saremo pronti per chiarimenti, informazioni, o per fissare un appuntamento.<br /><br /><strong>Se vuoi ottenere un appuntamento</strong> al più presto, ti consigliamo di lasciare un messaggio con il modulo apposito, specificando un recapito e l'area di intervento.</TextP>,
  /* text0: <TextP maxWidth="880px" paramWidth="88%" verticalSpacing="calc(2.0 * (14px + 1vw))" >La varietà di<strong>competenze</strong> dello Studio e il <strong>network</strong> di affiliazioni garantiscono un'ampia copertura della gamma di servizi tradizionali e straordinari: per informazioni su aree di intervento non illustrate, <LinkLink to="/#contatti">contattaci</LinkLink> e ti aiuteremo a ragionare sulla soluzione migliore</TextP>, */
  email: emailString,
  phone: phoneString,
  address: addressString,
  ctaemail: "E-MAIL",
  ctaphone: "TELEFONO",
  ctaaddress: "INDIRIZZO"
};

export const PrefooterStrings = {
  quote: "“ Noi siamo quello che pensiamo di essere. Il nostro abituale modo di pensare determina le nostre tendenze, le nostre capacità e la nostra personalità. ”",
  author: "Paramahansa Yogananda"
};

export const FooterStrings = {
  email: emailString,
  phone: phoneString,
  address: addressString
};
