import { LinkA, LinkLink, TextH4, TextP } from "comps/basic/Typography4";
import { useEffect, useState } from "react";
import styled, { css } from 'styled-components';
import { useLocation } from "react-router-dom";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import RippleButton, { BlueInvertRippleButtonOutline } from "comps/simple/RippleContainer";
import { HashLink } from "comps/basic/HashLink";
import { MultiSnippet3Small } from "comps/complex/MultiSnippet";
import imgAnna2 from "img/studio/anna-tallarico6.jpg";
import imgStudio2 from "img/studio/studio-tallarico720.jpg";

const colorSSMain = "#3E56A9";
const colorSSTitleGray = "#a0a0a0";

const SectionStudioNew = () => {
  return (
    <SSSNBox>
      <Expandable1 isOpen servid={"anna"} left img={imgAnna2} title={"Anna Tallarico"} text={"Nei suoi 30 anni di pratica, Anna Tallarico ha accumulato esperienza in tutte le aree di intervento, e ha collaborato con studi di varia natura. Oggi dedica le sue energie e competenze ai clienti dello studio, fornendo inestimabile valore aggiunto sul piano professionale"} />
      <Expandable1 isOpen servid={"studio"} img={imgStudio2} title={"Lo Studio"} text={"Lo Studio vanta una squadra affiatata di persone con variegate qualità professionali e competenze complementari. La forza dell'offerta sta nella passione per il profondo rapporto con il cliente, caratteristico degli Studi medio-piccoli."} />
    </SSSNBox>
  );
}

const SSSNBox = styled.div`
  position: relative;
`;

const SSeBox = styled.div`
  z-index: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: /* calc(20px + 1vw) 0 0  */0;
  padding-bottom: calc(1.0 * (14px + 1.0vw));
  .pclass {
    width: 80%;
    max-width: 900px;
    font-size: calc(1.0 * (14px + 0.4vw));
    margin: calc(2.0 * (14px + 1vw)) 0;
  }
`;

const risultatiItems = [
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>Modello 730</h2>,
    text: "di concentrarsi sulla crescita",
    icon: "fas fa-hands"
  },
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>Unico</h2>,
    text: "di non disperdere risorse",
    icon: "fas fa-hands"
  },
  {
    /* title: <p style={{ fontWeight: "400", fontSize: "calc(12px + 0.3vw)", color: "#3E56A9ff" }}>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#3E56A9ff" }}>crescita</span></p>, */
    title: <h2 style={{ fontWeight: "600", fontSize: "calc(12px + 0.3vw)", color: "#505050" }}>IMU e TASI</h2>,
    text: "di essere sempre aggiornati",
    icon: "fas fa-hands"
  }
];

export const Expandable1 = ({ servid = "contabilita-bilancio", img, title = "SECTION TITLE", text = "Some short or pretty short text of some kind illustrating sharply the concept of the section item", ctaLink, ctaText = "Scopri di più", left = false, isOpen = false }) => {
  const [open, setOpen] = useState(isOpen);
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const toggleOpen = () => { setOpen(s => !s); }

  const { hash, pathname } = useLocation();
  useEffect(() => {
    if (hash && hash !== "" && hash === "#" + servid) { setOpen(true); }
  }, [hash]);// , hash ]);

  return (
    <E0BoxCollapsible /* onClick={toggleOpen} */ open={open} left={left} bg={img}>
      <SectionHashTarget id={servid} />
      <div className="extrabox">
        {/* <MultiSnippet3Small theme="dark" color="#3E56A9" titles={[risultatiItems[0].title, risultatiItems[1].title, risultatiItems[2].title]} icons={[
          "fas fa-store", "fas fa-user-tie", "fas fa-wifi",
        ]} texts={[risultatiItems[0].text, risultatiItems[1].text, risultatiItems[2].text]} /> */}
      </div>
      {left === true ?
        <>
          <E0OverlayMiddleLeft />
          <E0OverlayTextLeft open={open} >
            <TextH4 uppercase spaced color="#303030" firstletter>{title}</TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
          </E0OverlayTextLeft>
        </> :
        <>
          <E0OverlayMiddleRight />
          <E0OverlayTextRight open={open} >
            <TextH4 uppercase spaced color="#303030" firstletter>{title}</TextH4>
            {/* open === true && */ <TextP className="e0text">{text}</TextP>}
          </E0OverlayTextRight>
        </>}
    </E0BoxCollapsible>
  );
}

const HI0 = styled.div`
  /* position: relative;
  top: 50%;
  right: calc(8.0 * (14px + 1.0vw));
  transform: translateY(-50%); */
  p {text-align: center;}
  i {/* color: red; */}
`;

const E0OverlayTextLeft = styled.div`
  position: relative;
  top: 0;
  /* left: 40%; */
  right: 0;
  order: 0;
  width: 60%;
    order: 2;
  /* width: 80%; */
  /* height: 100%; */
  /* background-image: linear-gradient(to left, #ffffffff 0vw, #ffffffff 25vw, #ffffffc0 100%); */
  background-image: linear-gradient(to left, #ffffffff 0vw, #ffffffff 25vw, #ffffff30 100%);
  /* padding: ${props => props.open === true ? "50px 0 50px 50px" : "50px"}; */
  /* padding: calc(1.5 * (14px + 1.0vw)) calc(2.5 * (14px + 1.0vw)) calc(1.5 * (14px + 1.0vw)) 0; */
  padding: calc(1.5 * (14px + 1.0vw)) calc(3.5 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw)) 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  /* @media only screen and (max-width: 768px) {
    left: 25%;
    width: 75%;
  } */
  margin: 0 calc(2.0 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw)) 0;
  .servlinkicon {
    /* color:#3E56A9; */
    margin: 0 8px;
  }
  font-size: calc(1.0 * (14px + 0.4vw));
  p {
    text-align: end;
  }
  /* h4 { font-size: calc(1.6 * (18px + 0.7vw)); } */
  h4 { font-size: calc(1.37 * (14px + 1vw)); }
  @media only screen and (min-width: 1200px) {
    font-size: calc(1.0 * (19px));
    h4 { font-size: calc(1.6 * (25px)); }
  }
  @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: ${props => props.open === true ? "100px" : "25px"};
    transition: background-color 0.3s;
    background-color: ${props => props.open === true ? "#ffffff90" : "#ffffffb0"};
    background-image: linear-gradient(to bottom, #000000c0 0%,  #00000000 3%,  #ffffff00 3%, #ffffff00 25%, #ffffffa0 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
  }
`;

const E0OverlayTextRight = styled(E0OverlayTextLeft)`
  /* left: 0; */
  right: 40%;
  left: 0;
  order: 0;
  align-items: flex-start;
  /* background-image: linear-gradient(to right, #ffffffff, #fffffff0); */
  background-image: linear-gradient(to right, #ffffffff 0vw, #ffffffff 25vw, #ffffffe0 100%);
  /* padding: ${props => props.open === true ? "50px 50px 50px 0" : "50px"}; */
  padding: calc(1.5 * (14px + 1.0vw)) 0 calc(2.0 * (14px + 1.0vw)) calc(3.5 * (14px + 1.0vw));
  p {
    text-align: start;
  }
  /* @media only screen and (max-width: 768px) {
    width: 75%;
  } */
  @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    padding-top: ${props => props.open === true ? "100px" : "25px"};
    transition: all 0.3s;
    background-color: ${props => props.open === true ? "#ffffff90" : "#ffffffb0"};
    background-image: linear-gradient(to bottom, #000000c0 0%,  #00000000 3%,  #ffffff00 3%, #ffffff00 25%, #ffffffa0 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
  }
  /* @media only screen and (max-width: 900px) {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.3s;
    background-color: ${props => props.open === true ? "#ffffff90" : "#ffffff00"};
    background-image: linear-gradient(to bottom, #000000c0 0%,  #00000000 2%,  #ffffff00 2%, #ffffff00 25%, #ffffffa0 25%, #ffffffc0 75%, #fffffff0 75%, #ffffffff 100%);
    justify-content: flex-end;
    padding-bottom: calc(1.25 * (14px + 1.0vw));
  } */
`;

const E0OverlayMiddleLeft = styled.div`
  position: absolute;
  top: 0;
  left: 30%;
  width: 70%;
  height: 100%;
  background-image: linear-gradient(to left, #ffffff00, #ffffff40);
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const E0OverlayMiddleRight = styled(E0OverlayMiddleLeft)`
  left: 0;
  background-image: linear-gradient(to right, #ffffff20, #ffffff70);
`;

const E0BoxCollapsible = styled.div`
  position: relative;
  width: 100%;
  /* cursor: pointer; *//*
  padding: ${props => props.open === true ? (props.left === true ? "100px 0 100px 0" : "100px 0 100px 0") : "100px"}; */
  min-height: ${props => props.open === true ? "200px" : "0px"};
  transition: all 0.3s;
  background-image: url(${props => props.bg});
  background-size: ${props => props.left === false ? "60% auto" : "auto 70%"};
  /* background-position: ${props => props.left === true ? "0% 20%" : "100% 20%"}; */
  background-position: ${props => props.left === true ? "16% center" : "100% center"};
  background-repeat: no-repeat;
  display: flex;
  flex-flow: row nowrap;
  /* justify-content: ${props => props.left === false ? "flex-end" : "flex-start"}; */
  justify-content: center;
  align-items: center;
  .extrabox {
    order: 1;
    position: relative;
    width: 35%;
    z-index: 1;
    color: black !important;
    overflow: hidden;
    transition: opacity 0.4s;
    background-image: linear-gradient(to right, #ffffff00, #ffffffc0, #ffffffc0, #ffffff00);
    /* display: ${props => props.open === true ? "block" : "none"}; */
    opacity: ${props => props.open === true ? "1.0" : "0.0"};
    max-height: ${props => props.open === true ? "600px" : "0px"};
    div {
      transition: transform 0.75s 0.0s;
      transform:  ${props => props.open === false ? "translate(-200%, 0%)" : "translate(0%, 0%)"};
    }
    .ms3smallitem {
      width: 90%;
      margin: calc(0.5 * (14px + 1.0vw));
    }opacity: 0;
  }
  @media only screen and (max-width: 900px) {
    background-size: cover;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* max-height: ${props => props.open === true ? "30%" : "0px"}; */
    min-height: ${props => props.open === true ? "200px" : "0px"};
    /* ${props => props.open === true && css`padding:0;`}; */
    background-size: ${props => props.left === false ? "100% auto" : "300px auto"};
  /* background-position: ${props => props.left === true ? "0% 20%" : "100% 20%"}; */
    background-position: ${props => props.left === true ? "90% 90%" : "100% center"};
    padding-bottom:  ${props => props.left === true ? "100px" : "inherit"};
    .extrabox {
      order: 3;
      /* background-image: none; */background-image: linear-gradient(to right, #ffffffd0, #ffffffa0);
      background-color: white;
      width: 100%;
      .ms3smallbox {
        justify-content: center;
        padding-top: calc(10px + 0.4vw);
      }
      .ms3smallitem {
        width: 30%;
        margin: 0;
      }
    }
  }
  .e0text {
    max-width: 88%;
    transition: all 0.6s, opacity 0.4s;
    opacity: ${props => props.open === true ? "1.0" : "0.0"};
    max-height: ${props => props.open === true ? "500px" : "0px"};
    margin-top: ${props => props.open === true ? "calc(1.0 * (14px + 1.0vw))" : "0px"};
  }

  .e0link {
    font-size: calc(1.0 * (14px + 0.4vw));
    display: block;
    margin: calc(1.0 * (14px + 1.0vw)) 0;
    /* margin: ${props => props.open === true ? "calc(0.3 * (14px + 0.5vw)) 0 calc(2.0 * (14px + 0.5vw)) 0" : "0px"};
    transform: ${props => props.open === true ? "translate(75%, 0)" : "translate(0%, 0)"}; */
  }
  @media only screen and (min-width: 1200px) {
    .e0link {font-size: calc(1.0 * (20px));}
  }
`;

export default SectionStudioNew;
