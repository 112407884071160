import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import bg1 from 'img/header/header4-0.jpg';
import bg2 from 'img/header/header4-1.jpg';
import bg3 from 'img/header/header4-0.jpg';
import bg4 from 'img/header/header4-1.jpg';
import titleimg from 'img/header/title.svg';
import DotsNav from "comps/simple/DotsNav";
import { Link } from "react-router-dom";
import { GreenInvertCircleRippleButton2, GreenInvertCircleRippleButton } from "comps/simple/RippleContainer";
import { useKey } from 'react-use';
import debounce from "lodash.debounce";
import { Colors, Sizes } from "data/Basic";
import { HashLink } from "comps/basic/HashLink";
import { TextH4 } from 'comps/basic/Typography4';

const HF0Nav = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 40%;
  width: calc(100% * (1.0 - (1495/1920)));
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; */
  /* background-color: red; */
`;

const HF00 = styled.div`
  position: absolute;
  left: calc((425/1920) * 100%);
  width: calc(((1920 - (1.5 * 425))/1920) * 100%);
  /* outline: 1px solid #00000010; */
  /* height: 200px; */
  /* bottom: calc(2.0 * (14px + 1.0vw)); */
  padding: calc(4.0 * (14px + 1.0vw)) 0 calc(3.2 * (14px + 1.0vw)) 0;
  /* background-image: linear-gradient(to right, #ffffffa0, #ffffff00); */
  background-image: /* linear-gradient(to right, #ffffffc0, #ffffffa0, #ffffff00); */linear-gradient(to right, #ffffffe8, #ffffffd8, #ffffffd0, #ffffff00);
  top: ${"calc(2.9 * " + Sizes.navHeightFullStr + ")"};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding-left: calc(4.4 * (14px + 1.0vw));
  p {
    font-size: calc(1.1 * (12px + 0.4vw));
    line-height: 1.7;
    text-align: start;
    color: #000000f0;
  }
  @media only screen and (max-width: 600px) {
    p {font-size: calc(0.9 * (13px + 0.4vw));}
  }
  .hf0-p3 {
    color: #3E56A9;
    font-weight: 600;
  }
  .ctas {
    position: absolute;
    right: 4%;
    bottom: 0;
    transform: translate(0%, 30%);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    &>a {
      margin-right: calc(1 * (14px + 1.0vw));
    }
      /* margin-right: calc(0.6 * (0px + 1.0vw)); */
  }

  .hf0-p1 {
    opacity: 0.0;
    animation: hf0-p1appear 0.2s ease-out 0.0s 1 forwards;
    @keyframes hf0-p1appear {
      0% { opacity: 0.0;transform: translate(-100%, 0%); }
      100% { opacity: 1.0;transform: translate(0%, 0%); }
    }
  }

  .hf0-p2 {
    opacity: 0.0;
    animation: hf0-p1appear 0.3s ease-out 0.2s 1 forwards;
    @keyframes hf0-p1appear {
      0% { opacity: 0.0;transform: translate(-100%, 0%); }
      100% { opacity: 1.0;transform: translate(0%, 0%); }
    }
  }

  .hf0-p3 {
    opacity: 0.0;
    animation: hf0-p1appear 0.4s ease-out 0.4s 1 forwards;
    @keyframes hf0-p1appear {
      0% { opacity: 0.0;transform: translate(-100%, 0%); }
      100% { opacity: 1.0;transform: translate(0%, 0%); }
    }
  }

  @media only screen and (max-width: 800px) {
    left: 0;
    width: 100%;
    padding: calc(4.8 * (14px + 1.0vw)) calc(2.0 * (14px + 1.0vw));
    /* padding-left: calc(2.0 * (14px + 1.0vw)); */
    /* text-align: center; */
  }
  @media only screen and (min-width: 1441px) {
    padding: calc(4.0 * (28px)) 0 calc(3.2 * (28px)) 0;
    top: ${"calc(2.9 * " + Sizes.navHeightFullStrOver + ")"};
    padding-left: calc(5.0 * (28px));
    p {
      font-size: calc(1.1 * (13px + 6px));
    }
    .ctas {
      &>a {
        margin-right: calc(1 * (28px));
      }
    }
  }
`;

const HF0Nav0 = styled.div`
  position: absolute;
  ${props => props.position === 0 &&
    css`
      left: calc((425/1920) * 100%);
      top: ${"calc(3.0 * " + Sizes.navHeightFullStr + ")"};
      @media only screen and (min-width: 1441px) {
        top: ${"calc(3.0 * " + Sizes.navHeightFullStrOver + ")"};
      }
    `
  };
  ${props => props.position !== 0 &&
    css`
      left: calc((425/1920) * 50%);
      top: 65%;
    `
  };
  /* top: ${props => props.position === 0 ? "0%" : (props.position === 1 ? "50%" : "50%")}; */
  transform: ${props => props.position === 0 ?
    "translate(0%, 0%)" : (props.position === 1 ?
      "translate(calc(-3.0 * (10px + 1.2vw)), -50px)" :
      "translate(calc(-3.0 * (10px + 1.2vw)), 50px)")};
  transition: all 0.4s;

  @media only screen and (max-width: 800px) {
    ${props => props.position === 0 &&
    css`
        left: 0%;
      top: ${"calc(3 * " + Sizes.navHeightFullStr + ")"};
      `
  };
    ${props => props.position !== 0 &&
    css`
        left: 50%;
        top: unset;
        bottom: calc(1.0 * (14px + 1.0vw));
      `
  };
    /* transform: ${props => props.position === 0 ?
    "translate(0%, 0%)" : (props.position === 1 ?
      "translate(-175%, 0)" :
      "translate(75%, 0)")}; */
    transform: ${props => props.position === 0 ?
    "translate(0%, 0%)" : (props.position === 1 ?
      "translate(calc(-20vw - 50%), 0)" :
      "translate(calc(20vw - 50%), 0)")
  };
  }
  @media only screen and (min-width: 1441px) {
    transform: ${props => props.position === 0 ?
    "translate(0%, 0%)" : (props.position === 1 ?
      "translate(calc(-2.5 * (27px)), -50px)" :
      "translate(calc(-2.5 * (27px)), 50px)")};
  }
  h4 {
    position:relative;
    white-space: nowrap;
    font-size: ${props => props.position === 0 ? "calc(1.6 * (14px + 1.0vw))" : "calc(1.1 * (10px + 1.2vw))"};
    color: ${props => props.position === 0 ? "#000000e8" : "#ffffffb8"};
    padding: ${props => props.position === 0 ? "calc(0.6 * (14px + 1.0vw)) calc(1.4 * (14px + 1.0vw))" : "0 0"};
    margin: 0;
    text-align: start;
    line-height: 1;
    &::first-letter {
      ${props => props.position === 0 ? props.color : "#ffffffb8"};
    }
    transition: all 0.4s;
    @media only screen and (min-width: 1441px) {
      font-size: ${props => props.position === 0 ? "calc(1.6 * (28px))" : "calc(1.1 * (27px))"};
      padding: ${props => props.position === 0 ? "calc(0.6 * (28px)) calc(1.4 * (28px))" : "0 0"};
    }
    &::after {
      content: '';
      position: absolute;
      /* top: 50%; */
      left: ${props => props.position !== 0 ? "calc(-0.8 * (14px + 1.0vw))" : "calc(-0.4 * (14px + 1.0vw))"};
      top: ${props => props.position !== 0 ? "0" : "-30%"};
      width: ${props => props.position !== 0 ? "calc(0.5 * (14px + 1.0vw))" : "calc(1.0 * (14px + 1.0vw))"};
      height: ${props => props.position !== 0 ? "100%" : "250%"};
      transform: /* translate(-10%, -50%); */translate(-10%, 0%);
      /* height: ${props => props.position !== 0 ? "calc(1.0 * (14px + 1.0vw))" : "calc(2.0 * (14px + 1.0vw))"}; */
      background-color: ${props => props.position === 0 ? props.color + "ff" : props.color + "b8"};
      transition: all 0.4s;
      /* border-radius: 999px; */


      @media only screen and (max-width: 800px) {
        left: ${props => props.position !== 0 ? "calc(-0.8 * (14px + 1.0vw))" : "0"};
        top: ${props => props.position !== 0 ? "0" : "-30%"};
        width: ${props => props.position !== 0 ? "calc(0.5 * (14px + 1.0vw))" : "70%"};
        height: ${props => props.position !== 0 ? "100%" : "calc(0.8 * (14px + 1.0vw))"};
        transform: /* translate(-10%, -50%); */translate(-10%, 0%);
      }
      @media only screen and (min-width: 1441px) {
        left: ${props => props.position !== 0 ? "calc(-0.8 * (28px))" : "calc(-0.4 * (28px))"};
        top: ${props => props.position !== 0 ? "0" : "-20%"};
        width: ${props => props.position !== 0 ? "calc(0.5 * (28px))" : "calc(1.0 * (28px))"};
      }
    }
  }
`

const HBox2 = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #00000090;
  height: calc(3.0 * (14px + 1.0vw));
  display: none;
  @media only screen and (max-width: 800px) {
    display: block;
  }
`;

const HeroFinal0 = ({ headRef, fullNav = true }) => {
  const [item, setItem] = useState(0);
  const boxColors = ["#b4bf1f", "#fbb74c", "#947150", "#cac0b4"];
  const itemCount = hItems.length;
  const swipeOn = debounce(() => { setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  const swipeBack = debounce(() => { setItem(i => (i > 0) ? (i - 1) : (itemCount - 1)); }, 10);
  useKey("ArrowLeft", swipeBack);
  useKey("ArrowRight", swipeOn);
  return (
    <HF0box bg={hItems[0].img} ref={headRef} onClick={swipeOn}>
      <HBox0 /><HBox1 /><HBox2 />
      <HF00>
        {hItems[item].text}
        <div className="ctas">
          <HashLink to="#prestazioni"><GreenInvertCircleRippleButton iconSize={1.0} iconPadding="0 0 2px 2px" iconFAClass="fas fa-info" rippleColor={"#DADFF190"} darkColor="#293970f0" effect="circle-darken" className="fabutton" /></HashLink>
          <HashLink to="#contatti"><GreenInvertCircleRippleButton2 iconSize={1.0} iconPadding="0 2px 0 0" iconFAClass="fas fa-paper-plane" rippleColor={"#DADFF190"} darkColor="#0070CCf0" effect="circle-darken" className="fabutton" /></HashLink>
        </div>
      </HF00>
      <h2 className="hfh20">Tutta</h2><h2 className="hfh21">la nostra esperienza<h2 className="hfh22">dedicata a te</h2></h2>
      {/* <HTitle src={titleimg} />
      <h2><span className="h2ttfl">Tutta</span> la nostra esperienza <span className="h2ttll">dedicata a te</span></h2> */}
      {/* <HF0Nav> */}
      <HF0Nav0 onClick={(e) => { setItem(0); e.stopPropagation(); }} color={hItems[0].dens} position={item === 0 ? 0 : (item === 1 ? 2 : 1)}><TextH4  >{hItems[0].title}</TextH4></HF0Nav0>
      <HF0Nav0 onClick={(e) => { setItem(1); e.stopPropagation(); }} color={hItems[1].dens} position={item === 0 ? 1 : (item === 1 ? 0 : 2)}><TextH4  >{hItems[1].title}</TextH4></HF0Nav0>
      <HF0Nav0 onClick={(e) => { setItem(2); e.stopPropagation(); }} color={hItems[2].dens} position={item === 0 ? 2 : (item === 1 ? 1 : 0)}><TextH4  >{hItems[2].title}</TextH4></HF0Nav0>
      {/* </HF0Nav> */}
      {/* <HItem key={"hboxitemf0" + item} >
        {hItems[item].text}
      </HItem> */}
    </HF0box>
  );

}

const hItems = [
  {
    title: "Partita IVA", text:
      <>
        <p className="hf0-p1" key="hf0-1-p1"><strong>Sicuri</strong> di venir supportati da collaboratori partecipi</p>
        <p className="hf0-p2" key="hf0-1-p2"><strong>Liberi</strong> di concentrarsi sulla crescita e la propria attività.</p>
        <p className="hf0-p3" key="hf0-1-p3">Bilanci, consulenza tributaria, fatturazione, f24 ecc.</p>
      </>,
    img: bg1, dens: "#1AC35E",
    icon: 'fas fa-people-carry'
  },
  {
    title: "Privati", text:
      <div>
        <p className="hf0-p1" key="hf0-2-p1"><strong>Sereni</strong> di non perdersi fra scadenze e incombenze</p>
        <p className="hf0-p2" key="hf0-2-p2">Forti di essere <strong>guidati</strong> nel comprendere norme e disposizioni.</p>
        <p className="hf0-p3" key="hf0-2-p3">Dichiarazione dei redditi, IMU e TASI, buste paga, cedolini ecc.</p>
      </div>,
    img: bg2, dens: "#FAC332",
    icon: 'fas fa-hands-helping'
  },
  {
    title: "Società", text:
      <>
        <p className="hf0-p1" key="hf0-3-p1"><strong>Tranquilli</strong> di ottimizzare i costi e non disperdere risorse</p>
        <p className="hf0-p2" key="hf0-3-p2"><strong>Fiduciosi</strong> di avere una strategia sostenibile.</p>
        <p className="hf0-p3" key="hf0-3-p3">Consulenza del lavoro, business plan, startup, agevolazioni ecc.</p>
      </>,
    img: bg4, dens: "#30a2ff",
    icon: 'fas fa-user-tie'
  }/*,
  {
    title: "Startup", text:
      <>
        <p className="hf0-p1"><strong>Tranquilli</strong> di non occuparsi di scadenze e incombenze</p>
        <p className="hf0-p2">Forti di essere <strong>guidati</strong> e comprendere norme e disposizioni</p>
        <p className="hf0-p3">dichiarazione dei redditi, IMU e TASI, buste paga e cedolini</p>
      </>,
    img: bg3, dens: "#29397040",
    icon: 'fas fa-seedling'
  }*/
];

const HF0TitleBox = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-end;
`;

const HF0box = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: #000; */
  overflow: hidden;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStr + ")"};
  h2 {
    position: absolute;
    font-family: "Bodoni Moda", serif;
    letter-spacing: calc(1px + 0.04vw);
    font-size: calc(2.0 * (14px + 1.0vw));
    font-weight: 600;
    line-height: 1;
    padding: 0;
  }
  .hfh20 {
    color: #ffffffd8;
    left: calc((425/1920) * 100%);
    top: ${"calc(1.8 * " + Sizes.navHeightFullStr + ")"};
    @media only screen and (min-width: 1441px) {
      top: ${"calc(1.8 * " + Sizes.navHeightFullStrOver + ")"};
    }
    transform: translate(-110%, -50%);
  }
  .hfh21 {
    color: #000000d8;
    left: calc(6px + (425/1920) * 100%);
    top: ${"calc(1.8 * " + Sizes.navHeightFullStr + ")"};
    @media only screen and (min-width: 1441px) {
      top: ${"calc(1.8 * " + Sizes.navHeightFullStrOver + ")"};
    }
    transform: translate(0%, -50%);
  }
  .hfh22 {
    color: #3E56A9;
    right: 0;top: 0;
    transform: translate(102%, 64%) scale(1.15);
  }
  @media only screen and (max-width: 1200px) {
    .hfh22 {
      position: absolute;
      font-size: calc(1.9 * (14px + 1.0vw));
      color: #3E56A9;
      right: 0;
      top: unset;
      bottom: 0;
      line-height: 1;padding: 0;
      transform: translate(60%, 100%) scale(1.1);
    }
  }

@media only screen and (max-width: 800px) {
  h2 {
    font-size: calc(1.8 * (14px + 1.0vw));
  }
  .hfh22 {
    position: absolute;
    font-size: calc(2 * (14px + 1.0vw));
    color: #3E56A9;
    right: 0;
    top: unset;
    bottom: 0;
    line-height: 1;padding: 0;
    transform: translate(24%, 100%) scale(1.05);
  }
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: calc(1.6 * (14px + 1.0vw));
  }
  .hfh22 {
    position: absolute;
    font-size: calc(1.8 * (14px + 1.0vw));
    color: #3E56A9;
    right: 0;
    top: unset;
    bottom: 0;
    line-height: 1;padding: 0;
    transform: translate(14%, 100%) scale(1.05);
  }
}

  @media only screen and (min-width: 1441px) {
    padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStrOver + ")"};
    h2 {
      letter-spacing: calc(1px + (0.04 * 14px));
      font-size: calc(2.0 * (28px));
    }
  }

  /* display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  align-items: flex-end; */
  /* opacity: 0.0;
  animation: home-page-reveal 1.0s ease-in 0.0s 1 forwards;
  @keyframes home-page-reveal {
      0% { opacity: 0.5; }
      100% { opacity: 1.0; }
  } */
  /* h2 {
    color: #000000f0;
    font-family: "Bodoni Moda", serif;
    font-size: calc(2.1 * (11px + 1.25vw));
    font-weight: 900;
    letter-spacing: 2%;
    margin: 0 0 calc(0.6 * (20px + 0.8vw)) 0;
    display: none;
    .h2ttfl {
      color: #fffffff0;
    }
    .h2ttll {
      color: #3E56A9f0;
    }
  } */
  /* @media only screen and (max-width: 700px) {
    justify-content: center;
    padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStr + ")"};
  @media only screen and (min-width: 1441px) {
    padding-top: ${"calc(1.0 * " + Sizes.navHeightFullStrOver + ")"};
  }
    h2 {
      display: block;
      z-index: 1;
      margin: calc(1.0 * (14px + 1.0vw)) 0;
        font-weight: 600;
      .h2ttfl {
        color: #000000f0;
      }
      .h2ttll {
        display: block;
        width: 100%;
        color: #3E56A9ff;
        text-align: end;
        font-weight: 800;
      }
    }
  } */
`;

const HBox0 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc((425/1920) * 100%);
  /* height: 100%; */
  /* background-image: linear-gradient(to bottom, #00000000, #00000000, #00000000, #00000090); */
  background-color: /* #ffffffa0; *//* #00000050; */#00000070;
  /* border-radius: 0 0 0 300px; */
`;

const HBox1 = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: calc((425/1920) * 100%);
  /* height: 100%; */
  /* background-image: linear-gradient(to bottom, #00000000, #00000000, #00000000, #00000090); */
  background-color: /* #ffffff50; */#ffffff70;
  /* border-radius: 0 0 0 300px; */
`;

const HTitle = styled.img`
  position: absolute;
  width: 80%;
  top: ${"calc(1.3 * " + Sizes.navHeightFullStr + ")"};
  @media only screen and (min-width: 1441px) {
    top: ${"calc(1.3 * " + Sizes.navHeightFullStrOver + ")"};
  }
  left: 50%;
  transform: translate(-50%, 0%);
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const HItem = styled.div`
  position: relative;
  /* border-radius: 60px; */border-radius: 0;
  /* width: 70%; *//* width: 100%; */
  width: calc((1495/1920) * 100%);
  height: calc((1495/1920*2/5) * 100vw);
  bottom: 0;right: 0;
  /* height: 40vh; */
  /* mix-blend-mode: darken; */
  padding: /* calc(1.8 * (20px + 0.6vw)) */calc(1.2 * (20px + 0.6vw)) 0 /* calc(1.3 * (20px + 0.6vw)) */calc(1.0 * (20px + 0.6vw)) 0;
  /* background-color: #ffffffa0; */
  /* background-color: #ffffffe0; */background-image: linear-gradient(to right, #ffffff00, #ffffffd0, #ffffff00);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center/* flex-start */;
  align-items: center;
  /* box-shadow: 3px 3px 12px #00000030; */box-shadow: none;
  h4 {
    /* transform: translateX(-100px); */transform: translateX(-200px);
    font-size: calc(1.6 * (14px + 1vw));
    opacity: 0.0;
    animation: appearhithh4 0.7s ease 0.2s 1 forwards;
    @keyframes appearhithh4 {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .pclass{
    width: 86%;
    max-width: 600px;
    font-size: calc(1.0 * (14px + 0.4vw));
    margin-top: calc(0.6 * (14px + 0.5vw));
    opacity: 0.0;
    animation: appearhitp4 0.8s ease 0.35s 1 forwards;
    @keyframes appearhitp4 {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .ctas {
    .fabutton {
      transform: scale(0.9);
    }
    /**/transform: translateX(150px);
    align-self: /* flex-end; */center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin: calc(1.0 * (14px + 1.0vw)) /* calc(1.6 * (14px + 1.0vw)) */0 0 0;
    div {
    margin-right: calc(0.6 * (14px + 1.0vw));
    }
    opacity: 0.0;
    animation: appeari 0.9s ease 0.45s 1 forwards;
    @keyframes appeari {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
  }
  .iclass {
    position: absolute;
    top: 50%;left: 17%;
    transform: translate(0%, -50%);
    font-size: calc(4.0 * (14px + 1.0vw));
    color: /* ${Colors.primary + "18"}; */${Colors.primary + "90"};
    mix-blend-mode: darken;
    /* color: #ffffffff; */
  }
  opacity: 0.0;
  transform: translate(-50%, 0%);
  animation: appearhit4 0.4s ease 0.0s 1 forwards;
  @keyframes appearhit4 {
    0% { opacity: 0.0;transform: translate(-70%, 0%); }
    100% { opacity: 1.0;transform: translate(0%, 0%); }
  }
  /* @media only screen and (max-width: 1000px) {
    .iclass {
      font-size: calc(3.8 * (14px + 1.0vw));
      top: 0;
      left: unset;
      right: 0;
      transform: translate(-25%, -45%);
    }
  } */
  @media only screen and (max-width: 1000px) {
    width: 100%;
    border-radius: 0;
    margin: calc(2.0 * (14px + 1.0vw)) 0 calc(2.5 * (14px + 1.0vw)) 0;
    .iclass {
      font-size: calc(3.8 * (14px + 1.0vw));
      color: ${Colors.primary + "90"};
      top: 0;
      left: unset;
      right: 0;
      transform: translate(-25%, -45%);
      color: #ffffffff;
    }
    .ctas {
      align-self: center;
      margin: calc(1.0 * (14px + 1.0vw)) 0 0 0;
      div {
        margin-right: calc(0.6 * (14px + 1.0vw));
      }
      .fabutton {
        transform: scale(0.84);
      }
    }
    h4 {
      transform: translateX(0px);
    }
  }
`;

const HeaderDots = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-30%, -30%);

  padding: calc(0.4 * (14px + 0.5vw)) calc(0.8 * (14px + 0.5vw));
  /* border-radius: 999px;
  box-shadow: 2px 2px 6px #00000090;
  background-color: #ffffffa0; */

  z-index: 12;
  opacity: 1.0;
  transition: all 0.3s;
  @media only screen and (max-width: 800px) {
    transform: translate(-10%, -30%);
    /* transform: translate(-50%, -30%); */
    /* right: 50%; */
  }
`;

/* const headerItems = [
  { id: 0, img: bg1, title: "Psicoterapia cognitiva", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet mollis velit, non rutrum dolor tempor vitae. Vivamus eu est interdum.", text: "", cta: "Scopri di più" },
  { id: 1, img: bg2, title: "Psicologia della salute", subtitle: "Integer semper turpis vel neque blandit, at laoreet mauris ultricies. Suspendisse convallis imperdiet accumsan.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" }
]; */

export default HeroFinal0;
