import { MultiSnippet3Small, MultiSnippet4 } from "comps/complex/MultiSnippet";
import styled from 'styled-components';
import imgbg0t from 'img/ui/bgt0.png';
import imgbg330t from 'img/ui/bgt330.png';
/* import imgbg0tsvg from 'img/ui/bgt0.svg'; */
import { SubHeadline0 } from "comps/simple/SubHeadline0";
import MultiShowOne0 from "comps/complex/MultiShowOne0";
import imgAnna from "img/studio/anna-tallarico.jpg";
import imgStudio from "img/studio/studio-tallarico.jpg";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import SectionTitle0 from "comps/simple/SectionTitle0";
import { Expandable0 } from "comps/compound/SectionServiziOLD";
import SectionStudioNew, { Expandable1 } from "comps/compound/SectionStudioNew";
import imgAnna2 from "img/studio/anna-tallarico6.jpg";
import imgStudio2 from "img/studio/studio-tallarico720.jpg";
import { TextH4 } from "comps/basic/Typography4";

const textStudioShort = <p>Lo Studio Tallarico fornisce servizi di <strong>consulenza fiscale, societaria, aziendale e contabile</strong> dal 2016 nella sua struttura di Milano, portando avanti l’attività svolta dalla Studio Associato Rizzo-Tallarico fin dal 2008. Lo Studio affianca una molteplicità di soggetti che spaziano <strong>dalla piccola impresa a gestione famigliare alle grandi società per azioni, comprendendo anche grandi istituzioni del terzo settore.</strong></p>;

const textStudio = <p>Lo Studio Tallarico fornisce servizi di consulenza fiscale, societaria, aziendale e contabile dal 2016 nella sua struttura di Milano, portando avanti l’attività svolta dalla Studio Associato Rizzo-Tallarico fin dal 2008. Lo Studio affianca una molteplicità di soggetti che spaziano dalla piccola impresa a gestione famigliare alle grandi società per azioni, comprendendo anche grandi istituzioni del terzo settore. L’approccio dello Studio nel suo complesso, e della Titolare in  particolare, favorisce un rapporto amichevole e personalizzato, e si pone l’obiettivo di diventare un business partner a tutto  tondo, in grado di soddisfare tutte le esigenze della clientela. Lo Studio pone particolare attenzione alla trasparenza nel rapporto col Cliente: in particolare, la struttura dei costi è per quanto possibile predeterminata, al fine di consentire una efficacie pianificazione del budget dedicato alla consulenza. Uno dei maggiori punti di forza dello Studio è la capacità di ascoltare le esigenze del Cliente, analizzare le possibile soluzioni, e presentarle nel modo più chiaro e comprensibile possibile.</p>;

const textTitolareShort = <p>La dott.ssa Anna Tallarico si è diplomata in Ragioneria e laureata in Scienze Economiche e Sociali. Dopo una fruttuosa esperienza come consulente contabile e fiscale, ha conseguito il titolo di Dottore Commercialista e si è iscritta all’<strong>Albo dell’Ordine dei Dottori Commercialista ed Esperti Contabili di Milano</strong> dal 2004, per poi iscriversi al <strong>Registro Nazionale dei Revisori</strong> Legali dal 2008.</p>;

const textTitolare = <p>La dott.ssa Anna Tallarico si è diplomata in Ragioneria e laureata in Scienze Economiche e Sociali. Dopo una fruttuosa esperienza come consulente contabile e fiscale, ha conseguito il titolo di Dottore Commercialista e si è iscritta all’Albo dell’Ordine dei Dottori Commercialista ed Esperti Contabili di Milano dal 2004, per poi iscriversi al Registro Nazionale dei Revisori Legali dal 2008. L’attività svolta dalla dott.ssa Tallarico spazia dall’assistenza civilistica e fiscale a favore di società di vario tipo, singoli imprenditori e professionisti, alla redazione di perizie, all’assistenza in operazioni straordinarie come fusioni o trasformazioni societarie.</p>;

const beneficiItems = [
  {
    title: 'Chiarezza',
    text: "Si studia la normativa di riferimento in seguito alle richieste e si espone in maniera chiara e comprensibile al cliente",
    icon: "fas fa-hands"
  },
  {
    title: 'Disponibilità',
    text: "Siamo sempre a disposizione per qualsiasi dubbio si abbia e pronti a rispondere alle domande tramite mail o telefono",
    icon: "fas fa-hands"
  },
  {
    title: 'Aggiornamento',
    /* text: "Scarichiamo mensilmente le fatture dal sito dell'agenzia delle entrate e le contabilizziamo. Si potrà avere una situazione contabile aggiornata sia mensilmente che trimestralmente.", */
    text: "Fatture contabilizzate mensilmente, situazione contabile aggiornata trimestralmente o mensilmente.",
    icon: "fas fa-hands"
  },
  {
    title: 'Velocità',
    text: "Si gestiscono le richieste entro due giorni lavorativi",
    icon: "fas fa-hands"
  }
];

const risultatiItems = [
  {
    title: <h5>Liberi di concentrarsi sulla <span style={{ fontWeight: "600", color: "#FCBF1E" }}>crescita</span></h5>,
    text: "di concentrarsi sulla crescita",
    icon: "fas fa-hands"
  },
  {
    title: <h5>Fiduciosi di <span style={{ fontWeight: "600", color: "#FCBF1E" }}>non disperdere</span> risorse</h5>,
    text: "di non disperdere risorse",
    icon: "fas fa-hands"
  },
  {
    title: <h5>Sereni di essere sempre <span style={{ fontWeight: "600", color: "#FCBF1E" }}>aggiornati</span></h5>,
    text: "di essere sempre aggiornati",
    icon: "fas fa-hands"
  }
];

const SectionStudio = () => {
  return (
    <SSBox bg={imgbg330t/* imgbg0t */}>
      <SectionHashTarget id="studio-tallarico" />
      {/*  <SectionTitle0 img={1} flip title="Lo Studio" subtitle="e approfondimenti" /> */}
      <SectionTitle0 img={1} flip title="Presentazione" subtitle="cosa ti offriamo" />

      {/* <SectionStudioNew /> */}

      {/* <SImgBox00>
        <SImgBox><SImgBox0 /><SImgBox1 /><SImgAnna src={imgAnna} /></SImgBox>
        <SImgBox02>
          <h4>ANNA</h4><p>La dott.ssa Anna Tallarico, permetta di dare a ogni cliente, permetta di dare a ogni cliente un servizio ad alto livello e fortemente personalizzato.</p>
        </SImgBox02>
      </SImgBox00>
      <SImgBox00>
        <SImgBox01>
          <h4>STUDIO</h4><p>La dott.ssa Commercialisti ed Esperti Contabili di Milano, nella Sez. A al n. 6342, e dal 2008 all’Albo dei Revisori Ufficiali dei Conti, al n. 150987, come da G.U. n. 37 del 13/5/2008.</p>
        </SImgBox01>
        <SImgBox><SImgBox0 /><SImgBox1 /><SImgAnna src={imgStudio} /></SImgBox>
      </SImgBox00> */}

      <div className="ssBenefitsBox">
        {/* <span className="ssBenefitsSpan"><SubHeadline0 title="Cosa ti offriamo" subtitle="IMPEGNO" /></span> */}
        {/* <h4>IL NOSTRO IMPEGNO</h4> */}
        <Expandable1 isOpen servid={"studio"} img={imgStudio2} title={"Studio Tallarico"} text={textStudioShort/* "Lo Studio Tallarico è uno studio individuale di Dottore Commercialista e Revisore Contabile con sede a Milano, nato nel 2016 dopo la chiusura dello Studio Associato Rizzo Tallarico e rappresenta comunque una continuazione dell’attività svolta dai soci fin dal 1998. Lo Studio fornisce servizi aggiornati e specifici ad una molteplicità di soggetti, dalle piccole imprese alle grandi società, individuando le diverse esigenze e fornendo le soluzioni più adeguate." */}/* text={"Lo Studio vanta una squadra affiatata di persone con variegate qualità professionali e competenze complementari. La forza dell'offerta sta nella passione per il profondo rapporto con il cliente, caratteristico degli Studi medio-piccoli."} */ />
        <MSSBox><TextH4 uppercase spaced color="#303030" firstletter>{"Il nostro impegno"}</TextH4>
          <MultiSnippet4 color="#3E56A9" firstL={true} titleColor="#606060" textColor="#00000090"
            icons={["fas fa-heartbeat", "fas fa-spa", "fas fa-sun", "fas fa-hiking", "fas fa-child"]}
            titles={[beneficiItems[0].title, beneficiItems[1].title, beneficiItems[2].title, beneficiItems[3].title]}
            texts={[beneficiItems[0].text, beneficiItems[1].text, beneficiItems[2].text, beneficiItems[3].text]} /></MSSBox>
        <Expandable1 isOpen servid={"anna"} left img={imgAnna2} title={"La Titolare"} text={textTitolareShort/* "La dott.ssa Anna Tallarico, diplomata in Ragioneria e laureata in Scienze Economiche e Sociali, è iscritta dal 2004 all’ordine dei Dottori Commercialisti ed Esperti Contabili di Milano, nella Sez. A al n. 6342, e dal 2008 all’Albo dei Revisori Ufficiali dei Conti, al n. 150987, come da G.U. n. 37 del 13/5/2008." */}/* text={"Nei suoi 30 anni di pratica, Anna Tallarico ha accumulato esperienza in tutte le aree di intervento, e ha collaborato con studi di varia natura. Oggi dedica le sue energie e competenze ai clienti dello studio, fornendo inestimabile valore aggiunto sul piano professionale"} */ />
      </div>
      <div className="ssResultsBox">
        {/* <SSBox0 />
        <SSBox1 />
        <SSBox2 /> */}
        <span className="ssResultsSpan">
          <SubHeadline0 right title="Cosa significa per te" subtitle="ASPETTATIVE" />
        </span>
        {/* <h4>LE TUE ASPETTATIVE</h4> */}
        {/* <MultiSnippet3 firstL={true} color="#FCBF1E" textColor="#ffffffb0" titleColor="#fffffff0" iconColor="#FCBF1Ee0"
          icons={["fas fa-heartbeat", "fas fa-spa", "fas fa-sun", "fas fa-hiking", "fas fa-child"]}
          titles={[risultatiItems[0].title, risultatiItems[1].title, risultatiItems[2].title]}
          texts={[risultatiItems[0].text, risultatiItems[1].text, risultatiItems[2].text]} /> */}
        <MultiSnippet3Small /* columnMarginBottom="calc(40px + 1vw)"  *//* columnThreshold="2000px" */ theme="dark" color="#FCBF1E" titles={[risultatiItems[0].title, risultatiItems[1].title, risultatiItems[2].title]} icons={[
          "fas fa-store", "fas fa-user-tie", "fas fa-wifi",
        ]} texts={[risultatiItems[0].text, risultatiItems[1].text, risultatiItems[2].text]} />
        <SectionHashTarget id="soluzioni" />
        <MultiShowOne0 />
      </div>
    </SSBox >
  );
}
const SSBox0 = styled.div`
  position: absolute;
  top: calc(-4.0 * (14px + 1.0vw));
  left: calc(-6.0 * (14px + 1.0vw));
  width: calc(12.0 * (14px + 1.0vw));
  height: calc(12.0 * (14px + 1.0vw));
  background-color: white;
  border-radius: 999px;
  z-index: 1;
`;
const SSBox1 = styled.div`
  position: absolute;
  top: calc(-3.0 * (14px + 1.0vw));
  left: calc(5.0 * (14px + 1.0vw));
  width: calc(6.0 * (14px + 1.0vw));
  height: calc(6.0 * (14px + 1.0vw));
  background-color: white;
  border-radius: 999px;
  z-index: 1;
`;
const SSBox2 = styled.div`
  position: absolute;
  top: calc(-4.0 * (14px + 1.0vw));
  right: calc(-4.0 * (14px + 1.0vw));
  width: calc(10.0 * (14px + 1.0vw));
  height: calc(10.0 * (14px + 1.0vw));
  background-color: white;
  border-radius: 999px;
  z-index: 1;
`;

const MSSBox = styled.div`
  width: 100%;
    position: relative;
    margin: calc(3.0 * (2vw)) 0 calc(2.0 * (2vw)) 0;
    padding: 0 0 calc(2.0 * (2vw)) 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(to right, #3E56A900, #3E56A908, #3E56A908, #3E56A918); */
    background-image: linear-gradient(to right, #30a2ff00, #30a2ff08, #30a2ff08, #30a2ff18);
    box-shadow: 0px 4px 16px #00000030;
    h4 {
      width: 100%;
      margin: calc(2.0 * (2vw)) 0 calc(1.0 * (2vw)) 0;
      padding: calc(1.8 * (14px + 0.5vw)) 0 calc(1.8 * (14px + 0.5vw)) 0;
      font-size: calc(1.37* (14px + 1vw));
      /* background-image: linear-gradient(to left, #3E56A900, #3E56A900, #3E56A918); */
      background-image: linear-gradient(to left, #30a2ff00, #30a2ff00, #30a2ff18);
    }
  @media only screen and (min-width: 1200px) {
    margin: calc(3.0 * (27px)) 0 calc(2.0 * (27px)) 0;
    padding: 0 0 calc(2.0 * (27px)) 0;
    font-size: calc(1.0 * (19px));
    h4 {
      margin: 0 0 calc(1.0 * (20px)) 0;
      font-size: calc(1.6 * (25px));
    }
  }
`;
const SSBox = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; */
    /* padding-bottom: calc(2.0 * (14px + 1.0vw)); */

  font-family: 'Montserrat', sans-serif;
  /* overflow: hidden; */
  z-index: 1;
  .ssBenefitsBox {
    position: relative;
    width: 100%;
    color: #3E56A9;
    padding: calc(0.4 * (14px + 1.0vw)) 0 calc(2.0 * (14px + 1.0vw)) 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    background-image: url(${props => props.bg});
    background-size: 100% auto;
    background-position: bottom;
    background-repeat: no-repeat;
    /* padding-bottom: calc(100px + 257px); */
    padding-bottom: /* calc(0px + 20vw); */14vw;
    z-index: 1;
    .ssBenefitsSpan {
      @media only screen and (min-width: 800px) {
        div{
          transform: translate(calc(-30px - 16vw), 0);
        }
      }
    }
  }
  .ssResultsBox {
    .ms3smallbox {
      /* transform: translateY(calc(-100px - 2vh)); */
      /* margin-top: calc(-0.75 * (100px + 2vh)); */
      margin: calc(2.5 * (14px + 1.0vw)) 0;
      h5 {
        font-size: calc(1.0 * (14px + 0.2vw));
        font-weight: 500;
      }
    }
    position: relative;
    width: 100%;
    background: #3E56A9 linear-gradient(to right, #3E56A990, #1B264B90);
    color: #FCBF1E;
    padding: calc(2.0 * (14px + 1.0vw)) 0 0 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    /* margin-top: calc(-100vw * (257/1440)); */margin-top: calc(-100vw * ((257*330/465)/1440));
    /* transform: translateY(calc(-100vw * (257/1440))); */
    /* background-color: red; */
    /* transform: translateY(-257px); */
    padding-top: 190px;
    z-index: 0;
    @media only screen and (min-width: 1441px) {
      margin-top: calc(-1440px * ((257*330/465)/1440));

    .ms3smallbox {
      /* transform: translateY(calc(-100px - 2vh)); */
      /* margin-top: calc(-0.75 * (100px + 2vh)); */
      margin: calc(2.5 * (28px)) 0;
      h5 {
        font-size: calc(1.0 * (17px));
      }
    }
    }
    .ssResultsSpan {
      div{
        h4 { line-height: 1.5; color: white; &::first-letter { color: #FCBF1Ee0; }}
        h5 {
          color: #ffffffb0;
          &::after {
            background-image: ${props => props.right === false ? "linear-gradient(to right, #ffffff00, #ffffffa0)" : "linear-gradient(to left, #ffffff00, #ffffffa0)"};
          }
        }
        /* transform: translate(calc(30px + 4vw), calc(-100px - 2vh)); */
          margin-top: calc(-0.5 * (100px + 2vh));
          /* margin-left: calc(1.25 * (30px + 4vw)); */
        @media only screen and (min-width: 650px) {
          /* transform: translate(calc(30px + 16vw), calc(-100px - 2vh)); */
          margin-top: calc(-0.45 * (100px + 2vw));
          margin-left: calc(1.25 * (10px + 18vw));
        }
        @media only screen and (min-width: 1441px) {
          margin-top: calc(-0.35 * (100px + 30px));
          margin-left: calc(1.25 * (10px + 300px));
        }
      }
    }
  }
`;

const SImgAnna = styled.img`
  position: relative;
  width: calc(200px + 8vw);
  height: auto;
  border-radius: 5px 50px 5px 50px;
  box-shadow: 2px 2px 20px #3E56A950;
  border: 3px solid #3E56A9b0;
  border-width: 2px 4px 6px 1px;
`;

const SImgBox = styled.div`
  position: relative;
  /* border: 5px solid red; */
  margin: calc(12px + 1vw);
  max-width: 100vw;
  /* overflow: hidden; */
`;

const SImgBox00 = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 0 0 0;
`;

const SImgBox0 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 95%;
  transform: translate(-15%, -5%);
  border-radius: 25px;
  /* background-color: #3E56A930; */
  background-image: linear-gradient(to bottom, #3E56A910, #3E56A940);
`;

const SImgBox1 = styled(SImgBox0)`
  transform: translate(15%, 5%);
  width: 100%;
  height: 110%;
  /* background-color: #3E56A918; */
  background-image: linear-gradient(to right, #3E56A918, #3E56A930);
`;

const SImgBox01 = styled.div`
  position: relative;
  /* max-width: calc(100% - 600px);
  min-width: 400px; */
  width: 600px;
  text-align: end;
  line-height: calc(1.4 * (14px + 0.4vw));
  h4 {
    display: inline-block;
    margin-right: calc(8px + 0.2vw);
    color: #3E56A990;
    font-size: calc(1.5 * (14px + 0.4vw));
  }
  p{
    display: inline;
    color: #585858;
    font-size: calc(0.9 * (14px + 0.4vw));
    }
  text-align: end;
`;

const SImgBox02 = styled(SImgBox01)`
  position: relative;
  text-align: start;
`;

export default SectionStudio;
