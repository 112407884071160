import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
/* import headerItem0 from "img/illu0-850-700.png";
import headerItem1 from "img/illu1-850-700.png";
import headerItem2 from "img/illu2-850-700.png";
import headerItem3 from "img/illu3-850-700.png"; */
/* import { useKey } from "react-use"; */
import debounce from "lodash.debounce";
import DotsNav from "comps/simple/DotsNav";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import { HashLink } from "comps/basic/HashLink";
import RippleButton from "comps/simple/RippleContainer";
import { Sizes } from "data/Basic";

import headerItem0 from 'img/header/anna-tallarico-studio-commercialista-milano-00.jpg';
import headerItem1 from 'img/header/anna-tallarico-studio-commercialista-milano-01.jpg';
import headerItem2 from 'img/header/anna-tallarico-studio-commercialista-milano-02.jpg';

const headerItems = [
  { title: 'Partita IVA', img: headerItem0, img2: headerItem0, text: "Explore the range of products and services we provide. From responsive design, to eCommerce development, to online brand marketing.", ctaTitle: "EXPLORE", ctaIcon: "fas fa-share", ctaLink: "/#soluzioni" },
  { title: 'Privati', img: headerItem1, img2: headerItem1, text: "Discover our frame of mind in laying out the dimensions along which truely tailored solutions unfold, while keeping your goals in focus.", ctaTitle: "UNDERSTAND", ctaIcon: "fas fa-share", ctaLink: "/#soluzioni" },
  { title: 'Startup', img: headerItem2, img2: headerItem2, text: "Understand the essential steps of the workflow, from communication based planning, to careful and regular maintenance.", ctaTitle: "DISCOVER", ctaIcon: "fas fa-share", ctaLink: "/#soluzioni" },
  { title: 'Società', img: headerItem1, img2: headerItem1, text: "Find out the skills we bring to the table and the values we always keep on that same table. You choose whether it all aligns with your needs.", ctaTitle: "FIND OUT", ctaIcon: "fas fa-share", ctaLink: "/#soluzioni" }
];

const Header2 = () => {
  const [item, setItem] = useState(0);
  const itemCount = headerItems.length;
  const swipeOn = debounce(() => { console.log("clicked!"); setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  useEffect(() => { console.log("changed item: ", item); }, [item]);
  return (
    <HeaderBox onClick={swipeOn}>
      <SectionHashTarget id="start" />
      {
        headerItems.map((cItem, cIndex) =>
          <HeaderSubBox key={"header2item-" + cIndex} show={item === cIndex} imgFirst={cIndex % 2 !== 0}>
            <HeaderTxtBoxt className="txtbox" align={(cIndex % 2 !== 0) === true ? "left" : "right"}>
              <h3 onClick={e => e.stopPropagation()}>{cItem.title}</h3>
              <p onClick={e => e.stopPropagation()}>{cItem.text}</p>
              <HashLink to={cItem.ctaLink} smooth><RippleButton effect="invert" rippleColor="#3E56A9" bgcolor="#3E56A9e0" iconSize={0.7} iconFAClass="fas fa-paper-plane" border="0px" color="#ffffff" text="Contattaci" /></HashLink>
              {/* <p>Understand the essential steps of the workflow, from communication based planning, to careful and regular</p> */}
            </HeaderTxtBoxt>
            <HeaderImgBox src={cItem.img/* cItem.img */} src2={cItem.img2/* cItem.img */} className="imgbox" imgFirst={cIndex % 2 !== 0}>
              {/* <img src={cItem.img} alt={"BITSCUITS " + cItem.title} /> */}
            </HeaderImgBox>
          </HeaderSubBox>
        )
      }
      <HeaderDots onClick={(e) => e.stopPropagation()}>
        <DotsNav marginPx={6} borderPx={0} dotsNumber={headerItems.length} sizePx={20} activeDot={((item > -1) && (item < headerItems.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#FCBF1E60" colorActive="#FCBF1Ec0" />
      </HeaderDots>
    </HeaderBox>
  );
}

const HeaderDots = styled.div`
  position: absolute;
  /* left: 50%; */
  right: 0%;
  bottom: 25px;
  transform: translate(-50%, 0%);
  padding: 5px 10px;
  z-index: 2;
  opacity: 1.0;
  transition: all 0.3s;
  /* @media only screen and (max-width: 800px) { */
    background-color: #000000e0;
    border-radius: 22px;
    box-shadow: 4px 4px 4px #00000030;
  /* } */
  @media only screen and (max-width: 800px) {
    right: 50%;
  transform: translate(50%, 0%);
  }
  @media only screen and (max-width: 330px) {
    bottom: 15px;
  }
`;

const HeaderBox = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: red;
  cursor: pointer;
  background-image: linear-gradient(to right, #293970, #1B264B);

  /* animation: headerappear 1s ease 0.3s 1 forwards;
  @keyframes headerappear {
    0% { opacity: 0.0; }
    100% { opacity: 1.0; }
  } */
`;

const HeaderSubBox = styled.div`
  position: absolute;
  top: calc(${Sizes.navHeightFullStr});
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #ff000090; */
  transition: opacity 0.5s;
  opacity: ${props => props.show === true ? "1.0" : "0.0"};
  display: ${props => props.show === true ? "flex" : "none"};
  /* display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center; */

  .imgbox {
    opacity: 0.0;
    animation: ${props => props.show === true ? "appearhib 1s ease-out 0.0s 1 forwards" : "appearhib2 1s ease-out 0.0s 1 forwards"};
    filter: saturate(50%);
  }
  @keyframes appearhib {
    0% { opacity: 0.0; }
    100% { opacity: 0.85; }
  }
  @keyframes appearhib2 {
    0% { opacity: 0.0; }
    100% { opacity: 0.85; }
  }

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* @media only screen and (max-width: 900px) {
    width: 100%;
    left: 0%;
  } */
  @media only screen and (min-width: 1441px) {
    top: calc(${Sizes.navHeightFullStrOver});
  }
`;

const HeaderTxtBoxt = styled.div`
  position: relative;
  z-index: 1;
  /* flex-shrink: 2; */
  /* background-image: linear-gradient(to right, #272121e0, #00000070, #272121b0, #000000c0); */
  /* background-image: ${props => props.align === "left" ?
    "linear-gradient(to right, #00000030, #00000090, #000000d0, #00000030)" :
    "linear-gradient(to left, #00000030, #00000090, #000000d0, #00000030)"}; */
  /*       background-image: ${props => props.align === "left" ?
    "linear-gradient(to right, #00000020, #00000080, #000000e0, #00000080)" :
    "linear-gradient(to left, #00000020, #00000080, #000000e0, #00000080)"};      */
  /* background-image: ${props => props.align === "left" ?
    "linear-gradient(to right, #00000020, #000000b0, #000000e0, #00000030)" :
    "linear-gradient(to left, #00000020, #000000b0, #000000e0, #00000030)"}; */
    background-image: ${props => props.align === "left" ?
    "linear-gradient(to right, #ffffff00, #ffffff80, #ffffffe0, #ffffffc0)" :
    "linear-gradient(to left, #ffffff00, #ffffff80, #ffffffe0, #ffffffc0)"};
  padding: calc(30px + 2.2vw) calc(0px + 9vw);
  @media only screen and (min-width: 1441px) {
    padding: 60px 140px;
  }
  /* border-radius: 50px; */
  box-shadow: 0px 5px 12px #000000e0;
  width: 100%;
  /* margin-right: 17.5%; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.align === "left" ? "flex-end" : (props.align === "right" ? "flex-start" : "center")};
  text-align: ${props => props.align === "left" ? "end" : (props.align === "right" ? "start" : "center")};
  &>h3 {
    font-size: calc(1.6 * (14px + 1vw));
    opacity: 0.0;
    animation: appearhithh4 0.7s ease 0.2s 1 forwards;
    @keyframes appearhithh4 {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
    }
    /* padding: 0 calc(40px + 2vw); */
    /* font-size: calc(20px + 1vw); */
    font-weight: 500;
    line-height: 1;
    color: #202020;
    margin-bottom: calc(15px + 1.5vw);
  }
  &>p {
    max-width: 700px;
    font-size: calc(13px + 0.4vw);
    font-weight: 400;
    line-height: 1.6;
    /* color: #505050; */
    color: #505050;
    margin-bottom: calc(15px + 1.5vw);
  }

  @media only screen and (max-width: 800px) {
    align-items: center;
    text-align: center;
    background-color: #ffffffc0;
    /* background-image: linear-gradient(to right, #000000c0, #000000c0, #000000c0, #000000c0, #000000c0); */
    padding: calc(30px + 1.6vw) calc(10px + 2vw);
  }
  @media only screen and (max-width: 500px) {
    /* background-image: linear-gradient(to right, #000000c0, #000000c0, #000000c0, #000000c0, #000000c0); */
    padding: calc(20px + 1.6vw) calc(10px + 2vw);
  }
`;

const HeaderImgBox = styled.div`
  position: absolute;
  top: 0;
  right: ${props => props.imgFirst === false ? "0" : "unset"};
  left: ${props => props.imgFirst === true ? "0" : "unset"};
  /* position: relative; */
  /* flex-shrink: 0; */
  /* float: right; */
  width: 55%;
  height: 100%;
  /* background-color: #ff000040; */
  /* shape-outside: url(${props => props.src}); */
  border-radius: ${props => props.imgFirst === false ?
    "calc((100vh - " + Sizes.navHeightFullStr + ") / 2.0) 0 0 calc((100vh - " + Sizes.navHeightFullStr + ") / 2.0)" :
    "0 calc((100vh - " + Sizes.navHeightFullStr + ") / 2.0) calc((100vh - " + Sizes.navHeightFullStr + ") / 2.0) 0"};

  overflow: hidden;
  background-image: url(${props => props.src});
  background-size: cover;
  /* background-position:  center right; */
  background-repeat: no-repeat;
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${props => props.src2});
    /* background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    img {
      /* display: none; */
      /* position: absolute;
      height: 94%;
      width: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); */
    }
  }
  @media only screen and (min-width: 1441px) {
    border-radius: ${props => props.imgFirst === false ?
    "calc((100vh - " + Sizes.navHeightFullStrOver + ") / 2.0) 0 0 calc((100vh - " + Sizes.navHeightFullStrOver + ") / 2.0)" :
    "0 calc((100vh - " + Sizes.navHeightFullStrOver + ") / 2.0) calc((100vh - " + Sizes.navHeightFullStrOver + ") / 2.0) 0"};
  }
`;

export default Header2;
