import { shortFAQGenerali } from 'data/FAQGenerali';
import { shortFAQStudio } from 'data/FAQStudio';
import styled from 'styled-components';
import { useState } from 'react';
import Expandablist from "comps/complex/Expandablist";
import { TextP } from "comps/basic/Typography4";
import { SectionHashTarget } from "comps/simple/SectionHashTarget";
import { ToggleControlled } from "comps/simple/Toggle";
import { SubHeadline0 } from "comps/simple/SubHeadline0";
import faqbg from 'img/ui/bgt1b.svg';

const SectionFAQ2 = () => {
  const [type, setType] = useState(true); /*true = studio, false = tecniche*/
  const toggleType = () => (setType(s => !s));
  return (
    <SFB0 bg={faqbg}>{/*
      <i className="fas fa-question-circle" /> */}
      <SectionHashTarget id="domande-frequenti" />
      <SFT><SubHeadline0 screen spaced title="Domande frequenti" subtitle="lo studio e la professione" /></SFT>
      {/* <SectionTitle bg align="right" title="Domande frequenti" /> */}
      <FAQBox>
        <ToggleControlled selected={type} onToggle={toggleType} t1="Lo studio" t2="Tecniche" /><br />
        {type === true ?
          <Expandablist key={/* newKey +  */"fqs" + type} itemList={shortFAQStudio} /> :
          <Expandablist key={/* newKey +  */"fqt" + type} itemList={shortFAQGenerali} />}
        <p className="sfdots">...</p>
        {/* <LinkA>
          <HashLink to="/domande-frequenti">Leggi tutte le domande frequenti</HashLink>
        </LinkA> */}
      </FAQBox>
    </SFB0>
  );
}
const SFT = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 0;
  background-image: linear-gradient(to right, #ffffffc0, #ffffff00 75%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;
const SFB0 = styled.div`
  .subheadbox {
    align-self: flex-start;
    margin-left: calc(5.0 * (14px + 1.0vw));
  }
  @media only screen and (min-width: 1300px) {.togglebox {align-self: flex-start;}}
  .togglebox {margin-bottom: calc(0.8 * (14px + 1.0vw))}
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 calc(20px + 2vw) 0;
  transform: translateY(-0px);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.bg});
    background-size: 100% auto;
    background-position: right top;
    background-repeat: no-repeat;
    opacity: 0.3;
  }
  /* i {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30vw;
    opacity: 0.4;
    color: #3E56A9ff;
    transform: translate(30%, -30%);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 999px;
      background-image: linear-gradient(to bottom, #3E56A990, #1B264B90);
    }
  } */
`;

const FAQBox = styled.div`
  position: relative;
  width: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .sfdots {
    position: relative;
    width: 100%;
    text-align: end;
    padding: 0 17px;
    font-size: calc(24px + 1.4vw);
    line-height: calc(24px + 1.4vw);
    letter-spacing: 6px;
    font-weight: 600;
    color: #505050;
    margin-bottom: calc(14px + 1vw);
  }
`;

export default SectionFAQ2;
